import * as types from "../constants/actions-type";

export const toggleSearch = (hide = false) => ({
  type: types.TOGGLE_SEARCH,
  hide,
});

// In use to filter entities on the map
export const toggleFilter = (hide = false) => ({
  type: types.FILTER_TOGGLE,
  hide,
});

export const setFilter = (filter) => ({
  type: types.FILTER_SET,
  filter: filter,
});

export const setScrollSpy = value => ({
  type: types.SET_SCROLL_SPY,
  value,
});

export const setActiveTab = value => ({
  type: types.SET_ACTIVE_TAB,
  value,
});

export const setCurrentOrgunitLegend = value => ({
  type: types.SET_CURRENT_ORGUNIT_LEGEND,
  value,
});

export const setMapLayers = mapLayers => ({
  type: types.SET_MAP_LAYERS,
  mapLayers,
});

export const setMapGroups = mapGroups => ({
  type: types.SET_MAP_GROUPS,
  mapGroups,
});

export const toggleLegend = displayLegend => ({
  type: types.TOGGLE_LEGEND,
  displayLegend,
});

export const setLegendHeight = legendHeight => ({
  type: types.SET_LEGEND_HEIGHT,
  legendHeight,
});
