import {
  RECEIVE_PERIODS,
  UPDATE_FROM_PERIOD,
  UPDATE_TO_PERIOD,
} from "../constants/actions-type";

export const receivePeriods = periods => ({
  type: RECEIVE_PERIODS,
  periods,
});

export const updateFromPeriod = period => ({
  type: UPDATE_FROM_PERIOD,
  period,
});

export const updateToPeriod = period => ({
  type: UPDATE_TO_PERIOD,
  period,
});

export const updatePeriod = (when, period) => dispatch => {
  if (when === "from") {
    return dispatch(updateFromPeriod(period));
  }
  if (when === "to") {
    return dispatch(updateToPeriod(period));
  }
};
