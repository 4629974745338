import React from "react";
import Control from "react-leaflet-control";
import { RadioGroup, Radio } from "react-radio-group";
import ReactModal from "react-modal";
import { useSelector, useDispatch } from 'react-redux';
import { toggleFilter, setFilter } from "../../../actions/ui";
import { withTranslation } from "react-i18next";
import { useState, useEffect } from "react";

function FilterControl({t, onChangeCallback}) {
  const dispatch = useDispatch();
  const activeFilter = useSelector(state => state.ui.filter)
  const filterActive = useSelector(state => state.ui.filterActive)
  const filterElements = useSelector(state => state.project.filterElements || [])
  const [selectedQuery,setSelectedQuery] = useState(null);

  useEffect(() => {
    const query = filterElements.find(el => el.elements.map(e => e.id).includes(activeFilter))
    if (query) {
      setSelectedQuery(query.id);
    }
  },[activeFilter])

  const onClose = e => {
    dispatch(toggleFilter())
  }
  const handleChange = value => {
    const query = filterElements.find(el => el.id === value);

  }

  const selectChanged = e => {
    dispatch(setFilter(e.target.value))
    if (onChangeCallback) {
      onChangeCallback();
    }
  }
  const filtersEnabled = useSelector(state => (state.project.featureToggles || {mapFilters: false}).mapFilters);
  if (!filtersEnabled) {
    return [];
  }

  return (
    <>
      <h5>Filters</h5>
      <RadioGroup
        selectedValue={selectedQuery}
        onChange={(value) => setSelectedQuery(value)}
      >
        {filterElements.map(({id,name,elements}) => (
          <p key={id}>
            <label>
              <Radio value={id} />
              {name}
            </label>
            <select
              disabled={id !== selectedQuery}
              id={`select-${id}`}
              onChange={selectChanged}
              value={activeFilter}>
              >
              <option> </option>
              {elements.map(({id,name}) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))}
            </select>
          </p>
        ))}
      </RadioGroup>
    </>
  )
}

export default withTranslation()(FilterControl);
