import React from "react";

import PropTypes from "prop-types";

function SectionDescription(props) {
  if (!props.text) {
    return null;
  }

  return (
    <div
      className="section__description"
      dangerouslySetInnerHTML={{ __html: props.text }}
    />
  );
}

SectionDescription.propTypes = {
  text: PropTypes.string,
};

export default SectionDescription;
