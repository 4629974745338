import * as types from "../constants/actions-type";

function values(
  state = {
    valuesByOrgUnitId: [],
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_VALUES:
    return {
      ...state
    };
  case types.RECEIVE_VALUES:
    return {
      ...state,
      valuesByOrgUnitId: {...state.valuesByOrgUnitId, ...action.values}
    }
  default:
    return state;
  }
}

export default values;
