import color from "color";

function hEXtoHSL(hex, brightnessCorrection = 0, alpha = 1) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let r = parseInt(result[1], 16);
  let g = parseInt(result[2], 16);
  let b = parseInt(result[3], 16);
  (r /= 255), (g /= 255), (b /= 255);
  const max = Math.max(r, g, b);

  const min = Math.min(r, g, b);
  let h;

  let s;

  let l = (max + min) / 2;
  if (max === min) {
    h = s = 0;
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }
  s *= 100;
  s = Math.round(s);
  l *= 100;
  l = Math.round(l);
  h = Math.round(360 * h);
  return `hsla(${h}, ${s}%, ${l + brightnessCorrection}%,  ${alpha})`;
}

export const gradientHero = (color, backgroundImageUrl) => `
  linear-gradient(${hEXtoHSL(color, -18, 0.1)}, ${hEXtoHSL(color, -18)} 80%), 
  linear-gradient(${hEXtoHSL(color, -30, 0.7)}, ${hEXtoHSL(color, -30, 0.7)}), 
  url(${backgroundImageUrl})
  `;

export const generateColors = (primary, secondary) => ({
  primary: color(primary).hex(),
  "brand-primary-dark": color(primary)
    .darken(0.1)
    .hex(),
  "brand-primary-light": color(primary)
    .lighten(0.1)
    .hex(),
  "primary-dark": color(primary)
    .darken(0.1)
    .hex(),
  "primary-light": color(primary)
    .lighten(0.1)
    .hex(),
  secondary: color(secondary).hex(),
  "secondary-light": color(secondary)
    .lighten(0.13)
    .hex(),
  "secondary-dark": color(secondary)
    .darken(0.2)
    .hex(),
});

export const customSelectStyles = (state, big = false) => {
  const primaryColor = state.project.colors.primary;

  return {
    option: (styles, { data, isSelected, isFocused }) => ({
      ...styles,
      backgroundColor: isSelected ? primaryColor : isFocused ? "#efefef" : null,
      color: isSelected ? "#fff" : data.color,
      fontSize: "14px",
    }),
    indicatorSeparator: styles => ({
      ...styles,
      display: "none",
    }),
    control: (styles, { isFocused }) => ({
      ...styles,
      borderColor: "#efefef",
      fontSize: big ? "18px" : "14px",
      boxShadow: isFocused ? `0 0 0 1px ${primaryColor}` : "#efefef",
      "&:hover": {
        border: "1px solid #ccc",
        borderColor: isFocused ? primaryColor : "#ccc",
      },
    }),
  };
};

const colors = (primary, secondaryColor) => [
  primary,
  secondaryColor,
  "#9D2933",
  "#D9B611",
  "#A87CA0",
  "#006442",
  "#5B8930",
  "#26C281",
  "#044F67",
  "#FCC9B9",
  "#5D3F6A",
  "#E08A1E",
  "#875F9A",
  "#FFB3A7",
  "#8F1D21",
  "#9B59B6",
  "#CF3A24",
  "#4B77BE",
  "#22A7F0",
  "#D24D57",
  "#F5D76E",
  "#2ABB9B",
  "#FFA631",
  "#CA6924",
  "#7A942E",
  "#C93756",
  "#CF000F",
  "#003171",
  "#BE90D4",
  "#16A085",
  "#FFB94E",
  "#8E44AD",
  "#89C4F4",
  "#87D37C",
  "#F7CA18",
  "#BF55EC",
  "#C91F37",
  "#26A65B",
  "#8DB255",
  "#DC3023",
  "#F47983",
  "#4DAF7C",
  "#4D8FAC",
  "#FAA945",
  "#317589",
  "#FFB61E",
  "#03A678",
  "#FFA400",
  "#DB5A6B",
  "#BFBFBF",
  "#36D7B7",
  "#F3C13A",
  "#E29C45",
  "#59ABE3",
  "#1F4788",
  "#F4D03F",
  "#8D608C",
  "#F22613",
  "#E2B13C",
  "#F9690E",
  "#5D8CAE",
  "#6B9362",
  "#763568",
  "#C3272B",
  "#89729E",
  "#E68364",
  "#19B5FE",
  "#407A52",
  "#F58F84",
  "#264348",
  "#A17917",
  "#F62459",
  "#F5AB35",
  "#5B3256",
  "#48929B",
];

export default colors;
