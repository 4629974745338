import "../../../css/org-unit/showreel.scss";
import PropTypes from "prop-types";
import _isEqual from "lodash/isEqual";

import React, { Component } from "react";

import classNames from "classnames";

class Showreel extends Component {
  constructor(props) {
    super(props);

    const activeIndex = 0;
    const interval = 6000;
    const intervalId = null;

    this.state = {
      activeIndex,
      interval,
      intervalId,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      this.state.activeIndex !== nextState.activeIndex ||
      !_isEqual(nextProps.children, this.props.children)
    );
  }

  componentDidMount() {
    this.startShowreel();
  }

  componentWillUnmount() {
    clearInterval(this.state.intervalId);
  }

  UNSAFE_componentWillReceiveProps(newProps) {
    if (!_isEqual(newProps.children, this.props.children)) {
      this.setState({ activeIndex: 0 });
      if (this.state.intervalId) {
        clearInterval(this.state.intervalId);
        this.startShowreel();
      }
    }
  }

  startShowreel() {
    const intervalId = setInterval(this.next, this.state.interval);
    this.setState({ intervalId });
  }

  next = () => {
    const i = this.state.activeIndex + 1;
    if (i === this.props.children.length)
      this.setState(() => ({ activeIndex: 0 }));
    else this.setState(() => ({ activeIndex: i }));
  };

  render() {
    if (!this.props.children.length || !this.state.intervalId) {
      return null;
    }
    return (
      <div className="showreel">
        {this.props.children.map((child, index) => (
          <div
            key={`fadethrough-item${index}`}
            className={classNames("showreel__picture", {
              active: index === this.state.activeIndex,
            })}
          >
            {child}
          </div>
        ))}
      </div>
    );
  }
}

Showreel.propTypes = {
  children: PropTypes.array,
};

export default Showreel;
