import "../../css/footer.scss";

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import OpenDataNotice from "./OpenDataNotice";

const propTypes = {
  donorLogo: PropTypes.string,
};

const Footer = props => (
  <footer className="primary-background">
    <div className="footer__columns">
      <div className="footer__column">
        <h4>{props.t("footer.issued_by")}</h4>
        <p>
          <img
            src={props.project.logoUrl}
            alt={props.project.ministry}
            width={200}
          />
        </p>
        <p>{props.project.ministry}</p>
      </div>
      <div className="footer__column">
        <h4>{props.t("footer.powered_by")}</h4>
        <h5>Dhis2</h5>
        <p>
          <a
            href="https://www.dhis2.org/"
            rel="noopener noreferrer"
            target="_blank"
          >
            https://www.dhis2.org/
          </a>
        </p>
      </div>
      <div className="footer__column">
        <h4>{props.t("footer.made_by")}</h4>
        <h5>Bluesquare</h5>

        <p>
          <a href="https://www.bluesquarehub.com">
            https://www.bluesquarehub.com
          </a>
        </p>
      </div>
    </div>

    <div className="footer__licence container">
      <OpenDataNotice project={props.project} />
    </div>
  </footer>
);

Footer.propTypes = propTypes;

const mapStateToProps = state => ({
  project: state.project,
});

export default withTranslation()(connect(mapStateToProps)(Footer));
