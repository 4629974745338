import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import classNames from "classnames";

function ExportButton({ t, branding, exportSheetUrl, split }) {
  if (!exportSheetUrl) return <span />;

  return (
    <div
      className={classNames({
        container: !split,
        "container-fluid": split,
      })}
    >
      <div className="row">
        <div className=" col col-sm-12 centered-text">
          <div className="section">
            <a
              href={exportSheetUrl}
              rel="noopener noreferrer"
              target="_blank"
              className={`centered btn btn-lg btn-primary ${branding}-background ${branding}-border-color`}
            >
              <i className="fa fa-download icon--left" />{" "}
              {t("CSVIndicators.exportData")}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

ExportButton.defaultProps = {
  split: false,
};

const mapStateToProps = state => ({
  exportSheetUrl: state.project.exportSheetUrl,
  branding: state.project.colors.buttons,
});

export default withTranslation()(connect(mapStateToProps)(ExportButton));

ExportButton.propTypes = {
  branding: PropTypes.string,
  exportSheetUrl: PropTypes.string,
  split: PropTypes.bool,
  t: PropTypes.func,
};
