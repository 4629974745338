import * as types from "../constants/actions-type";

function map(
  state = {
    choroplethIndicators: [],
    currentChoroplethIndicator: null,
    fetchingChorplethValues: true,
    choroplethValuesById: {},
    currentGroupSetId: null
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_CHOROPLETH_INDICATORS_VALUES:
      return {
        ...state,
        fetchingChorplethValues: true,
      };
    case types.RECEIVE_CHOROPLETH_INDICATORS_VALUES:
      return {
        ...state,
        fetchingChorplethValues: false,
        choroplethValuesById: {
          ...state.choroplethValuesById,
          ...action.data,
        },
      };
    case types.RECEIVE_CHOROPLETH_INDICATORS:
      return {
        ...state,
        choroplethIndicators: action.indicators,
      };
    case types.UPDATE_SELECTED_CHOROPLETH:
      return {
        ...state,
        currentChoroplethIndicator: action.indicator,
      };
    case types.UPDATE_SELECTED_GROUP_SET:
      return {
        ...state,
        currentGroupSetId: action.groupSetId,
      };
    default:
      return state;
  }
}

export default map;
