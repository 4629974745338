import "../../../css/org-unit/org-unit-summary.scss";
import _isEqual from "lodash/isEqual";

import React, { Component } from "react";

import PropTypes from "prop-types";
import _sampleSize from "lodash/sampleSize";
import { connect } from "react-redux";
import OrgUnitBoxMedia from "./OrgUnitBoxMedia";
import { fromParent } from "../../reducers/entities";
import { isZone } from "../../lib/orgUnitUtils";

const entityPhotoPlaceholder = require("../../../img/topography-dark.png");

class OrgunitSummary extends Component {
  state = {
    lightBoxOpen: false,
    sampleOrgUnits: [],
  };

  componentDidMount() {
    this.selectRandowEntitiesPics(
      this.props.orgUnit,
      this.props.entitiesFromParent,
    );
  }

  selectRandowEntitiesPics(orgUnit, entitiesFromParent) {
    if (isZone(orgUnit) && !!entitiesFromParent.length) {
      const entitiesWithPics = entitiesFromParent.filter(
        entity => !!entity.imgUrl,
      );

      this.setState({
        sampleOrgUnits: _sampleSize(entitiesWithPics, 4),
      });
    } else {
      this.setState({
        sampleOrgUnits: orgUnit.imgUrl ? [orgUnit] : [],
      });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.orgUnit.id !== this.props.orgUnit.id) {
      this.selectRandowEntitiesPics(
        nextProps.orgUnit,
        nextProps.entitiesFromParent,
      );
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.orgUnit.id !== this.props.orgUnit.id ||
      !_isEqual(nextState.sampleOrgUnits, this.state.sampleOrgUnits) ||
      this.state.lightBoxOpen !== nextState.lightBoxOpen
    );
  }

  toggleLightBox = () => {
    this.setState({ lightBoxOpen: !this.state.lightBoxOpen });
  };

  render() {
    const { orgUnit } = this.props;
    return (
      <div className={"org-unit-summary"}>
        <OrgUnitBoxMedia
          toggleLightBox={this.toggleLightBox}
          lightBoxOpen={this.state.lightBoxOpen}
          orgUnit={orgUnit}
          entityPhotoPlaceholder={this.props.entityPhotoPlaceholder}
          displayPlaceholder={!this.state.sampleOrgUnits.length}
          sampleOrgUnits={this.state.sampleOrgUnits}
        />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const entitiesFromParent = fromParent();
  return {
    entitiesFromParent: entitiesFromParent(state, props.orgUnit.id),
    entityPhotoPlaceholder,
    orgUnit: props.orgUnit,
  };
};

export default connect(mapStateToProps)(OrgunitSummary);

OrgunitSummary.propTypes = {
  orgUnit: PropTypes.object,
  entitiesFromParent: PropTypes.array,
};
