import { RECEIVE_TOP_ENTITIES } from "../constants/actions-type";

function topEntities(
  state = {
    isFetching: true,
    byDataElementId: {},
  },
  action,
) {
  switch (action.type) {
    case RECEIVE_TOP_ENTITIES:
      return Object.assign({}, state, {
        isFetching: false,
        byDataElementId: { ...action.byDataElementId },
      });
    default:
      return state;
  }
}

export default topEntities;

export const topEntitiesForDataElement = (topEnt, dataElementId, period) => {
  const topEntitiesList = topEnt.byDataElementId[dataElementId];
  return topEntitiesList ? topEntitiesList[period] : [];
};
