import { Route, Switch, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../LoadingSpinner";
import Publication from "./Publication";
import PublicationsList from "./PublicationsList";
import { fetchPublications } from "../../actions/publications";

class PublicationsContainer extends React.Component {
  componentDidMount() {
    this.props.fetchPublications();
  }

  render() {
    const { isFetching, match } = this.props;

    if (isFetching) return <LoadingSpinner />;

    return (
      <Switch>
        <Route path={`${match.url}/:id`} component={Publication} />
        <Route strict path={`${match.url}`} component={PublicationsList} />
      </Switch>
    );
  }
}

function mapStateToProps(state) {
  const { isFetching } = state.publications;
  return {
    isFetching,
  };
}

PublicationsContainer.propTypes = {
  isFetching: PropTypes.bool,
  fetchPublications: PropTypes.func,
  initializePublicationsContainer: PropTypes.func,
  match: PropTypes.object,
};

export default withRouter(
  connect(mapStateToProps, { fetchPublications })(PublicationsContainer),
);
