import "../../css/org-unit-legend.scss";

import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { Tooltip } from "react-tippy";
import { withTranslation } from "react-i18next";

import ColorLegend from "./ColorLegend";
import { formattedName } from "../lib/uiUtils";

const propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  active: PropTypes.bool,
  onClickOrgUnitLegend: PropTypes.func,
  clickable: PropTypes.bool,
  showTooltip: PropTypes.bool,
  t: PropTypes.func,
};

const OrgUnitLegend = ({
  name,
  color,
  active,
  onClickOrgUnitLegend,
  clickable,
  showTooltip,
  t,
}) => (
  <div
    className={classNames("org-unit-legend", {
      "org-unit-legend--clickable": clickable,
      "org-unit-legend--not-active": !active,
    })}
    onClick={onClickOrgUnitLegend}
  >
    <Tooltip
      title={
        active
          ? t("graphLegends.tootltipActive")
          : t("graphLegends.tootltipInActive")
      }
      position="top"
      size="big"
      arrow
      disabled={!showTooltip}
      className="org-unit-legend__tooltip"
    >
      <ColorLegend color={color} />
      <span>{formattedName(name)}</span>
    </Tooltip>
  </div>
);

OrgUnitLegend.propTypes = propTypes;

export default withTranslation()(OrgUnitLegend);
