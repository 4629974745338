/* global window, navigator */

import React, { Component } from "react";
import PropTypes from "prop-types";
import { parse } from "querystring";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import Indicator from "../KeyIndicators/Indicator";

class DataReporting extends Component {
  domain() {
    return (
      parse(this.props.location.search).domain ||
      process.env.PROJECT_DOMAIN ||
      window.location.hostname
    );
  }

  handleClick = () => {
    const location = navigator.geolocation.getCurrentPosition(
      loc => loc.coords,
    );

    const mailtToCode = this.props.t("contact", {
      orgUnitName: this.props.orgUnit.name,
      email: this.props.email,
      id: this.props.orgUnit.id,
      domain: this.domain(),
      lat: (location || {}).latitude || "N/A",
      long: (location || {}).longitude || "N/A",
    });

    window.location.href = mailtToCode;
  };

  emailButton() {
    return (
      <a onClick={this.handleClick} className="color-primary">
        {this.props.t("buttons.createTicket")}
      </a>
    );
  }

  render() {
    if (!this.props.email) {
      return null;
    }
    return (
      <Indicator
        key={`attribute-listitem-reporting`}
        title={this.props.t("attributesList.reportInaccuracies")}
        showProgress={false}
        compact
        valueType="TEXT"
        value={this.emailButton()}
      />
    );
  }
}

DataReporting.propTypes = {
  email: PropTypes.string,
  location: PropTypes.object,
  orgUnit: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(withRouter(DataReporting));
