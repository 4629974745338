import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

const propTypes = {
  toggleModal: PropTypes.func.isRequired,
  acceptCookies: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const CookiesBanner = ({ toggleModal, acceptCookies, t }) => (
  <div className="cookies-banner">
    <div>{t("cookies.bannerTextPart1")}</div>
    <div>
      {t("cookies.bannerTextPart2")}:{" "}
      <a
        href={t("cookies.bannerLink")}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t("cookies.bannerLinkText")}
      </a>
    </div>
    <div className="cookies-banner-actions">
      <button
        className="btn btn-sm btn-primary primary-background primary-border-color"
        onClick={() => toggleModal()}
      >
        {t("cookies.settings")}
      </button>
      <button
        className="btn btn-sm btn-primary primary-background primary-border-color"
        onClick={() => acceptCookies()}
      >
        {t("cookies.validate")}
      </button>
    </div>
  </div>
);

CookiesBanner.propTypes = propTypes;

export default withTranslation()(CookiesBanner);
