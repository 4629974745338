import "../../../css/search-container.scss";

import React, { Component } from "react";

import _debounce from "lodash/debounce";
import _groupBy from "lodash/groupBy";
import _values from "lodash/values";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import PropTypes from "prop-types";
import ReactModal from "react-modal";
import { getSuggestions } from "../../lib/orgUnitSearchUtils";
import SearchResults from "./SearchResults";
import SearchForm from "./SearchForm";
import PyramidBrowser from "./PyramidBrowser";
import "../../../css/search-toggle.scss";

class SearchContainer extends Component {
  state = {
    liveSearch: false,
    suggestionsCategories: {},
    query: null,
    viewingOrgUnit: this.props.currentZone,
  };

  handelQueryChange = query => {
    const suggestionsCategories = _groupBy(
      getSuggestions(query, this.props.searchableItems),
      "level",
    );

    this.setState({
      query,
      suggestionsCategories,
    });
  };

  handleViewingOrgUnitChange = orgUnit => {
    this.setState({
      viewingOrgUnit: orgUnit,
    });
  };

  render() {
    return (
      <ReactModal
        className="dv-modal dv-modal--big raised-box"
        overlayClassName="dv-overlay"
        isOpen={this.props.searchActive}
        shouldCloseOnOverlayClick
        closeTimeoutMS={100}
        onRequestClose={() => this.props.toggleSearch()}
        aria={{
          labelledby: "modal__header",
        }}
      >
        <div className={`search-container`}>
          <button
            onClick={this.props.toggleSearch}
            className="search-toggle search-toggle--close btn"
          >
            <i className="fa fa-close" />
          </button>
          <SearchForm handleChange={_debounce(this.handelQueryChange, 200)} />
          {this.state.query ? (
            <SearchResults
              searching={this.state.searching}
              query={this.state.query}
              suggestionsCategories={this.state.suggestionsCategories}
            />
          ) : (
            <PyramidBrowser
              viewingOrgUnit={this.state.viewingOrgUnit}
              handleScopeChange={this.handleViewingOrgUnitChange}
            />
          )}
        </div>
      </ReactModal>
    );
  }
}

SearchContainer.propTypes = {
  currentZone: PropTypes.object,
  searchActive: PropTypes.bool,
  searchableItems: PropTypes.array,
  toggleSearch: PropTypes.func,
};

const zonesById = state => state.zones.byId;
const entitiesById = state => state.entities.byId;

const searchableItems = createSelector(
  [zonesById, entitiesById],
  (zones, entities) => [..._values(zones), ..._values(entities)],
);

const mapStateToProps = state => ({
  searchableItems: searchableItems(state),
});
export default connect(mapStateToProps)(SearchContainer);
