import React, { Component } from "react";
import classNames from "classnames";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { setActiveTab } from "../../actions/ui";
import scrollSpy from "../../lib/scrollSpy";
import { parametrize } from "../../lib/routerUtils";

class SeparatorSection extends Component {
  sectionName = parametrize(this.props.section.name);

  render() {
    const { hidden } = this.props;
    return (
      <section
        className={classNames("section section--separator", {
          "section--separator--hidden": hidden,
        })}
        id={`section-${this.sectionName}`}
        ref={el => scrollSpy.spyOn(el, this.sectionName)}
      >
        <span className="primary-background">{this.props.section.name}</span>
      </section>
    );
  }
}

SeparatorSection.defaultProps = {
  hidden: false,
};

SeparatorSection.propTypes = {
  section: PropTypes.object,
  hidden: PropTypes.bool,
};

export default connect(null, { setActiveTab })(SeparatorSection);
