import axios from "axios";
import * as types from "../constants/actions-type";

import { receiveChoroplethIndicators, updateSelectedChoropleth, updateSelectedGroupSet } from "./map";

import i18n from "../i18n/i18n";
import { receivePeriods } from "./periods";
import { setMapLayers } from "./ui";
import { LAYERS } from "../constants/ui";

function setEntitiesLevel(level) {
  return {
    type: types.SET_ENTITIES_LEVEL,
    level,
  };
}

const loadBootstrapData = data => ({
  type: types.LOAD_BOOTSTRAP_DATA,
  data,
});

const prepareMapLayers = project => {
  const mapLayers = {};
  mapLayers.groups = !!(project.displayedGroups || []).length;
  mapLayers.choropleth = !!project.map.choroplethIndicators.length;
  let activeMapLayer = null;
  if (mapLayers.groups) {
    activeMapLayer = LAYERS.groups;
  } else if (mapLayers.choropleth) {
    activeMapLayer = LAYERS.choropleth;
  }
  return {
    mapLayers,
    activeMapLayer,
  };
};

export const fetchProject = domain => dispatch =>
  axios
    .get(`${process.env.FRONTOFFICE_MANAGER_URL}/bootstrap?domain=${domain}`)
    .then(response => {
      const { data } = response;
      i18n.changeLanguage(data.languages.default);
      dispatch(setMapLayers(prepareMapLayers(data)));
      dispatch(receivePeriods(data.periods));
      dispatch(setEntitiesLevel(data.entitiesLevel));
      dispatch(receiveChoroplethIndicators(data.map.choroplethIndicators));
      dispatch(updateSelectedChoropleth(data.map.choroplethIndicators[0]));
      if ((data.displayedGroups || []).length < 1) {
        data.displayedGroups = [];
      }
      if (data.displayedGroups[0] && (data.displayedGroups[0].groupSet || data.displayedGroups[0].groupSets)) {
        let groupSetId = null;
        if (data.displayedGroups[0].groupSet) {
          // xxx - Remove when groupSet_S_ goes live
          groupSetId = data.displayedGroups[0].groupSet.id;
        } else {
          groupSetId = data.displayedGroups[0].groupSets[0].id;
        }
        // In some edge cases, a groupSet might not be defined on the displayedGroups
        // per project (usually a faulty cache). This makes sure we don't break, in the
        // dataviz you'll see the legend not loading, until you select a group.
        dispatch(updateSelectedGroupSet(groupSetId));
      }
      return dispatch(loadBootstrapData(data));
    });

export const togglePerformanceLibs = () => ({
  type: types.TOGGLE_PERFORMANCE_LIBS,
});
