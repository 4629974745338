import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import CsvDownloader from "./CsvDownloader";
import { formattedPeriod } from "../lib/periodsUtils";

const propTypes = {
  periods: PropTypes.array,
  dataByOrgUnit: PropTypes.array,
  data: PropTypes.array,
};

class IndicatorTable extends React.Component {
  formattedData(data) {
    return data.map(orgUnit => {
      const base = { orgUnits: orgUnit.name };
      orgUnit.data.map(period => (base[period.x] = period.y));
      return base;
    });
  }

  tableHeaders(periods) {
    const periodsLabels = periods
      .map(period => formattedPeriod(period))
      .map(period => ({
        displayName: period,
        id: period,
      }));
    return [{ displayName: "orgUnits", id: "orgUnits" }, ...periodsLabels];
  }

  render() {
    const headers = this.tableHeaders(this.props.periods);
    const data = this.formattedData(this.props.dataByOrgUnit);
    return (
      <CsvDownloader
        columns={headers}
        datas={data}
        filename={this.props.title}
        separator=";"
      >
        CSV
      </CsvDownloader>
    );
  }
}

const mapStateToProps = state => ({
  periods: state.period.selected,
});

export default connect(mapStateToProps)(IndicatorTable);

IndicatorTable.propTypes = propTypes;
