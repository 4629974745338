import _values from "lodash/values";
import { entitiesFor, isEntityParent, getSubZones } from "../reducers/zones";
import indicatorUtils from "./indicatorUtils";

export const enrichEntities = (entities, entitiesLevel) =>
  entities.map(entity => ({
    ...entity,
    level: entitiesLevel,
    type: "entity",
  }));

export const getLocalEntities = (
  state,
  currentZone,
  displayLocalEntities,
  currentChoroplethIndicator,
) => {
  if (!displayLocalEntities) {
    return [];
  }
  const entitiesForCurrentOrgUnit = entitiesFor(state.entities, currentZone.id);
  return currentChoroplethIndicator
    ? entitiesForCurrentOrgUnit.map(facility =>
        indicatorUtils.withChoroplethValues(
          state,
          facility,
          currentChoroplethIndicator,
        ),
      )
    : entitiesForCurrentOrgUnit;
};

export const isParentOrCurrentEntity = (
  entitiesParentIds,
  zoneId,
  activeEntity,
) => !!activeEntity || isEntityParent(entitiesParentIds, zoneId);

export const getSurroundingGeozones = (state, currentZone) => {
  if (!currentZone.parentId) {
    return [];
  }

  return getSubZones(state, currentZone.parentId).filter(
    zone => zone.id !== currentZone.id,
  );
};

export const getCurrentInactiveGeoZones = state =>
  _values(state.inactiveGeozones.byId);

export const isZone = orgUnit => orgUnit.type === "zone";

export const decorateWithFilter = (entities, filterData, filterActive) => {
  if (!filterActive || !filterData) {
    return entities.map(e => {
      e.overRuledColor = null;
      return e;
    });
  }

  return entities.map(entity => {
    const found = filterData.find(arr => arr[0] == entity.id);
    if (found) {
      if (found[1] == "100.0" || found[1] == "1.0") {
        entity.overRuledColor = "green";
      } else {
        entity.overRuledColor = "red";
      }
      return entity;
    } else {
      // Don't show entities without data
      return null;
    }
  }).filter((obj) => obj);
}
