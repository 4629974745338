import _keyBy from "lodash/keyBy";
import geoUtils from "../lib/geoUtils";
import getOrgUnitData from "./orgUnit";
import { receiveEntitties } from "./entities";
import { receiveGeozones } from "./zones";
import { receiveInactiveGeozones } from "./inactiveGeozones";
import { enrichEntities } from "../lib/orgUnitUtils";

const initializer = (orgUnitType, orgUnitId) => (dispatch, getState) => {
  const { project } = getState();
  const projectId = project.id;
  const filtersEnabled = (project.featureToggles || {mapFilters: false}).mapFilters;

  geoUtils
    .fetchAllGeoZones(projectId, filtersEnabled)
    .then(({ geozones, inactiveGeozones, entities, filters }) => {
      const enrichedEntities = enrichEntities(entities, project.entitiesLevel);
      dispatch(receiveEntitties(_keyBy(enrichedEntities, "id")));
      dispatch(receiveGeozones(geozones));
      dispatch(receiveInactiveGeozones(inactiveGeozones));
      dispatch(getOrgUnitData(orgUnitType, orgUnitId));
      dispatch({type: "RECEIVE_FILTERS", filters})
    });
};

export default initializer;
