/* global document */
import "./i18n/i18n";

import ReactDOM from "react-dom";
import { Route, Router } from "react-router-dom";
import { Provider } from "react-redux";
import React from "react";

import AppWrapper from "./components/AppWrapper";
import MaintenancePage from "./components/MaintenancePage";
import history from "./config/history";
import store from "./store/store";

import icons from "../css/embedded-woff.css";

const MOUNT_NODE = document.getElementById("app");

let app;

if (process.env.MAINTENANCE_MODE === "true") {
  app = <MaintenancePage />;
} else {
  app = (
    <Provider store={store}>
      <Router history={history}>
        <Route path="/" component={AppWrapper} />
      </Router>
    </Provider>
  );
}

if (module.hot) {
  module.hot.accept();
}

ReactDOM.render(app, MOUNT_NODE);
