import "react-vis/dist/styles/plot.scss";

import PropTypes from "prop-types";
import React from "react";
import sortBy from "lodash/sortBy";
import ColorLegend from "../ColorLegend";
import { formatLabel } from "../../lib/graphsUtils";
import { formattedName } from "../../lib/uiUtils";

const propTypes = {
  values: PropTypes.array,
  getOrgUnitName: PropTypes.func,
};

const GraphTooltip = ({ values }) => (
  <div className="graph-tooltip raised-box">
    <div className="text-center graph-tooltip__header">
      {formatLabel(values[0].x, "period")}
    </div>
    <table>
      <tbody>
        {sortBy(values, [value => value.y])
          .reverse()
          .map((value, index) => (
            <tr className="graph-tooltip__line" key={index}>
              <td>
                <ColorLegend color={value.color} /> {formattedName(value.name)}
              </td>
              <td className="graph-tooltip__line__value">
                {value.formattedValue} {value.unit}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  </div>
);

GraphTooltip.propTypes = propTypes;

export default GraphTooltip;
