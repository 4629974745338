import React, { PureComponent } from "react";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app

import PropTypes from "prop-types";
import classNames from "classnames";
import Showreel from "./Showreel";
import { CLOUDINARY_URL, CLOUDINARY_URL_BIG } from "../../constants/ui";
import { orgUnitPath, redirectTo } from "../../lib/routerUtils";

class OrgUnitBoxMedia extends PureComponent {
  state = {
    currentImage: 0,
  };

  croppedImgUrl(imgUrl, big = false) {
    if (imgUrl === null) {
      return this.props.entityPhotoPlaceholder;
    }
    return `${big ? CLOUDINARY_URL_BIG : CLOUDINARY_URL}${encodeURIComponent(
      imgUrl,
    )}`;
  }

  goToImage = index => {
    this.setState({ currentImage: index });
  };

  toggleLightBox = index => {
    this.setState({ currentImage: index });
    this.props.toggleLightBox();
  };

  handleImageClick = orgUnit => {
    this.props.toggleLightBox();
    redirectTo(orgUnitPath(orgUnit));
  };

  render() {
    const { sampleOrgUnits, displayPlaceholder, orgUnit } = this.props;
    const images = sampleOrgUnits.map(ou => ({
      src: this.croppedImgUrl(ou.imgUrl, true),
      title: <a onClick={() => this.handleImageClick(ou)}>{ou.name}</a>,
    }));

    const imagesSources = images.map(img => img.src);
    const imagesTitles = images.map(img => img.title);

    return (
      <div
        className={classNames("org-unit-summary__media ", {
          "org-unit-summary__media--expanded": !displayPlaceholder,
          "org-unit-summary__media--placeholder": displayPlaceholder,
        })}
      >
        <Showreel>
          {sampleOrgUnits.map((ou, index) => (
            <figure
              onClick={() => this.toggleLightBox(index)}
              key={index}
              className={classNames("showreel-picture", {
                "with-stretched-background": !displayPlaceholder,
              })}
              style={{
                backgroundImage: `url(${this.croppedImgUrl(ou.imgUrl)})`,
              }}
            />
          ))}
        </Showreel>
        {!displayPlaceholder &&
          sampleOrgUnits.length &&
          this.props.lightBoxOpen && (
            <Lightbox
              mainSrc={imagesSources[this.state.currentImage]}
              nextSrc={
                imagesSources[
                  (this.state.currentImage + 1) % imagesSources.length
                ]
              }
              prevSrc={
                imagesSources[
                  (this.state.currentImage + imagesSources.length - 1) %
                    imagesSources.length
                ]
              }
              onCloseRequest={this.toggleLightBox}
              onMovePrevRequest={() =>
                this.goToImage(
                  (this.state.currentImage + imagesSources.length - 1) %
                    imagesSources.length,
                )
              }
              onMoveNextRequest={() =>
                this.goToImage(
                  (this.state.currentImage + 1) % imagesSources.length,
                )
              }
              imageTitle={imagesTitles[this.state.currentImage]}
            />
          )}
      </div>
    );
  }
}

export default OrgUnitBoxMedia;

OrgUnitBoxMedia.propTypes = {
  displayPlaceholder: PropTypes.bool,
  entityPhotoPlaceholder: PropTypes.string,
  isZone: PropTypes.bool,
  lightBoxOpen: PropTypes.any,
  orgUnit: PropTypes.object,
  orgUnitPictures: PropTypes.arrayOf(PropTypes.string),
  sampleOrgUnits: PropTypes.any,
  showReel: PropTypes.bool,
  toggleLightBox: PropTypes.any,
};
