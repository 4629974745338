import React, { Component, Fragment } from "react";
import { GeoJSON } from "react-leaflet";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import GeoZones from "./GeoZones";
import NationalGeoJson from "./NationalGeoJson";
import { validCoordinates } from "../../lib/geoUtils";

class BoundariesContainer extends Component {
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.currentZone.id !== this.props.currentZone.id ||
      nextProps.activeMapLayer !== this.props.activeMapLayer ||
      isEqual(
        this.props.currentChoroplethIndicator,
        nextProps.currentChoroplethIndicator,
      )
    );
  }

  render() {
    const {
      currentZone,
      subZones,
      inactiveGeozones,
      surroundingGeozones,
      colors,
      activeMapLayer,
      currentChoroplethIndicator,
    } = this.props;
    if (!currentZone) {
      return null;
    }
    return (
      <Fragment>
        {validCoordinates(currentZone) && (
          // Viewable on entity level
          <GeoJSON
            clickable={false}
            key={`geojson-${currentZone.id}`}
            data={currentZone.geoJson}
            style={colors.currentZone()}
            onAdd={e => {
              e.target.bringToFront();
            }}
          />
        )}
        {subZones.length && (
          <GeoZones
            currentChoroplethIndicator={currentChoroplethIndicator}
            currentZone={currentZone}
            geozones={subZones}
            variant="sub"
            colors={colors}
            activeMapLayer={activeMapLayer}
          />
        )}
        {surroundingGeozones.length && (
          <GeoZones
            currentChoroplethIndicator={currentChoroplethIndicator}
            currentZone={currentZone}
            geozones={surroundingGeozones}
            variant="surrounding"
            colors={colors}
          />
        )}
        {inactiveGeozones.length && (
          <GeoZones
            currentChoroplethIndicator={currentChoroplethIndicator}
            currentZone={currentZone}
            geozones={inactiveGeozones}
            variant="inactive"
            colors={colors}
          />
        )}
      </Fragment>
    );
  }
}

BoundariesContainer.propTypes = {
  activeMapLayer: PropTypes.string,
  colors: PropTypes.object,
  currentChoroplethIndicator: PropTypes.object,
  currentZone: PropTypes.object,
  inactiveGeozones: PropTypes.arrayOf(PropTypes.object),
  map: PropTypes.object,
  subZones: PropTypes.arrayOf(PropTypes.object),
  surroundingGeozones: PropTypes.arrayOf(PropTypes.object),
};

export default BoundariesContainer;
