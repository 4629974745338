import "../../../css/search-form.scss";

import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class SearchForm extends Component {
  handleChange = () => {
    this.props.handleChange(this.el.value);
  };

  render() {
    return (
      <div className="search-form">
        <input
          ref={el => (this.el = el)}
          type="text"
          placeholder={this.props.t("searchBox.search")}
          className="form-control"
          onChange={this.handleChange}
        />
      </div>
    );
  }
}

export default withTranslation()(SearchForm);
