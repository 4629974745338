import { interpolateHcl } from "d3-interpolate";
import { rgb } from "d3-color";
import { scaleLinear } from "d3-scale";
import sortBy from "lodash/sortBy";
import { nerfValue } from "../../lib/graphsUtils";

class GeoZoneColors {
  constructor(project) {
    this.mapColorVariant = project.colors.map;
    this.cssPalette = project.cssPalette;
  }

  colorScale(value) {
    const scale = scaleLinear()
      .domain([0, 5])
      .interpolate(interpolateHcl)
      .range([rgb("#fff"), rgb(this.cssPalette.primary)]);
    return () => ({
      fillColor: scale(Math.round(nerfValue(value, 100) / 20) - 1),
      color: this.cssPalette[this.mapColorVariant],
      weight: 1,
      fillOpacity: 0.4,
    });
  }

  legendColors(currentChoroplethIndicator, value) {
    const { legendSets } = currentChoroplethIndicator;
    const legendSet = legendSets[0];
    const legends = sortBy(legendSet.legends, ["endValue"]);
    const currentLegend = legends.find(
      legend => value >= legend.startValue && value <= legend.endValue,
    );

    return () => ({
      fillColor: currentLegend ? currentLegend.color : "#efefef",
      color: this.cssPalette[this.mapColorVariant],
      weight: 1,
      fillOpacity: 0.7,
    });
  }

  boundaries() {
    return () => ({
      fillColor: this.cssPalette[this.mapColorVariant],
      color: this.cssPalette[this.mapColorVariant],
      weight: 1,
      opacity: 1,
      fillOpacity: 0.2,
      stroke: true,
    });
  }

  surroundingZone() {
    return () => ({
      fillColor: this.cssPalette[`${this.mapColorVariant}-light`],
      color: this.cssPalette[`${this.mapColorVariant}-light`],
      weight: 2,
      opacity: 1,
      fillOpacity: 0,
      stroke: true,
    });
  }

  currentZone() {
    return () => ({
      fillColor: "#efefef",
      color: this.cssPalette[this.mapColorVariant],
      weight: 1,
      opacity: 1,
      fillOpacity: 0.7,
      stroke: true,
    });
  }

  hoveredZone() {
    return () => ({
      fillOpacity: 0.7,
    });
  }

  inactiveZones() {
    return () => ({
      color: "#999",
      weight: 1,
      opacity: 1,
      fillOpacity: 0.2,
      stroke: true,
    });
  }

  noDataZones() {
    return () => ({
      fillColor: "#999",
      color: "#999",
      weight: 1,
      opacity: 0.3,
      fillOpacity: 0.3,
      stroke: true,
    });
  }
}

export default GeoZoneColors;
