import React, { Component } from "react";

import PropTypes from "prop-types";
import _isEqual from "lodash/isEqual";
import { connect } from "react-redux";
import App from "../components/App";
import { toggleSearch } from "../actions/ui";
import getOrgUnitData from "../actions/orgUnit";
import { scrollToTop } from "../lib/uiUtils";
import withTracker from "../lib/withTracker";
import initializer from "../actions/initializer";

const propTypes = {
  pageTitle: PropTypes.string,
  isFetching: PropTypes.bool,
  initializer: PropTypes.func,
};

class AppContainer extends Component {
  componentDidMount() {
    const { type, id } = this.props.match.params;
    this.props.initializer(type, id);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!_isEqual(nextProps.match.params, this.props.match.params)) {
      if (nextProps.location.state && nextProps.location.state.scrollToTop) {
        scrollToTop();
      }
      const { type, id } = nextProps.match.params;
      this.props.getOrgUnitData(type, id);

      this.props.toggleSearch(true);
    }
  }

  render() {
    if (this.props.isFetching) {
      return null;
    }
    return <App />;
  }
}

function mapStateToProps(state) {
  const { isFetching, shortDescription } = state.project;
  return { isFetching, pageTitle: shortDescription };
}

AppContainer.propTypes = propTypes;

export default withTracker(
  connect(mapStateToProps, {
    initializer,
    getOrgUnitData,
    toggleSearch,
  })(AppContainer),
);
