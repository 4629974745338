/* globals Raven */
import GoogleAnalytics from "react-ga";
import React from "react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import trackedDomains from "./trackedDomains.js";

export const analyticsId = "UA-113588186-1";
export const ravenUrl =
  "https://1859bf32503f4b5d91237744b25613f8@sentry.io/279510";
const logRocketPath = "ctjurq/blsq-dataviz-portal";

export const launchPerformanceLibs = () => {
  if (process.env.NODE_ENV !== "production") {
    GoogleAnalytics.initialize(analyticsId, "auto", { testMode: true });

    if (process.env.LOG_RERENDER === "true") {
      const { whyDidYouUpdate } = require("why-did-you-update");
      whyDidYouUpdate(React);
    }
  } else {
    GoogleAnalytics.initialize(analyticsId, "auto");

    Raven.config(ravenUrl).install();

    LogRocket.init(logRocketPath);
    setupLogRocketReact(LogRocket);
  }

  // To allow cross domain tracking we need to pass all tracked domains
  // https://developers.google.com/analytics/devguides/collection/upgrade/reference/gajs-analyticsjs#cross-domain
  GoogleAnalytics.ga("linker:autoLink", trackedDomains);
  GoogleAnalytics.ga("require", "linker");
};
