import "../../../css/graph.scss";
import Select from "react-select";
import _includes from "lodash/includes";
import React, { Component, Fragment } from "react";
import _isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import { applyContainerQuery } from "react-container-query";
import classNames from "classnames";
import { connect } from "react-redux";

import { withTranslation } from "react-i18next";
import GraphLegend from "./GraphLegend";
import Graph from "./Graph";
import GraphDownloadActions from "./GraphDownloadActions";
import { setCurrentOrgunitLegend } from "../../actions/ui";
import mediaQueries from "../../lib/browser";
import { getChartType } from "../../lib/graphsUtils";
import domToImg from "../../lib/domToImg";
import { customSelectStyles } from "../../lib/colors";
import { getName } from "../../lib/uiUtils";
import { CHART_TYPE_HEATMAP } from "../../constants/ui";
import Heatmap from "./Heatmap";

class GraphContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartWidth: 0,
      lastLocation: "",
      isFetching: false,
      graphSeriesData: props.series || [],
      graphContainer: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextProps.title !== this.props.title ||
      nextProps.containerQuery.lg !== this.props.containerQuery.lg ||
      nextProps.currentOrgunitLegend !== this.props.currentOrgunitLegend ||
      !_isEqual(nextState.graphSeriesData, this.state.graphSeriesData) ||
      nextState.downloadActive !== this.state.downloadActive ||
      nextState.hideGraphLegendToggle !== this.state.hideGraphLegendToggle
    );
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.series !== this.state.graphSeriesData) {
      this.setState({ graphSeriesData: nextProps.series });
    }
  }

  toggleSerie = serie => {
    const newSerie = { ...serie, active: !serie.active };
    const graphSeriesData = [...this.state.graphSeriesData];
    graphSeriesData[newSerie.id] = newSerie;
    if (serie.persistable) {
      const activeOrgUnitIds = graphSeriesData
        .filter(chartSerie => chartSerie.persistable && chartSerie.active)
        .map(chartSerie => chartSerie.orgUnitId);

      this.props.setCurrentOrgunitLegend(activeOrgUnitIds);
    } else {
      this.setState({ graphSeriesData });
    }
  };

  exportImage = () => {
    const { graphContainer } = this.state;
    domToImg(graphContainer, this.props.title);
  };

  title() {
    const items = this.props.graphNavigatorItems;

    if (items.length < 2) {
      return <h4>{this.props.title}</h4>;
    }

    const options = items.map(item => ({
      value: item.id,
      label: getName(item),
    }));

    const selectedOptionIndex = options.findIndex(
      option => option.value === (this.props.currentGraph || {}).id,
    );

    return (
      <div className="graphs-section__detail__title">
        <Select
          options={options}
          value={options[selectedOptionIndex]}
          defaultValue={options[selectedOptionIndex]}
          styles={this.props.customSelectStyles}
          onChange={item => this.props.onGraphSelect(item.value)}
        />
      </div>
    );
  }

  render() {
    const { graphSeriesData } = this.state;
    const { containerQuery, chartType } = this.props;
    return (
      <div className="graphs-section__detail">
        <div
          className={classNames("graphs-section__detail__header", {
            "graphs-section__detail__header--stacked": !containerQuery.lg,
          })}
        >
          {this.title()}
          {!!graphSeriesData.length && (
            <GraphDownloadActions
              title={this.props.title}
              exportImage={() => this.exportImage()}
              graphSeriesData={this.state.graphSeriesData}
            />
          )}
        </div>
        <div
          className="graphs-section__detail__body"
          ref={el =>
            this.setState({
              graphContainer: el,
            })
          }
        >
          {graphSeriesData.length ? (
            <Fragment>
              {chartType.label !== CHART_TYPE_HEATMAP ? (
                <Graph
                  title={this.props.title}
                  currency={this.props.currency}
                  scaleType={this.props.scaleType}
                  chartType={chartType}
                  graphSeriesData={this.state.graphSeriesData}
                />
              ) : (
                <Heatmap
                  title={this.props.title}
                  currency={this.props.currency}
                  scaleType={this.props.scaleType}
                  chartType={chartType}
                  graphSeriesData={this.state.graphSeriesData}
                />
              )}

              {chartType.hasLegend && (
                <GraphLegend
                  containerQuery={containerQuery}
                  series={this.state.graphSeriesData}
                  onGraphLegendClick={this.toggleSerie.bind(this)}
                />
              )}
            </Fragment>
          ) : (
            <p className="spaced">{this.props.t("noData.noDataAvailble")}</p>
          )}
        </div>
      </div>
    );
  }
}

function activateSeries(series, activeOrgUnits) {
  const activatedSeries = series.map(serie => ({
    ...serie,
    active:
      serie.persistable && serie.orgUnitId
        ? _includes(activeOrgUnits, serie.orgUnitId)
        : serie.active,
  }));

  return activatedSeries;
}

const mapStateToProps = (state, props) => {
  const { currentOrgunitLegend } = state.ui;
  const series = activateSeries(props.graphSeriesData, currentOrgunitLegend);

  return {
    series,
    currentOrgunitLegend,
    customSelectStyles: customSelectStyles(state, true),
    currency: state.project.currencyUnit,
    chartType: getChartType(props.defaultGraphType),
  };
};

export default withTranslation()(
  applyContainerQuery(
    connect(mapStateToProps, { setCurrentOrgunitLegend })(GraphContainer),
    mediaQueries,
  ),
);

GraphContainer.propTypes = {
  chartType: PropTypes.object,
  containerQuery: PropTypes.object,
  currency: PropTypes.string,
  currentGraph: PropTypes.object,
  currentOrgunitLegend: PropTypes.array,
  customSelectStyles: PropTypes.object,
  graphNavigatorItems: PropTypes.array,
  onGraphSelect: PropTypes.func,
  scaleType: PropTypes.string,
  series: PropTypes.array,
  setCurrentOrgunitLegend: PropTypes.func,
  title: PropTypes.string,
};
