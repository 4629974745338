import { CircleMarker, Marker } from "react-leaflet";
import React from "react";

import classNames from "classnames";
import { divIcon } from "leaflet";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import markerIcon from "../../../img/app-icons/marker-cs-active.svg";
import { orgUnitPath, redirectTo } from "../../lib/routerUtils";
import MarkerTooltip from "./MarkerTooltip";
import { LAYERS } from "../../constants/ui";

function EntityMarker({t, entity, section, active, groupColor, activeMapLayer, colors}) {
  const handleOnClick = () => {
    redirectTo(orgUnitPath(entity));
  };

  const choroplethActive = activeMapLayer === LAYERS.choropleth;
  const groupsActive = activeMapLayer === LAYERS.groups;
  const filtersActive = activeMapLayer === LAYERS.filters;

  const entityStyle = (entity) => {
    if (choroplethActive && entity.choroplethValue !== undefined) {
      return entity.currentChoroplethIndicator.legendSets.length
        ? colors.legendColors(
          entity.currentChoroplethIndicator,
          entity.choroplethValue,
        )
        : colors.colorScale(entity.choroplethValue);
    }
    return colors.noDataZones();
  }

  const iconClassNames = classNames("entity-marker", {
    "primary-marker primary-border-color": !active,
    "secondary-marker secondary-border-color": active,
  });

  const icon = divIcon({
    className: iconClassNames,
    html: markerIcon,
  });

  const position = entity.geoJson.geometry
        ? entity.geoJson.geometry.coordinates
        : entity.geoJson;

  if (position.length > 2 || !position[0] || !position[1]) {
    return null;
  }

  if (groupsActive) {
    return (
      <CircleMarker
        radius={active ? 12 : 8}
        center={position}
        fillOpacity={1}
        fillColor={groupColor}
        opacity={active ? 0.8 : 1}
        weight={active ? 9 : 2}
        stroke={active}
        onClick={handleOnClick}
      >
        <MarkerTooltip orgUnit={entity} section={section} />
      </CircleMarker>
    );
  }

  if (filtersActive) {
    return (
      <CircleMarker
        radius={active ? 12 : 8}
        center={position}
        fillOpacity={1}
        fillColor={entity.overRuledColor}
        opacity={active ? 0.8 : 1}
        weight={active ? 9 : 2}
        stroke={active}
        onClick={handleOnClick}
      >
        <MarkerTooltip orgUnit={entity} section={section} />
      </CircleMarker>
    );
  }
  if (choroplethActive) {
    const style = entityStyle(entity);

    return (
      <CircleMarker
        radius={active ? 12 : 8}
        center={position}
        {...style()}
        fillOpacity={1}
        opacity={active ? 0.8 : 1}
        weight={active ? 9 : 2}
        stroke={active}
        onClick={handleOnClick}
      >
        <MarkerTooltip orgUnit={entity} section={section}>
          <div>
            <h6>{entity.currentChoroplethIndicator.displayName}</h6>
            {entity.choroplethValue === undefined
             ? t("noData.noDataAvailble")
             : entity.choroplethValue}
          </div>
        </MarkerTooltip>
      </CircleMarker>
    );
  }

  return (
    <Marker
      zIndexOffset={active ? 1000 : 5}
      onClick={handleOnClick}
      icon={icon}
      position={position}
    >
      <MarkerTooltip orgUnit={entity} />
    </Marker>
  );
}

EntityMarker.propTypes = {
  active: PropTypes.bool,
  activeMapLayer: PropTypes.string,
  colors: PropTypes.object,
  entity: PropTypes.object,
  t: PropTypes.func,
  section: PropTypes.string,
};

export default withTranslation()(EntityMarker);
