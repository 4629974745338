import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import PublicationSummary from "./PublicationSummary";
import { formatDate } from "../../lib/uiUtils";

const PublicationsList = ({
  publications,
  t,
  shortDescription,
  i18n: { language },
}) => (
  <section className="publication-container">
    {publications.length === 0 && (
      <div>
        <p>{t("publications.noPublications")}...</p>
        <div style={{ height: 250 }} />
      </div>
    )}
    {publications.length > 0 && (
      <div className="container page-content">
        <div className="masthead">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xs-12 col-lg-8 col-lg-offset-2">
                <Helmet>
                  <title>
                    {t("publications.title")} - {shortDescription} - Dataviz
                  </title>
                </Helmet>
                <h1 className="masthead__title">{t("publications.title")}</h1>
              </div>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-lg-8 col-lg-offset-2">
          {publications.map(publication => (
            <div key={publication.id} className="col-xs-12 publication">
              <PublicationSummary
                url={`/pages/publications/${publication.id}`}
                {...publication}
                date={formatDate(publication.createdAt, language)}
              />
            </div>
          ))}
        </div>
      </div>
    )}
  </section>
);

const mapStateToProps = state => ({
  publications: state.publications.items,
  shortDescription: state.project.shortDescription,
});

PublicationsList.propTypes = {
  publications: PropTypes.array,
  updateTitle: PropTypes.func,
  t: PropTypes.func,
  shortDescription: PropTypes.string,
  i18n: PropTypes.object,
};

export default withTranslation()(connect(mapStateToProps)(PublicationsList));
