import React from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";

import PropTypes from "prop-types";

import _flatMap from "lodash/flatMap";
import _sortBy from "lodash/sortBy";

import withKpi from "../KeyIndicators/WithKpi";
import indicatorUtils, {
  findLastAvailableValues,
} from "../../lib/indicatorUtils";
import { getName } from "../../lib/uiUtils";
import HorizontalBarGraph from "../Graphs/HorizontalBarGraph";
import SectionDescription from "./SectionDescription";
import LoadingSpinner from "../LoadingSpinner";
import { formattedPeriod } from "../../lib/periodsUtils";

function KpiComparatorSection(props) {
  const { section, lastValues } = props;
  const indicators = _sortBy(
    _flatMap(props.indicatorsQueries, dataSource => dataSource.indicators),
    ["position"],
  ).reverse();

  const series = indicators.map(indicator => {
    const data = indicatorUtils.generateData(null, indicator.data);
    let periodData = data[data.length - 1];
    let indicatorName = getName(indicator);

    if (lastValues) {
      periodData = findLastAvailableValues(data) || periodData;
      indicatorName = `${getName(indicator)} (${formattedPeriod(
        periodData.x,
      )})`;
    }

    return {
      ...periodData,
      x: periodData.y,
      label: `${periodData.x}`,
      y: indicatorName,
      xOffset: 10,
    };
  });

  const renderSeries = !series.length ? (
    <div>{props.t("noData.missingData")}</div>
  ) : (
    <HorizontalBarGraph
      scaleType={section.scaleType}
      data={series}
      color={props.color}
      lastValues={props.lastValues}
      title={section.name}
    />
  );

  return (
    <section className={"section"} data-id={props.section.id}>
      <div className="container-fluid">
        {props.section.name && (
          <h3 className="section__header">{props.section.name}</h3>
        )}
        {props.section.description && (
          <SectionDescription text={props.section.description} />
        )}
        {props.isFetching ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          renderSeries
        )}
      </div>
    </section>
  );
}

KpiComparatorSection.propTypes = {
  section: PropTypes.object,
  color: PropTypes.string,
  isFetching: PropTypes.bool,
  lastValues: PropTypes.bool,
  indicatorsQueries: PropTypes.arrayOf(PropTypes.object),
  t: PropTypes.func,
};

const mapStateToProps = state => ({
  color: state.project.cssPalette[`${state.project.colors.visualization}`],
});

export default withTranslation()(
  connect(mapStateToProps)(withKpi(KpiComparatorSection)),
);
