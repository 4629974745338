import "../../../css/pyramid-browser.scss";

import React, { Component } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { entitiesFor, getSubZones, isEntityParent } from "../../reducers/zones";
import { getOrgUnit } from "../../reducers/orgUnits";
import { orgUnitPath } from "../../lib/routerUtils";
import SearchResultViewButton from "./SearchResultViewButton";
import { formattedName } from "../../lib/uiUtils";

function sign(item) {
  return item.type === "entity" ? "icon-facility" : "map-signs";
}

class PyramidBrowser extends Component {
  handleScopeChange = orgUnit => {
    this.props.handleScopeChange(orgUnit);
    this.scroller.scrollTop = 0;
  };

  render() {
    const { viewingOrgUnit } = this.props;
    return (
      <div className="search-results pyramid-browser">
        <h5 className="search-results__list__group__header">
          {viewingOrgUnit.parentId && (
            <i
              className="fa fa-arrow-back icon--left primary-color fake-link"
              onClick={() => this.handleScopeChange(this.props.parentOrgUnit)}
            />
          )}
          {formattedName(viewingOrgUnit.name)}
          <SearchResultViewButton
            orgUnit={viewingOrgUnit}
            className="search-results__list__group__header__view-btn"
          />
        </h5>
        <div
          className="search-results__list"
          ref={el => {
            this.scroller = el;
          }}
        >
          <div className="search-results__list__group">
            <ul className="pyramid-browser__subzones">
              {this.props.subZones.map((subzone, index) => (
                <li key={index} className="search-results__list__group__result">
                  {this.props.isEntity ? (
                    <Link to={orgUnitPath(subzone)}>
                      <i className={`fa fa-${sign(subzone)} icon--left`} />
                      {formattedName(subzone.name)}
                    </Link>
                  ) : (
                    <a
                      onClick={() => this.handleScopeChange(subzone)}
                      className="fake-link"
                    >
                      <i className={`fa fa-${sign(subzone)} icon--left`} />
                      {formattedName(subzone.name)}
                    </a>
                  )}
                  <SearchResultViewButton orgUnit={subzone} />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state, props) => {
  const isEntity = isEntityParent(
    state.entities.entitiesParentIds,
    props.viewingOrgUnit.id,
  );
  const subZones = isEntity
    ? entitiesFor(state.entities, props.viewingOrgUnit.id)
    : getSubZones(state, props.viewingOrgUnit.id);
  return {
    subZones,
    isEntity,
    parentOrgUnit: getOrgUnit(state, props.viewingOrgUnit.parentId),
  };
};
export default connect(mapStateToProps)(PyramidBrowser);
