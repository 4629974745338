/* global document */
import domtoimage from "dom-to-image";

const domToImg = (container, fileName) =>
  domtoimage.toJpeg(container, { quality: 1 }).then(dataUrl => {
    const link = document.createElement("a");
    link.download = `${fileName}.jpeg`;
    link.href = dataUrl;
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  });

export default domToImg;
