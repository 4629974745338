import "../../../css/map.scss";
import { Map, TileLayer, Pane } from "react-leaflet";
import L from "leaflet";
import PropTypes from "prop-types";
import React from "react";
import _values from "lodash/values";
import { connect } from "react-redux";
import _isEqual from "lodash/isEqual";
import { debug } from "logrocket";
import { getSubZones } from "../../reducers/zones";

import BoundariesContainer from "./BoundariesContainer";
import GeoZoneColors from "./GeoZoneColors";
import Markers from "./Markers";
import { validCoordinates, getMapBounds } from "../../lib/geoUtils";
import {
  getLocalEntities,
  isParentOrCurrentEntity,
  getSurroundingGeozones,
  getCurrentInactiveGeoZones,
  decorateWithFilter,
} from "../../lib/orgUnitUtils";
import MapControls from "./MapControls";
import { LAYERS } from "../../constants/ui";

class MapContainer extends React.Component {
  render() {
    const {
      activeEntity,
      currentZone,
      localEntities,
      surroundingGeozones,
      zoom,
      subZones,
      inactiveGeozones,
      mapBounds,
      allEntities,
      displayLocalEntities,
      colors,
      mapColor,
      currentChoroplethIndicator,
      activeMapLayer,
      activeMapGroup,
      activeFilter,
      customTileUrl,
      popupSection,
      activeGroups,
    } = this.props;

    const tilesUrl =
      customTileUrl ||
      `https://api.mapbox.com/styles/v1/blsq/cjjda4mvn0fid2sqejnkvs1fj/tiles/256/{z}/{x}/{y}?access_token=pk.eyJ1IjoiYmxzcSIsImEiOiJjaWdrdDZwYmswMDJxdGVtM2M4cDFjYncxIn0.-qdov5jozrq1yUQ9TXDOnw`;
    return (
      <Map
        bounds={mapBounds}
        zoom={zoom}
        scrollWheelZoom={false}
        attributionControl={true}
        zoomControl={false}
        renderer={L.canvas()}
      >
        <TileLayer url={tilesUrl} detectRetina={true} />

        <Pane name="dv-boundaries">
          {surroundingGeozones && currentZone && (
            <BoundariesContainer
              surroundingGeozones={surroundingGeozones}
              subZones={subZones}
              totalEntities={allEntities.length}
              currentZone={currentZone}
              inactiveGeozones={inactiveGeozones}
              colors={colors}
              activeMapLayer={activeMapLayer}
              currentChoroplethIndicator={currentChoroplethIndicator}
            />
          )}
        </Pane>

        <Pane name="dv-markers">
          {!!localEntities.length && (
            <Markers
              displayBigMarkers={true}
              entities={localEntities}
              activeEntity={activeEntity}
              currentZone={currentZone}
              colors={colors}
              mapColor={mapColor}
              activeMapLayer={activeMapLayer}
              activeMapGroup={activeMapGroup}
              activeGroups={activeGroups}
              activeFilter={activeFilter}
              section={popupSection}
            />
          )}

          {!!allEntities.length && !displayLocalEntities && (
            <Markers
              displayBigMarkers={false}
              entities={allEntities}
              activeEntity={activeEntity}
              currentZone={currentZone}
              colors={colors}
              mapColor={mapColor}
              activeMapLayer={this.props.activeMapLayer}
              activeMapGroup={this.props.activeMapGroup}
              activeGroups={activeGroups}
              activeFilter={activeFilter}
              section={popupSection}
            />
          )}
        </Pane>

        <MapControls
          toggleSearch={this.props.toggleSearch}
          toggleMapLayer={this.props.toggleMapLayer}
          activeMapLayer={this.props.activeMapLayer}
          displayLayersControl={this.props.displayLayersControl}
          currentZone={currentZone}
          activeEntity={activeEntity}
          searchActive={this.props.searchActive}
          switchPane={this.props.switchPane}
          activeFilter={this.props.activeFilter}
        />
      </Map>
    );
  }
}

const findBestPeriod = state => {
  const filterPeriod =
    state.period && state.period.selected
      ? state.period.selected.slice(-1)[0]
      : "2020";
  return filterPeriod;
};

const mapStateToProps = (state, props) => {
  const { currentZone } = props;
  const {
    map: { currentChoroplethIndicator },
    project: { cssPalette },
  } = state;
  const mapBranding = state.project.colors.map;

  const subZones = getSubZones(state, currentZone.id);

  const displayLocalEntities = isParentOrCurrentEntity(
    state.entities.entitiesParentIds,
    currentZone.id,
  );
  const { choropleth, groups } = state.ui.mapLayers;
  const { currentGroupSetId } = state.map;
  const activeGroups = state.project.displayedGroups.filter(g => {
    if (g.groupSet) {
      return g.groupSet.id === currentGroupSetId;
    }
    return g.groupSets.map(set => set.id).includes(currentGroupSetId);
  });
  const activeFilter = state.ui.filter;
  const isFiltering =
    !!state.ui.filter && state.ui.activeMapLayer === LAYERS.filters;

  let filterData;

  if (activeFilter && isFiltering) {
    const realFilterData = [];
    const mostRecentPeriods = [].concat(state.period.selected).reverse();

    for (const orgUnitId of Object.keys(state.entities.byId)) {
      let found = false;
      for (const period of mostRecentPeriods) {
        if (!found) {
          const dataForPeriod = (state.entities.filters || {})[period] || {};
          const dataForPeriodFilter = dataForPeriod[activeFilter];
          if (dataForPeriodFilter) {
            const record = dataForPeriodFilter.find(
              dataRecord => dataRecord[0] == orgUnitId,
            );
            if (record) {
              found = true;
              realFilterData.push(record);
            }
          }
        }
      }
    }

    filterData = realFilterData;
  }

  const localEntities = getLocalEntities(
    state,
    currentZone,
    displayLocalEntities,
    currentChoroplethIndicator,
  );
  const allEntities = _values(state.entities.byId).filter(entity =>
    validCoordinates(entity),
  );

  return {
    displayLayersControl: choropleth || groups,
    colors: new GeoZoneColors(state.project),
    mapColor: cssPalette[`${mapBranding}-light`],
    entitiesFetching: state.entities.isFetching,
    mapBounds: getMapBounds(currentZone, state.project.mainZone.geojson),
    zoom: state.project.map.zoomLevel,
    popupSection: state.project.map.popupSectionIdentifier,
    localEntities: decorateWithFilter(localEntities, filterData, isFiltering),
    allEntities: !displayLocalEntities
      ? decorateWithFilter(allEntities, filterData, isFiltering)
      : [],
    surroundingGeozones: getSurroundingGeozones(state, currentZone),
    inactiveGeozones: getCurrentInactiveGeoZones(state),
    displayLocalEntities,
    currentChoroplethIndicator,
    activeGroups,
    subZones,
    activeFilter,
  };
};

MapContainer.propTypes = {
  activeEntity: PropTypes.object,
  activeMapLayer: PropTypes.string,
  activeMapGroup: PropTypes.string,
  allEntities: PropTypes.array,
  colors: PropTypes.object,
  currentChoroplethIndicator: PropTypes.object,
  currentZone: PropTypes.object,
  displayLayersControl: PropTypes.bool,
  displayLocalEntities: PropTypes.bool,
  entities: PropTypes.array,
  entitiesFetching: PropTypes.bool,
  inactiveGeozones: PropTypes.array,
  localEntities: PropTypes.array,
  mapBounds: PropTypes.object,
  mapColor: PropTypes.string,
  customTileUrl: PropTypes.string,
  position: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.number),
  ]),
  searchActive: PropTypes.bool,
  subZones: PropTypes.array,
  surroundingGeozones: PropTypes.array,
  toggleMapLayer: PropTypes.func,
  toggleSearch: PropTypes.func,
  zoom: PropTypes.number,
};

export default connect(mapStateToProps)(MapContainer);
