import { combineReducers } from "redux";
import dataElements from "./dataElements";
import entities from "./entities";
import inactiveGeozones from "./inactiveGeozones";
import map from "./map";
import period from "./period";
import project from "./project";
import publications from "./publications";
import sections from "./sections";
import topEntities from "./topEntities";
import values from "./values";
import ui from "./ui";
import zones from "./zones";

const index = combineReducers({
  project,
  dataElements,
  entities,
  inactiveGeozones,
  map,
  publications,
  period,
  sections,
  values,
  topEntities,
  zones,
  ui,
});

export default index;
