import "../../../css/top-entities-list.scss";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import _isEqual from "lodash/isEqual";
import { topEntitiesForDataElement } from "../../reducers/topEntities";

import TopEntityItem from "./TopEntityItem";
import { getName } from "../../lib/uiUtils";
import { currentPeriodMax } from "../../reducers/period";

class TopEntities extends React.Component {
  shouldComponentUpdate(nextProps) {
    const entitiesChanged = !_isEqual(
      nextProps.topEntities,
      this.props.topEntities,
    );
    const classNamesChanged = !_isEqual(
      nextProps.classNames,
      this.props.classNames,
    );
    const periodChanged = !_isEqual(
      nextProps.lastPeriod,
      this.props.lastPeriod,
    );

    if (entitiesChanged) return true;
    if (classNamesChanged) return true;
    if (periodChanged) return true;

    return false;
  }

  render() {
    const { topEntities, t, dataElement, classNames, color } = this.props;
    return (
      <div className={!topEntities.length ? "col-md-6" : classNames}>
        <h4 className="top-entities-list__header">{getName(dataElement)}</h4>
        {!topEntities.length && <p>{t("noData.noDataAvailble")}</p>}
        {topEntities.map((topEntity, index) => (
          <TopEntityItem
            key={`top-entity-${topEntity.orgUnitId}-${dataElement.id}-${index}`}
            topEntity={topEntity}
            color={color}
          />
        ))}
      </div>
    );
  }
}

TopEntities.propTypes = {
  topEntities: PropTypes.array,
  classNames: PropTypes.string,
  t: PropTypes.func,
  dataElement: PropTypes.object,
  lastPeriod: PropTypes.string,
  color: PropTypes.string,
};

const topEntitiesPresenter = (state, topEntities) =>
  topEntities && topEntities.length
    ? topEntities.map(topEntity => ({
        ...topEntity,
        ...state.entities.byId[topEntity.orgUnitId],
      }))
    : [];

const mapStateToProps = (state, props) => ({
  lastPeriod: currentPeriodMax(state.period),
  topEntities: topEntitiesPresenter(
    state,
    topEntitiesForDataElement(
      state.topEntities,
      props.dataElement.id,
      currentPeriodMax(state.period),
    ),
  ),
  color: state.project.cssPalette[`${state.project.colors.visualization}`],
});

export default withTranslation()(connect(mapStateToProps)(TopEntities));
