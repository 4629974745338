import _without from "lodash/without";
import {
  RECEIVE_DATA_ELEMENTS,
  SET_SECTIONS_LOADED,
} from "../constants/actions-type";

function sections(
  state = {
    all: [],
    sectionsLoaded: false,
  },
  action,
) {
  switch (action.type) {
    case RECEIVE_DATA_ELEMENTS:
      return {
        ...state,
        all: action.sections,
      };

    case SET_SECTIONS_LOADED:
      return {
        ...state,
        sectionsLoaded: true,
      };
    default:
      return state;
  }
}

export default sections;

export const sectionById = (sectionsStore, id) =>
  sectionsStore.all.find(section => section.id === id);

export const sectionByType = (sectionsStore, type) =>
  sectionsStore.all.find(section => section.sectionType === type);

export const sectionsByType = (sectionsStore, type) =>
  sectionsStore.all.filter(section => section.sectionType === type);

export const orgUnitSections = (sectionsStore, orgUnit) => {
  const isZone = orgUnit.type === "zone";
  const allSections = sectionsStore.all || [];
  const entityInfoSection = sectionByType(sectionsStore, "entity_info");

  return isZone ? _without(allSections, entityInfoSection) : allSections;
};
