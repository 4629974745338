import "../../css/maintenance-page.scss";

import React from "react";

const logoUrl = require("../../img/dataviz-logo.svg");

function MaintenancePage() {
  return (
    <div className="maintenance">
      <div>
        <div className="maintenance__logo">
          <img src={logoUrl} alt="Dataviz logo" width="50" />
        </div>
        <p>
          Site under maintenance, we are improving the current experience and
          executing database migrations. Please come back in a few hours.
        </p>
      </div>
    </div>
  );
}

export default MaintenancePage;
