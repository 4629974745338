import React, { Component } from "react";
import { RadioGroup, Radio } from "react-radio-group";
import ReactModal from "react-modal";
import Control from "react-leaflet-control";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import FilterControl from "./FilterControl";
import PropTypes from "prop-types";
import { LAYERS } from "../../../constants/ui";

import { updateSelectedChoropleth, updateSelectedGroupSet } from "../../../actions/map";

import { getName } from "../../../lib/uiUtils";

class LayersControl extends Component {
  state = {
    layersMenuOpen: false,
  };

  handleClickOutside = () => {
    if (this.state.layersMenuOpen) {
      this.toggleLayersMenu();
    }
  };

  toggleLayersMenu = () => {
    this.setState({
      layersMenuOpen: !this.state.layersMenuOpen,
    });
  };

  handleMapLayerChange = value => {
    this.toggleLayersMenu();
    this.props.toggleMapLayer(value);
  };

  handleGroupSetChange = groupSetId => {
    this.toggleLayersMenu();
    if (this.props.activeMapLayer !== LAYERS.groups) {
      this.props.toggleMapLayer(LAYERS.groups);
    }

    this.props.updateSelectedGroupSet(groupSetId);
  }

  handleChoroplethChange = optionValue => {
    this.toggleLayersMenu();
    if (this.props.activeMapLayer !== LAYERS.choropleth) {
      this.props.toggleMapLayer(LAYERS.choropleth);
    }
    const indicator = this.props.choroplethIndicators.find(
      ind => ind.id === optionValue,
    );
    this.props.updateSelectedChoropleth(indicator);
  };

  handleFilterChange = () => {
    this.toggleLayersMenu();
    if (this.props.activeMapLayer !== LAYERS.filters) {
      this.props.toggleMapLayer(LAYERS.filters);
    }
  }

  render() {
    const {
      currentGroupSetId,
      choroplethOptions,
      selectedOptionIndex,
      activeMapLayer,
      showChoroplethControls,
      showGroupsControls,
      groupSets,
      t,
    } = this.props;
    return (
      <Control position="topright">
        <button
          className="raised-box btn btn--map-control"
          onClick={this.toggleLayersMenu}
        >
          <i className="fa fa-layers" />
        </button>
        <ReactModal
          className="dv-modal dv-modal--centered raised-box"
          overlayClassName="dv-overlay"
          isOpen={this.state.layersMenuOpen}
          shouldCloseOnOverlayClick
          closeTimeoutMS={100}
          onRequestClose={() => this.toggleLayersMenu()}
          aria={{
            labelledby: "modal__header",
          }}
        >
          <h4 id="modal__header">{t("mapControls.chooseLayer")}</h4>
          <h5>Groups</h5>
          {showGroupsControls && (
            <RadioGroup
              selectedValue={
                activeMapLayer === LAYERS.groups
                  ? currentGroupSetId
                  : null
              }
              onChange={this.handleGroupSetChange}
            >
              {groupSets.map(({id, name}) => (
                <p>
                  <label>
                    <Radio value={id} />
                    {name}
                  </label>
                </p>
              ))}
            </RadioGroup>
          )}
          <FilterControl onChangeCallback={this.handleFilterChange} />
          <h5>Choropleth</h5>
          {showChoroplethControls && (
            <RadioGroup
              selectedValue={
                activeMapLayer === LAYERS.choropleth
                  ? choroplethOptions[selectedOptionIndex].value
                  : null
              }
              onChange={this.handleChoroplethChange}
            >
              {choroplethOptions.map(option => (
                <p key={option.value}>
                  <label>
                    <Radio value={option.value} />
                    {option.label}
                  </label>
                </p>
              ))}
            </RadioGroup>
          )}
        </ReactModal>
      </Control>
    );
  }
}

LayersControl.propTypes = {
  activeMapLayer: PropTypes.string,
  toggleMapLayer: PropTypes.func,
  t: PropTypes.func,
  choroplethOptions: PropTypes.array,
  selectedOptionIndex: PropTypes.number,
  updateSelectedChoropleth: PropTypes.func,
  choroplethIndicators: PropTypes.array,
  showChoroplethControls: PropTypes.bool,
  showGroupsControls: PropTypes.bool,
};

const mapStateToProps = state => {
  const { currentChoroplethIndicator, choroplethIndicators, currentGroupSetId } = state.map;
  let choroplethOptions;
  if (currentChoroplethIndicator) {
    choroplethOptions = choroplethIndicators.map(el => ({
      value: el.id,
      label: getName(el),
    }));
  }
  const groupSets = Object.values(state.project.displayedGroups.reduce((r, el) => {
    if (el.groupSet) {
      // xxx - Remove when groupSet_S_ is live
      r[el.groupSet.id] = {name: el.groupSet.name, id: el.groupSet.id}
    } else {
      el.groupSets.filter(set => set.shown).map(set => {
        r[set.id] = {name: set.name, id: set.id}
      })
    }
    return r;
  }, {}));

  const { choropleth, groups } = state.ui.mapLayers;

  return {
    currentGroupSetId: (currentGroupSetId ? currentGroupSetId : (groupSets[0] ? groupSets[0].id : null)),
    choroplethIndicators,
    choroplethOptions,
    showChoroplethControls: choropleth,
    showGroupsControls: groups,
    groupSets,
    selectedOptionIndex: (choroplethOptions || []).findIndex(
      option => option.value === currentChoroplethIndicator.id,
    ),
  };
};

export default withTranslation()(
  connect(mapStateToProps, { updateSelectedChoropleth, updateSelectedGroupSet })(LayersControl),
);
