import React from "react";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

function OpenDataNotice(props) {
  const { openData, contactEmail } = props.project;
  if (openData) {
    return (
      <div>
        <a
          rel="license"
          href="http://creativecommons.org/licenses/by/4.0/"
          className="icon--left"
        >
          <img
            alt="Creative Commons License"
            src="https://i.creativecommons.org/l/by/4.0/80x15.png"
          />
        </a>
        <div
          dangerouslySetInnerHTML={{
            __html: props.t("footer.opendata", {
              interpolation: { escapeValue: false },
            }),
          }}
        />
      </div>
    );
  }
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: props.t("footer.noOpendata", {
          contactEmail,
          interpolation: { escapeValue: false },
        }),
      }}
    />
  );
}

OpenDataNotice.propTypes = {
  project: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(OpenDataNotice);
