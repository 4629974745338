import _includes from "lodash/includes";
import * as types from "../constants/actions-type";
import { currentPeriodMin, currentPeriodMax } from "./period";

function dataElements(
  state = {
    byId: {},
    bySectionId: {},
    byQueryId: {},
    isFetching: true,
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_DATA_ELEMENTS:
      return {
        ...state,
        isFetching: true,
      };
    case types.RECEIVE_DATA_ELEMENTS:
      return {
        ...state,
        byId: action.byId,
        bySectionId: action.bySectionId,
        byQueryId: action.byQueryId,
        isFetching: false,
      };
    default:
      return state;
  }
}
export default dataElements;

export const filterMetaData = (data, fromPeriod, toPeriod) =>
  data.filter(
    dataItem => dataItem.period >= fromPeriod && dataItem.period <= toPeriod,
  );

export const indicatorsForPeriod = (period, indicators) =>
  indicators.reduce((filteredIndicators, indicator) => {
    const filteredMetaData = filterMetaData(
      indicator.data,
      currentPeriodMin(period),
      currentPeriodMax(period),
    );

    if (filteredMetaData.length > 0) {
      filteredIndicators.push({ ...indicator, data: filteredMetaData });
    }

    return filteredIndicators;
  }, []);

export const getIndicatorType = (indicatorTypes, type) =>
  (indicatorTypes || []).find(
    indicatorType => (indicatorType || {}).id === (type || {}).id,
  );

export const indicatorsForSection = (state, sectionId) =>
  (state.dataElements.bySectionId[sectionId] || []).map(
    indicatorId => state.dataElements.byId[indicatorId],
  );

export const indicatorsForQuery = (
  indicatorsIdsForQueryId,
  indicators,
  queryId,
) =>
  indicators.filter(indicator =>
    _includes(indicatorsIdsForQueryId[queryId], indicator.id),
  );
