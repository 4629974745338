import React, { Component, Fragment } from "react";
import Control from "react-leaflet-control";
import PropTypes from "prop-types";
import SearchContainer from "../../Search/SearchContainer";

class SearchControl extends Component {
  render() {
    const {
      currentZone,
      activeEntity,
      toggleSearch,
      searchActive,
    } = this.props;
    return (
      <Fragment>
        <Control position="topright">
          <button
            className="raised-box btn btn--map-control"
            onClick={toggleSearch}
          >
            <i className="fa fa-search" />
          </button>
        </Control>
        <SearchContainer
          currentZone={currentZone}
          activeEntity={activeEntity}
          toggleSearch={toggleSearch}
          searchActive={searchActive}
        />
      </Fragment>
    );
  }
}

SearchControl.propTypes = {
  activeEntity: PropTypes.object,
  currentZone: PropTypes.object,
  searchActive: PropTypes.bool,
  toggleSearch: PropTypes.func,
};

export default SearchControl;
