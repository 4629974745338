import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { Tooltip } from "react-tippy";
import classNames from "classnames";
import { formattedName } from "../../lib/uiUtils";
import { orgUnitPath } from "../../lib/routerUtils";

const propTypes = {
  orgUnit: PropTypes.object.isRequired,
  home: PropTypes.bool,
  interactive: PropTypes.bool,
};

export default function BreadCrumbItem({
  orgUnit,
  home = false,
  interactive = true,
}) {
  const href = home ? "/data" : orgUnitPath(orgUnit);

  return (
    <li
      className={classNames("map-navigation__breadcrumb__item", {
        active: interactive,
        current: !interactive,
      })}
    >
      {!interactive ? (
        <span>{formattedName(orgUnit.name)}</span>
      ) : (
        <Link to={href}>
          {home ? (
            <i className="fa fa-home" />
          ) : (
            <Tooltip
              title={formattedName(orgUnit.name)}
              position="bottom"
              size="big"
            >
              …
            </Tooltip>
          )}
        </Link>
      )}
    </li>
  );
}

BreadCrumbItem.propTypes = propTypes;
