import { applyMiddleware, compose, createStore } from "redux";

import { createLogger } from "redux-logger";
import thunk from "redux-thunk";
import LogRocket from "logrocket";
import indexReducer from "../reducers/index";

const middlewares = [thunk];

const composeEnhancers =
  process.env.NODE_ENV === "development" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const useLogger = (false && process.env.NODE_ENV === "development");
if (useLogger) {
  const logger = createLogger({ duration: true });
  middlewares.push(logger);
} else {
  middlewares.push(LogRocket.reduxMiddleware());
}

const store = createStore(
  indexReducer,
  composeEnhancers(applyMiddleware(...middlewares)),
);

export default store;
