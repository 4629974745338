import "../../css/switch-button.scss";

import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";

const propTypes = {
  isActive: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SwitchButton = ({ isActive, onChange }) => (
  <div
    className={classNames("switch-button", {
      "active primary-background": isActive,
    })}
    onClick={() => onChange()}
  />
);

SwitchButton.propTypes = propTypes;

export default SwitchButton;
