import _includes from "lodash/includes";
import _sortBy from "lodash/sortBy";
import { createSerieForOrgUnits } from "./sectionUtils";

class SeriesSelectorService {
  constructor(props) {
    this.state = props;
  }

  relatedOrgUnitsIds() {
    return this.state.displayChildrenValues
      ? this.state.childrenOrgUnitIds
      : this.state.siblingOrgUnitIds;
  }

  mainGraphSerie(sortedIndicators) {
    return sortedIndicators.find(
      indicator => indicator.id === this.state.currentGraphId,
    );
  }

  multiIndicatorGroupSeries(sortedIndicators) {
    return sortedIndicators
      .filter(indicator =>
        _includes(
          this.state.indicatorIdsByQuery[this.state.currentGraphId],
          indicator.id,
        ),
      )
      .map(serie => ({ ...serie, orgUnitId: this.state.currentOrgUnitId }));
  }

  multiIndicatorSeries(sortedIndicators) {
    return sortedIndicators.map(serie => ({
      ...serie,
      orgUnitId: this.state.currentOrgUnitId,
    }));
  }

  run() {
    const {
      indicators,
      sectionType,
      currentOrgUnitId,
      currentGraphId,
      displayChildrenValues,
    } = this.state;

    const sortedIndicators = _sortBy(indicators, ["position"]);

    // Multi indicator series (Don’t have currentGraph because all indicators are merged)
    if (!currentGraphId) {
      return this.multiIndicatorSeries(sortedIndicators);
    }

    // Multi indicators group series
    if (sectionType === "multi_indicators_groups") {
      return this.multiIndicatorGroupSeries(sortedIndicators);
    }

    // Standard graph series
    const mainGraphSerie = this.mainGraphSerie(sortedIndicators);

    if (mainGraphSerie) {
      mainGraphSerie.orgUnitId = currentOrgUnitId;

      const relatedSeries =
        sectionType !== "multi_indicators"
          ? createSerieForOrgUnits(
              this.relatedOrgUnitsIds(),
              mainGraphSerie,
              true,
              !displayChildrenValues,
            )
          : [];

      return displayChildrenValues
        ? relatedSeries
        : [mainGraphSerie, ...relatedSeries];
    }

    return [];
  }
}

export default SeriesSelectorService;
