import "../../../css/graph-download-actions.scss";
import PropTypes from "prop-types";
import React, { Component } from "react";
import classNames from "classnames";
import { withTranslation } from "react-i18next";
import Menu from "../Menu";

import IndicatorTable from "../IndicatorTable";
import MenuItem from "../MenuItem";

class GraphDownloadActions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadContainerActive: false,
    };
  }

  toggleDownloadContainer = () => {
    this.setState({
      downloadContainerActive: !this.state.downloadContainerActive,
    });
  };

  handleClickOutside = () => {
    if (this.state.downloadContainerActive) {
      this.setState({
        downloadContainerActive: false,
      });
    }
  };

  render() {
    const {
      title,
      graphSeriesData,
      t,
      exportImage,
      displayCsvButton,
    } = this.props;
    const { downloadContainerActive } = this.state;
    return (
      <div
        className="pull-right download-actions"
        onClick={() => this.toggleDownloadContainer()}
      >
        <button
          type="button"
          className={classNames(
            "btn btn-sm pull-right download-actions__open visible-lg-block",
            {
              "primary-color": downloadContainerActive,
            },
          )}
        >
          <i className="fa fa-download icon--left" />
          Download
        </button>

        <Menu
          in={downloadContainerActive}
          handleClickOutside={this.handleClickOutside}
        >
          <ul className="menu-list">
            {displayCsvButton && (
              <MenuItem>
                <IndicatorTable dataByOrgUnit={graphSeriesData} title={title} />
              </MenuItem>
            )}
            <MenuItem>
              <a onClick={() => exportImage()}>{t("graphDownload.image")}</a>
            </MenuItem>
          </ul>
        </Menu>
      </div>
    );
  }
}

GraphDownloadActions.defaultProps = {
  graphSeriesData: [],
  title: "",
  displayCsvButton: true,
};

GraphDownloadActions.propTypes = {
  title: PropTypes.string,
  graphSeriesData: PropTypes.array,
  t: PropTypes.func.isRequired,
  exportImage: PropTypes.func.isRequired,
  displayCsvButton: PropTypes.bool,
};

export default withTranslation()(GraphDownloadActions);
