import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import frTranslations from "./fr.json";
import enTranslations from "./en.json";

i18n.use(initReactI18next).init({
  // we init with resources
  resources: {
    en: { translation: enTranslations },
    fr: { translation: frTranslations },
  },
  lng: "fr",
  fallbackLng: "fr",
  debug: false,
  interpolation: {
    escapeValue: false, // not needed for react!!
  },
  react: {
    wait: true,
  },
});

export default i18n;
