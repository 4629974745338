import React, { Component } from "react";

import classNames from "classnames";

function ProgressIndicator(props) {
  const { previousValue, value } = props;
  let progress;
  if (!previousValue) return null;

  const isIncreasing = parseInt(previousValue, 10) < parseInt(value, 10);
  const stable = parseInt(previousValue, 10) === parseInt(value, 10);
  if (stable) {
    progress = "←";
  } else {
    progress = isIncreasing ? "↖" : "↘";
  }

  return (
    <span
      className={classNames(
        "indicator-box__content__value__progress",
        { "indicator-box__content__value__progress--stable": stable },
        {
          "indicator-box__content__value__progress--increasing": isIncreasing,
        },
        {
          "indicator-box__content__value__progress--decreasing": !isIncreasing,
        },
      )}
    >
      {progress}
    </span>
  );
}

export default ProgressIndicator;
