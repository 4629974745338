import { GeoJSON } from "react-leaflet";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

const NationalGeoJson = ({ mainZoneId, geoJson, color }) => {
  const styles = {
    color,
    weight: 2,
    opacity: 1,
    fillOpacity: 0,
    stroke: true,
  };
  return (
    <GeoJSON
      clickable={false}
      key={mainZoneId}
      data={geoJson}
      style={styles}
      onAdd={e => {
        e.target.bringToBack();
      }}
    />
  );
};

NationalGeoJson.propTypes = {
  mainZoneId: PropTypes.string,
  geoJson: PropTypes.object,
  color: PropTypes.string,
};

const mapStateToProps = state => ({
  mainZoneId: state.project.mainZone.id,
  geoJson: state.project.mainZone.geojson,
  color: state.project.colors[state.project.colors.map],
});

export default connect(mapStateToProps)(NationalGeoJson);
