/**
 * Map
 */

// We use Cloudinary to fetch remote images, we only use that part of their API
//
// The URL here is enough to define those items.
//
// https://cloudinary.com/documentation/fetch_remote_images
export const CLOUDINARY_URL_THUMB =
  "https://res.cloudinary.com/blsq-dataviz/image/fetch/f_auto,w_200,q_auto,a_auto/";
export const CLOUDINARY_URL =
  "https://res.cloudinary.com/blsq-dataviz/image/fetch/f_auto,w_600,q_auto,a_auto/";
export const CLOUDINARY_URL_BIG =
  "https://res.cloudinary.com/blsq-dataviz/image/fetch/f_auto,w_1000,q_auto,a_auto/";

export const TEXT_VALUE_TYPES = ["TEXT", "LONG_TEXT", "DATE", "OPTION"];
export const NON_COUNTABLE_TYPES = [
  "TEXT",
  "LONG_TEXT",
  "DATE",
  "BOOLEAN",
  "INVERTED_BOOLEAN",
  "OPTION",
];

export const CHART_TYPE_BAR = "bar";
export const CHART_TYPE_LINE = "line";
export const CHART_TYPE_HEATMAP = "heatmap";

export const LAYERS = {
  "choropleth": "choropleth",
  "groups": "groups",
  "filters": "filters"
}
