import { RECEIVE_INACTIVE_GEOZONES } from "../constants/actions-type";

function inactiveGeozones(
  state = {
    isFetching: false,
    byId: [],
  },
  action,
) {
  switch (action.type) {
    case RECEIVE_INACTIVE_GEOZONES:
      return Object.assign({}, state, {
        isFetching: false,
        byId: action.inactiveGeozones,
      });
    default:
      return state;
  }
}

export default inactiveGeozones;
