import _includes from "lodash/includes";
import _values from "lodash/values";
import uniqBy from "lodash/uniqBy";
import { createSelector } from "reselect";
import * as types from "../constants/actions-type";

function entities(
  state = {
    byId: {},
    byGeozoneId: {},
    entitiesParentIds: [],
    current: null,
    isFetching: true,
    level: null,
  },
  action,
) {
  switch (action.type) {
    case types.RECEIVE_ENTITIES:
      return {
        ...state,
        byId: action.entities,
        isFetching: false,
        byGeozoneId: action.byGeozoneIds,
        entitiesParentIds: uniqBy(
          _values(action.entities).map(entity => ({
            id: entity.parentId,
            hydrated: false,
          })),
          "id",
        ),
      };
    case types.UPDATE_CURRENT_ENTITY:
      return {
        ...state,
        current: action.id,
      };
    case types.UPDATE_CURRENT_ZONE:
      return {
        ...state,
        current: null,
      };
    case types.SET_ENTITIES_LEVEL:
      return {
        ...state,
        level: action.level,
      };
    case types.HYDRATE_ENTITIES:
      const entities = { ...state.byId };
      action.entities.forEach(entity => {
        entities[entity.id] = { ...entity, ...entities[entity.id] };
      });
      const newentitiesParentIds = state.entitiesParentIds.map(parent => ({
        id: parent.id,
        hydrated: parent.id === action.parentId,
      }));
      return {
        ...state,
        byId: entities,
        entitiesParentIds: newentitiesParentIds,
      };
    case "RECEIVE_FILTERS":
      return {
        ...state,
        filters: action.filters
      }
    default:
      return state;
  }
}

export default entities;

export const getEntity = (entities, id) => entities.byId[id];
export const getCurrentEntity = entities =>
  getEntity(entities, entities.current) || null;

const entitiesList = state => _values(state.entities.byId);
const parentIdSelector = (_, parentId) => parentId;

export const fromParent = () =>
  createSelector([entitiesList, parentIdSelector], (items, parentId) =>
    items.filter(entity => _includes(entity.path.split("/"), parentId)),
  );
