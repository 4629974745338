import * as types from "../constants/actions-type";

export const receiveEntitties = entities => ({
  type: types.RECEIVE_ENTITIES,
  entities,
});

export const hydrateEntitties = (entities, parentId) => ({
  type: types.HYDRATE_ENTITIES,
  entities,
  parentId,
});

export const updateCurrentEntity = id => ({
  type: types.UPDATE_CURRENT_ENTITY,
  id,
});
