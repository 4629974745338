import React from "react";
import { connect } from "react-redux";
import indicatorUtils from "./indicatorUtils";

const isSectionType = (section, sectionType) =>
  section.sectionType === sectionType;

const mapStateToProps = state => ({
  projectId: state.project.id,
});

export default function withValues(ComposedComponent, props) {
  class WithValues extends React.Component {
    state = {
      isFetching: true,
      dataByOrgUnitId: {},
    };

    componentDidUpdate(nextProps) {
      if (nextProps.currentOrgUnit.id !== this.props.currentOrgUnit.id) {
        this.fetchValues();
      }
    }

    fetchValues() {
      const { section } = this.props;
      if (
        !isSectionType(section, "publications") &&
        !isSectionType(section, "separator") &&
        !isSectionType(section, "entities_ranking")
      ) {
        this.setState({ isFetching: true });
        indicatorUtils
          .fetchDataElementValues({
            sectionId: this.props.section.id,
            projectId: this.props.projectId,
            orgUnitId: this.props.currentOrgUnit.id,
          })
          .then(response => {
            this.setState({
              isFetching: false,
              dataByOrgUnitId: indicatorUtils.sortDataByOrgUnit(response.data),
            });
          });
      } else {
        this.setState({ isFetching: false });
      }
    }

    componentDidMount() {
      this.fetchValues();
    }

    render() {
      return <ComposedComponent {...this.state} {...this.props} {...props} />;
    }
  }

  return connect(mapStateToProps)(WithValues);
}
