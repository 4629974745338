import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import withKpi from "../KeyIndicators/WithKpi";
import Indicators from "../KeyIndicators/Indicators";
import IndicatorsCategory from "../KeyIndicators/IndicatorsCategory";
import LoadingSpinner from "../LoadingSpinner";
import SectionDescription from "./SectionDescription";

function KpiSection(props) {
  const sectionComponent = !props.indicatorsQueries.length ? (
    <div>{props.t("noData.missingData")}</div>
  ) : (
    props.indicatorsQueries.map((query, index) => (
      <IndicatorsCategory
        key={index}
        name={query.name}
        hideTitle={props.indicatorsQueries.length === 1}
      >
        <Indicators
          indicators={query.indicators}
          displayBooleans={props.displayBooleans}
          lastValues={props.lastValues}
        />
      </IndicatorsCategory>
    ))
  );

  return (
    <section
      className={classNames("section", {
        "section--no-title": !props.section.name,
      })}
      data-id={props.section.id}
    >
      <div className="container-fluid">
        {props.section.name && (
          <h3 className="section__header">{props.section.name}</h3>
        )}
        {props.section.description && (
          <SectionDescription text={props.section.description} />
        )}

        {props.isFetching ? (
          <div>
            <LoadingSpinner />
          </div>
        ) : (
          sectionComponent
        )}
      </div>
    </section>
  );
}

KpiSection.propTypes = {
  description: PropTypes.string,
  displayBooleans: PropTypes.bool,
  indicators: PropTypes.arrayOf(PropTypes.object),
  indicatorsQueries: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
  lastValues: PropTypes.bool,
  section: PropTypes.object,
  subtitle: PropTypes.string,
  t: PropTypes.func,
  title: PropTypes.string,
};

export default withTranslation()(withKpi(KpiSection));
