/* global document */

import React, { Component } from "react";

import PropTypes from "prop-types";
import csv from "../lib/csv";

const PrefixSuffixType = PropTypes.oneOfType([
  PropTypes.bool,
  PropTypes.string,
  PropTypes.number,
]);

export default class CsvDownload extends Component {
  static defaultProps = {
    separator: ",",
    columns: false,
    bom: true,
    noHeader: false,
  };

  static propTypes = {
    bom: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
      PropTypes.element,
    ]),
    columns: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.array,
      PropTypes.arrayOf(PropTypes.object),
    ]),
    datas: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    ).isRequired,
    filename: PropTypes.string.isRequired,
    noHeader: PropTypes.bool,
    prefix: PrefixSuffixType,
    separator: PropTypes.string,
    text: PropTypes.string,
    suffix: PrefixSuffixType,
  };

  constructor(props) {
    super();
    this.state = {
      csv: csv(props.columns, props.datas, props.separator, props.noHeader),
    };
  }

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({
      ...this.state,
      csv: csv(props.columns, props.datas, props.separator, props.noHeader),
    });
  }

  handleClick = () => {
    const { suffix, prefix, bom } = this.props;

    let bomCode = "";
    let { filename } = this.props;

    if (filename.indexOf(".csv") === -1) {
      filename += ".csv";
    }

    if (bom) {
      bomCode = "%EF%BB%BF";
    }

    if (suffix) {
      if (typeof suffix === "string" || typeof suffix === "number") {
        filename = filename.replace(".csv", `_${suffix}.csv`);
      } else {
        filename = filename.replace(".csv", `_${new Date().getTime()}.csv`);
      }
    }

    if (prefix) {
      if (typeof prefix === "string" || typeof prefix === "number") {
        filename = `${prefix}_${filename}`;
      } else {
        filename = `${new Date().getTime()}_${filename}`;
      }
    }

    const a = document.createElement("a");
    a.textContent = "download";
    a.download = filename;
    a.href = `data:text/csv;charset=utf-8,${bomCode}${encodeURIComponent(
      this.state.csv,
    )}`;
    a.rel = "noopener noreferer";
    a.target = "_blank";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  render() {
    const { children, text } = this.props;

    if (typeof children === "undefined") {
      return (
        <button id={"csv-downloader"} onClick={this.handleClick}>
          {(() => {
            if (text) {
              return text;
            }
            return "Download";
          })()}
        </button>
      );
    }

    return <a onClick={this.handleClick}>{children}</a>;
  }
}
