import "../../../css/indicator.scss";

import CountTo from "react-count-to";
import PropTypes from "prop-types";
import React from "react";
import { Tooltip } from "react-tippy";
import classNames from "classnames";
import { NON_COUNTABLE_TYPES } from "../../constants/ui";
import ProgressIndicator from "./ProgressIndicator";
import { valueFactory, formatBooleanValue } from "../../lib/indicatorUtils";

const propTypes = {
  title: PropTypes.string.isRequired,
  unit: PropTypes.string,
  icon: PropTypes.string,
  previousValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  periodName: PropTypes.string,
  showProgress: PropTypes.bool,
};

export default function Indicator({
  title,
  value,
  previousValue,
  icon,
  unit,
  periodName,
  showProgress,
  compact,
  valueType,
  optionSetOptions,
}) {
  const isBool = valueType === "BOOLEAN";
  const isInvertedBool = valueType === "INVERTED_BOOLEAN";
  const showBoolIcon = isBool || isInvertedBool;
  const titleBlock = (
    <div key={`title-block-${title}`} className="indicator-box__content__label">
      {title}
    </div>
  );

  const indicatorValue = (
    <div key={`value-block-${title}`} className="indicator-box__content__value">
      {showProgress && (
        <ProgressIndicator value={value} previousValue={previousValue} />
      )}
      {parseInt(value, 10) && !NON_COUNTABLE_TYPES.includes(valueType) ? (
        <CountTo to={value} speed={1000} digits={2}>
          {val => valueFactory(valueType, val)}
        </CountTo>
      ) : (
        valueFactory(valueType, value, optionSetOptions)
      )}
      {unit && <small> {unit} </small>}
    </div>
  );

  const indicator = (
    <div
      className={classNames("indicator-box", {
        "indicator-box--compact": !!compact,
      })}
    >
      {icon && !showBoolIcon && (
        <div>
          <i className={`fa fa-${icon} fa-2x icon--left primary-color`} />
        </div>
      )}

      {showBoolIcon && <div>{formatBooleanValue(value, isInvertedBool)}</div>}

      <div className="indicator-box__content">
        {compact ? [titleBlock, indicatorValue] : [indicatorValue, titleBlock]}
      </div>
    </div>
  );

  if (periodName) {
    return (
      <Tooltip title={periodName} position="top" size="big" distance={0} arrow>
        {indicator}
      </Tooltip>
    );
  }

  return indicator;
}

Indicator.defaultProps = {
  showProgress: true,
};

Indicator.propTypes = propTypes;
