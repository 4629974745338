import React from 'react';
import { getName } from "../../lib/uiUtils";
import { useTranslation } from "react-i18next";
import CountTo from "react-count-to";
import { connect, useSelector } from "react-redux";
import { useEffect, useState, useRef } from 'react';
import { Tooltip } from "react-leaflet";
import { withTranslation } from "react-i18next";
import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import indicatorUtils, { findLastAvailableValues, valueFactory } from "../../lib/indicatorUtils";
import { formattedName } from "../../lib/uiUtils";
import { decorateKpiQueries, sectionPresenter } from "../../lib/sectionUtils";
import LoadingSpinner from "../LoadingSpinner";
import {
  sectionById
} from "../../reducers/sections";
import {
  indicatorsForSection,
  indicatorsForPeriod
} from "../../reducers/dataElements";
import { NON_COUNTABLE_TYPES, TEXT_VALUE_TYPES } from "../../constants/ui";

const valueTypeAdapter = indicator => {
  const displayBooleans = false;
  if (
    !displayBooleans &&
      (indicator.valueType === "BOOLEAN" ||
       indicator.valueType === "INVERTED_BOOLEAN")
  ) {
    return "NUMBER";
  }

  if (indicator.optionSetValue) {
    return "OPTION";
  }

  return indicator.valueType;
};

function SmallIndicator({
  indicator,
  lastValues
}) {
  const { t } = useTranslation();
  const data = indicatorUtils.generateData(null, indicator.data);
  let periodData = data[data.length - 1];
  const previousPeriod = data[data.length - 2];
  const valueType = valueTypeAdapter(indicator);
  const previousValue = previousPeriod ? previousPeriod.y : null;
  const value = periodData.missingData ? "-" : periodData.y;
  const unit = null;
  const isBool = valueType === "BOOLEAN";
  const isInvertedBool = valueType === "INVERTED_BOOLEAN";
  const showBoolIcon = isBool || isInvertedBool;
  const optionSetOptions = (indicator.optionSet || []).options;
  const title = getName(indicator);

  if (lastValues) {
    periodData = findLastAvailableValues(data) || periodData;
  }
  const indicatorValue = (
    <div key={`value-block-${indicator.id}`} className="">
      { periodData.missingData ? (
        "-"
      ) : (
        [valueFactory(valueType, value, optionSetOptions),
         (unit && <small> {unit} </small>)]
      )}
    </div>
  );

  return (
    <tr>
      <td className="indicator__table__title">
        {title}
      </td>
      <td className="indicator__table__value">
        {indicatorValue}
      </td>
    </tr>
  );

}

function MarkerTooltip(props) {
  const { orgUnit, t, children, offset, sticky } = props;
  const sectionId = props.section;
  const sections = useSelector(state => state.sections);
  const [data, setData] = useState([]);
  const [opened, setOpened] = useState(false);
  const [indicatorData, setIndicatorData] = useState([]);
  const projectId = useSelector(state => state.project.id)
  const dataElements = useSelector(state => state.dataElements)
  const period = useSelector(state => state.period);
  const prevPeriodRef = useRef();
  const [popupSection, setPopupSection] = useState("")
  const onOpen = () => {
    setOpened(true);
  };

  const onClose = () => {
    setOpened(false);
  };

  const indicatorValues = (fetchedData, sections, elements, sectionId, orgUnitId) => {
    const defaultResponse = {section: "", indicators: [], loaded: false};
    if (!sectionId) { return defaultResponse };
    if (!sections) {return defaultResponse };
    const section = sectionById(sections, sectionId);
    if (isEmpty(fetchedData)) {return {section: section, indicators: [], loaded: false};}
    if (isEmpty(section)) {return defaultResponse };

    const presentedSection = sectionPresenter({dataElements: elements}, section);
    const lastValues = !!presentedSection.lastValues;
    const indicatorValues = indicatorUtils.sortDataByOrgUnit(fetchedData)[orgUnitId] || [];
    const hydratedIndicators = indicatorValues.map(indicatorValue => ({
      ...indicatorValue,
      ...dataElements.byId[indicatorValue.id],
    }));
    const indicators = indicatorsForPeriod(period, hydratedIndicators);
    const decoratedData = decorateKpiQueries(
      dataElements.byQueryId,
      presentedSection.queries,
      indicators
    );
    return {
      section: section,
      indicators: decoratedData[0].indicators,
      loaded: true,
      useLastValues: lastValues
    }
  }

  useEffect(() => {
    if (opened && sectionId && isEmpty(data)) {
      indicatorUtils
        .fetchDataElementValues({
          sectionId: sectionId,
          projectId: projectId,
          orgUnitId: orgUnit.id,
        })
        .then(response => {
          setData(response.data);
        });
    }
  }, [opened]);

  const values = indicatorValues(data, sections, dataElements, sectionId, props.orgUnit.id);
  const shouldShowSmallIndicators = !!(sections && sectionId && sectionById(sections, sectionId));
  const onClick = () => {
  };

  return (
    <Tooltip
      direction="top"
      offset={offset}
      className="tooltip-marker"
      sticky={sticky}
      opacity={1}
      onOpen={onOpen}
      onClose={onClose}
      onClick={onClick}
    >
      <div>
        <h5 data-org-id="{orgUnit.id}">{formattedName(orgUnit.name)}</h5>
        { shouldShowSmallIndicators && (
          values.loaded ? (
            <>
              <div>
                <h6>{values.section && values.section.name}</h6>
                <table className="indicator__table">
                  <tbody>
                    { isEmpty(values.indicators) ? (
                      <tr>
                        <td>{t("noData.missingData")}</td>
                      </tr>
                    ) : (
                      values.indicators.map(indicator => {
                        return <SmallIndicator indicator={indicator} lastValues={values.useLastValues} />;
                      })
                    )}
                  </tbody>
                </table>
              </div>
            </>
          ) : (
            <div>
              <h6>{values.section && values.section.name}</h6>
              <LoadingSpinner />
            </div>
          )
        )}

        {!isEmpty(orgUnit.displayedGroups) && orgUnit.type !== "zone" && (
          <>
            <div>
              <h6>{t("entityInfo.displayedGroups")}</h6>
              {valueFactory("ARRAY", orgUnit.displayedGroups)}
            </div>
          </>
        )}
        {children}
      </div>
    </Tooltip>
  );
}

MarkerTooltip.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  offset: PropTypes.array,
  orgUnit: PropTypes.object,
  sticky: PropTypes.bool,
  t: PropTypes.func,
};

MarkerTooltip.defaultProps = {
  offset: [0, -5],
};

const mapStateToProps = state => ({
  projectId: state.project.id,
  dataElements: state.dataElements
});

export default withTranslation()(MarkerTooltip);
