import "../../../css/top-entity-item.scss";

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { CLOUDINARY_URL_THUMB } from "../../constants/ui";
import { orgUnitPath } from "../../lib/routerUtils";
import { valueFactory } from "../../lib/indicatorUtils";

const entityPhotoPlaceholder = require("../../../img/topography-dark.png");

const getProgressSize = (value, maxValue, minValue) => {
  if (value < minValue) {
    return `${minValue}%`;
  }
  if (value > maxValue) {
    return `${maxValue}%`;
  }
  return `${value}%`;
};

const getImgUrl = imgUrl => {
  if (imgUrl === null) {
    return entityPhotoPlaceholder;
  }
  return `${CLOUDINARY_URL_THUMB}${encodeURIComponent(imgUrl)}`;
};

function TopEntityItem(props) {
  const { topEntity, color } = props;
  return (
    <div className="top-entity-item">
      <Link
        className="top-entity-item__link"
        to={{
          pathname: orgUnitPath(topEntity),
          state: { scrollToTop: true },
        }}
      >
        <figure
          className="top-entity-item__picture"
          style={{
            backgroundImage: `url(${getImgUrl(topEntity.imgUrl)})`,
          }}
        />
        <div className="top-entity-item__infos">
          <span className="top-entity-item__name">{topEntity.name}</span>
          <div className="top-entity-item__progress-bar-container">
            <div
              className={classNames("top-entity-item__progress-value", {
                "align-left": topEntity.value < 10,
              })}
              style={{
                left: getProgressSize(topEntity.value, 96, 4),
                color,
              }}
            >
              <span>{valueFactory("PERCENTAGE", topEntity.value)}</span>
            </div>
            <span
              className="top-entity-item__progress-bar"
              style={{
                width: getProgressSize(topEntity.value, 100, 0),
                backgroundColor: color,
              }}
            />
          </div>

          <button className="btn btn-sm top-entity-item__button">
            <i className="fa fa-keyboard-arrow-right" />
          </button>
        </div>
      </Link>
    </div>
  );
}

TopEntityItem.propTypes = {
  topEntity: PropTypes.object,
  color: PropTypes.string,
};

export default TopEntityItem;
