import axios from "axios";
import * as types from "../constants/actions-type";

const requestPublications = () => ({
  type: types.REQUEST_PUBLICATIONS,
});

const recievePublications = publications => ({
  type: types.RECEIVE_PUBLICATIONS,
  publications,
});

export const fetchPublications = orgUnitId => (dispatch, getState) => {
  const state = getState();
  const projectId = state.project.id;
  const nationalOrgUnitId = state.project.mainZone.id;
  let publicationsQuery = "";

  dispatch(requestPublications());

  if (!orgUnitId || orgUnitId === nationalOrgUnitId) {
    publicationsQuery = "?national=true";
  } else if (orgUnitId) {
    publicationsQuery = `?org_unit_id=${orgUnitId}`;
  }

  return axios
    .get(
      `${process.env.FRONTOFFICE_MANAGER_URL}/projects/${projectId}/publications${publicationsQuery}`,
    )
    .then(json => dispatch(recievePublications(json.data)));
};
