import PropTypes from "prop-types";
import React from "react";

function IndicatorsCategory(props) {
  return (
    <div className="indicators__category">
      {props.name && !props.hideTitle && (
        <h4 className="indicators__header indicators__category-header">
          {props.name}
        </h4>
      )}
      {props.children}
    </div>
  );
}

IndicatorsCategory.propTypes = {
  children: PropTypes.object,
  name: PropTypes.string,
  className: PropTypes.string,
  hideTitle: PropTypes.bool,
};

export default IndicatorsCategory;
