import "../../../css/indicators.scss";
import "../../../css/pane.scss";

import PropTypes from "prop-types";
import React from "react";
import { applyContainerQuery } from "react-container-query";
import classNames from "classnames";
import _sortBy from "lodash/sortBy";
import { withTranslation } from "react-i18next";
import { TEXT_VALUE_TYPES } from "../../constants/ui";
import Indicator from "./Indicator";
import { formattedPeriod } from "../../lib/periodsUtils";
import { getName } from "../../lib/uiUtils";
import indicatorUtils, {
  findLastAvailableValues,
} from "../../lib/indicatorUtils";
import mediaQueries from "../../lib/browser";

const Indicators = ({
  indicators,
  containerQuery: mq,
  displayBooleans,
  lastValues,
  t,
}) => {
  const indClasses = classNames("indicators__categories__item", {
    "col-xs-12": !mq.sm && !mq.md,
    "col-xs-6": mq.sm && !mq.md,
    "col-xs-4": mq.md,
  });

  const valueTypeAdapter = indicator => {
    if (
      !displayBooleans &&
      (indicator.valueType === "BOOLEAN" ||
        indicator.valueType === "INVERTED_BOOLEAN")
    ) {
      return "NUMBER";
    }

    if (indicator.optionSetValue) {
      return "OPTION";
    }

    return indicator.valueType;
  };

  return (
    <div className="indicators__categories row wrapping-blocks">
      {_sortBy(indicators, ["position"]).map(indicator => {
        const title = getName(indicator);
        const data = indicatorUtils.generateData(null, indicator.data);
        let periodData = data[data.length - 1];
        const previousPeriod = data[data.length - 2];
        const valueType = valueTypeAdapter(indicator);
        const showProgress = !TEXT_VALUE_TYPES.includes(valueType);
        const previousValue = previousPeriod ? previousPeriod.y : null;

        if (lastValues) {
          periodData = findLastAvailableValues(data) || periodData;
        }

        return (
          <div
            className={indClasses}
            key={indicator.id}
            data-indicator-id={indicator.id}
          >
            <Indicator
              title={title}
              value={
                periodData.missingData ? t("noData.missingData") : periodData.y
              }
              previousValue={previousValue}
              icon={indicator.icon}
              valueType={
                periodData.missingData ? TEXT_VALUE_TYPES[0] : valueType
              }
              showProgress={showProgress}
              periodName={formattedPeriod(periodData.x)}
              optionSetOptions={(indicator.optionSet || []).options}
            />
          </div>
        );
      })}
    </div>
  );
};

Indicators.propTypes = {
  containerQuery: PropTypes.object,
  displayBooleans: PropTypes.bool,
  indicators: PropTypes.arrayOf(PropTypes.object),
  lastValues: PropTypes.bool,
  mq: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(applyContainerQuery(Indicators, mediaQueries));
