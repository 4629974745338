import "../../../css/publication.scss";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import FormattedText from "../FormattedText";
import { scrollToTop } from "../../lib/uiUtils";

class Publication extends React.Component {
  componentDidMount() {
    const { publication } = this.props;
    if (publication) {
      scrollToTop();
    }
  }

  render() {
    if (!this.props.publication) {
      return null;
    }
    const { t } = this.props;

    const {
      content,
      title,
      backgroundImageUrl,
      attachmentUrl,
    } = this.props.publication;
    const isHtml = /<[a-z][\s\S]*>/i.test(content);
    let body;

    body = (
      <FormattedText>
        {content === "" ? t("publications.noContent") : content}
      </FormattedText>
    );

    if (isHtml) {
      body = <div dangerouslySetInnerHTML={{ __html: content }} />;
    }

    return (
      <>
        {backgroundImageUrl && (
          <div
            className="publication-hero"
            style={{ backgroundImage: `url(${backgroundImageUrl})` }}
          ></div>
        )}
        <section className="publication-container detail">
          <div className="col-12">
            <div className="masthead">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-lg-8 col-lg-offset-2">
                    <section className="publication-content">
                      <Helmet>
                        <title>
                          {title} - {this.props.shortDescription} - Dataviz
                        </title>
                      </Helmet>
                      <h1 className="masthead__title">{title}</h1>
                      <div className="page-content">
                        <div className="page-body">{body}</div>
                        {attachmentUrl && (
                          <a
                            href={attachmentUrl}
                            className={
                              "btn btn-default publication-summary__download"
                            }
                          >
                            {t("buttons.download")}
                          </a>
                        )}
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state, { match: { params } }) => {
  const { isFetching, items } = state.publications;
  return {
    isFetching,
    shortDescription: state.project.shortDescription,
    publication: items.find(publication => publication.id === params.id),
  };
};

Publication.propTypes = {
  publication: PropTypes.object,
  shortDescription: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(connect(mapStateToProps)(Publication));
