import React, { Fragment, PureComponent } from "react";

import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";

import { isExternal } from "../../lib/routerUtils";

class MenuItem extends PureComponent {
  handleDropDown = e => {
    e.stopPropagation();
    e.preventDefault();
  };

  render() {
    const { t, i18nKey, title, url, children } = this.props;

    if (children) {
      return (
        <li className={"dropdown"}>
          <a
            href=""
            ref="#"
            className="dropdown-toggle"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            onClick={this.handleDropDown}
          >
            {title || (
              <Fragment>
                {t("header.links")}
                <i className="fa fa-keyboard-arrow-down icon--right" />
              </Fragment>
            )}
          </a>
          <ul className="dropdown-menu">{children}</ul>
        </li>
      );
    }

    if (isExternal(url)) {
      return (
        <li>
          <a href={url} rel="noopener noreferrer" target="_blank">
            <i className="fa fa-external-link-square icon--left" />
            {title}
          </a>
        </li>
      );
    }

    return (
      <li>
        <Link to={url}>{title || t(`header.${[i18nKey]}`)}</Link>
      </li>
    );
  }
}

MenuItem.propTypes = {
  children: PropTypes.any,
  i18nKey: PropTypes.string,
  location: PropTypes.object,
  t: PropTypes.func,
  title: PropTypes.string,
  url: PropTypes.string,
};

export default withRouter(withTranslation()(MenuItem));
