import "../../../css/section.scss";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import React, { Component } from "react";
import _isEqual from "lodash/isEqual";
import LoadingSpinner from "../LoadingSpinner";
import SectionFactory from "./SectionFactory";
import setSectionsLoaded from "../../actions/sections";

const loadSectionState = {};

const isAllSectionsLoaded = () =>
  Object.values(loadSectionState).indexOf(true) === -1 &&
  !!Object.entries(loadSectionState).length;

class SectionsList extends Component {
  constructor() {
    super();
    this.state = {
      isBannerVisible: false,
      isModalVisible: false,
      sectionsLoaded: false,
    };
  }

  shouldComponentUpdate(nextProps) {
    return (
      !_isEqual(nextProps.sections, this.props.sections) ||
      nextProps.isFetching !== this.props.isFetching ||
      nextProps.sectionsLoaded !== this.props.sectionsLoaded
    );
  }

  updateLoadSectionState(key, value) {
    loadSectionState[key] = value;
    if (
      isAllSectionsLoaded(loadSectionState) &&
      !this.state.sectionsLoaded &&
      Object.entries(loadSectionState).length === this.props.sections.length
    ) {
      this.setState({
        sectionsLoaded: true,
      });
      this.props.setSectionsLoaded();
    }
  }

  render() {
    const { sections, isFetching, ...rest } = this.props;
    if (!sections.length && isFetching) {
      return (
        <div>
          <LoadingSpinner />
        </div>
      );
    }
    if (!sections.length && !isFetching) {
      return null;
    }
    return (
      <section className="sections-list">
        {sections.map(section => (
          <SectionFactory
            {...rest}
            section={section}
            key={section.id}
            updateLoadSectionState={(key, value) =>
              this.updateLoadSectionState(key, value)
            }
          />
        ))}
      </section>
    );
  }
}

SectionsList.propTypes = {
  sections: PropTypes.array,
  isFetching: PropTypes.bool,
  setSectionsLoaded: PropTypes.func,
  sectionsLoaded: PropTypes.bool,
};

const mapStateToProps = state => ({
  isFetching: state.dataElements.isFetching,
  sectionsLoaded: state.sections.sectionsLoaded,
});

export default connect(mapStateToProps, { setSectionsLoaded })(SectionsList);
