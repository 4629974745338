import * as types from "../constants/actions-type";

export const receiveGeozones = zones => ({
  type: types.RECEIVE_GEOZONES,
  zones,
});

export const updateCurrentZone = id => ({
  type: types.UPDATE_CURRENT_ZONE,
  id,
});
