import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { decorateKpiQueries, sectionPresenter } from "../../lib/sectionUtils";
import { getCurrentOrgUnitId } from "../../reducers/zones";
import { indicatorsForPeriod } from "../../reducers/dataElements";

const mapStateToProps = (state, props) => {
  const { section, dataByOrgUnitId } = props;
  let kpiProps;
  const defaultProps = {
    indicatorsQueries: [],
  };

  const presentedSection = sectionPresenter(state, section);

  const indicatorValues = dataByOrgUnitId[getCurrentOrgUnitId(state)] || [];

  const hydratedIndicators = indicatorValues.map(indicatorValue => ({
    ...indicatorValue,
    ...state.dataElements.byId[indicatorValue.id],
  }));

  if (presentedSection && indicatorValues.length) {
    const lastValues =
      props.sectionName === "entity_info" || !!presentedSection.lastValues;
    kpiProps = {
      lastValues,
      displayBooleans: !!state.entities.current,
      title: presentedSection.name,
      indicatorsQueries: decorateKpiQueries(
        state.dataElements.byQueryId,
        presentedSection.queries,
        indicatorsForPeriod(state.period, hydratedIndicators),
      ),
    };
  }

  return {
    ...defaultProps,
    ...kpiProps,
  };
};

export default function withKpi(ComposedComponent, props) {
  class WithKpi extends React.PureComponent {
    render() {
      return <ComposedComponent {...this.props} {...props} />;
    }
  }

  WithKpi.propTypes = {
    indicatorsQueries: PropTypes.arrayOf(PropTypes.object),
  };

  return connect(mapStateToProps)(WithKpi);
}
