import _flatten from "lodash/flatten";
import _values from "lodash/values";
import { format } from "d3-format";
import { LineMarkSeries, VerticalBarSeries, HeatmapSeries } from "react-vis";
import { getName } from "./uiUtils";
import { getOrgUnit } from "../reducers/orgUnits";
import store from "../store/store";
import {
  CHART_TYPE_LINE,
  CHART_TYPE_BAR,
  CHART_TYPE_HEATMAP,
} from "../constants/ui";

export const serieName = (serie, sectionType) => {
  if (
    sectionType === "multi_indicators" ||
    sectionType === "multi_indicators_groups" ||
    !serie.orgUnitId
  ) {
    return getName(serie);
  }
  return getName(getOrgUnit(store.getState(), serie.orgUnitId));
};

export const formatLabel = (tick, scaleType) => {
  switch (scaleType) {
    case "period":
      return tick;
    case "percentage":
      return format(".0%")(tick / 100);
    case "money":
      return format("$1.2d")(tick);
    default:
      return format(",.2d")(tick);
  }
};

export const getAverages = dataSet => {
  const averages = {};
  _flatten(dataSet).map(period => {
    if (averages[period.period]) {
      averages[period.period].value += period.value;
      averages[period.period].count += 1;
    } else {
      averages[period.period] = { ...period, count: 1 };
    }
  });

  return _values(averages).map(d => ({
    ...d,
    value: d.value / d.count,
  }));
};

export const nerfValue = (value, max) => Math.min(max, Math.max(0, value));

export const getChartType = defaultGraphType => {
  switch (defaultGraphType) {
    case CHART_TYPE_BAR:
      return {
        id: 2,
        label: CHART_TYPE_BAR,
        iconClass: "bar-chart",
        type: VerticalBarSeries,
        hasLegend: true,
      };
    case CHART_TYPE_HEATMAP:
      return {
        id: 3,
        label: CHART_TYPE_HEATMAP,
        iconClass: "heatmap-chart",
        type: HeatmapSeries,
        hasLegend: false,
      };
    default:
      return {
        id: 1,
        label: CHART_TYPE_LINE,
        iconClass: "line-chart",
        type: LineMarkSeries,
        hasLegend: true,
      };
  }
};

export const getLabelWidthEstimation = (
  maxLength,
  charLength = 5,
  minimumWidth = 40,
) => maxLength * charLength + minimumWidth;
