import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import GeoZone from "./GeoZone";

class GeoZones extends Component {
  render() {
    const {
      geozones,
      variant,
      currentZone,
      colors,
      currentChoroplethIndicator,
    } = this.props;
    const active = variant !== "inactive";

    return geozones.map(geozone => (
      <GeoZone
        variant={variant}
        clickable={active}
        inactive={!active || geozone.id === currentZone.id}
        geozone={geozone}
        key={`geojson-${geozone.id}`}
        onAdd={this.props.onAdd}
        activeMapLayer={this.props.activeMapLayer}
        currentChoroplethIndicator={currentChoroplethIndicator}
        colors={colors}
      />
    ));
  }
}

GeoZones.propTypes = {
  activeMapLayer: PropTypes.string,
  colors: PropTypes.object,
  currentZone: PropTypes.object,
  currentChoroplethIndicator: PropTypes.object,
  geozones: PropTypes.arrayOf(PropTypes.object),
  onAdd: PropTypes.func,
  variant: PropTypes.string,
};

export default GeoZones;
