const mediaQueries = {
  sm: {
    minWidth: 384,
  },
  md: {
    minWidth: 496,
  },
  lg: {
    minWidth: 600,
  },
};

export default mediaQueries;
