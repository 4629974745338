import React, { Component, Fragment } from "react";
import "../../../css/homepage.scss";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import _truncate from "lodash/truncate";
import { fetchPublications } from "../../actions/publications";
import Body from "../Body";
import { gradientHero } from "../../lib/colors";
import DashboardItem from "./DashboardItem";
import PublicationSummary from "../Publications/PublicationSummary";
import { formatDate } from "../../lib/uiUtils";
import ExportButton from "../../containers/ExportButton";
import OpenDataNotice from "../OpenDataNotice";

const datavizLogo = require("../../../img/dataviz-logo.svg");
const dhis2Logo = require("../../../img/dhis2-logo.svg");

class Homepage extends Component {
  componentDidMount() {
    this.props.fetchPublications();
  }

  render() {
    const {
      project: {
        colors,
        logoUrl,
        backgroundImageUrl,
        ministry,
        shortDescription,
        description,
        donors,
        noData,
      },
      t,
      publications,
    } = this.props;
    const gradient = backgroundImageUrl
      ? gradientHero(colors.primary, backgroundImageUrl)
      : undefined;

    return (
      <Body className="full-width readable">
        <Fragment>
          <Helmet>
            <title>{shortDescription} - Dataviz</title>
            <meta
              name="description"
              content={_truncate(description, { length: 200 })}
            />
          </Helmet>
          <div
            className="hero with-stretched-background primary-background"
            style={{
              backgroundImage: gradient,
            }}
          >
            <div className="hero__header centered-text container">
              <div className="col col-sm-10 centered">
                <img
                  src={logoUrl}
                  aria-hidden="true"
                  className="hero__header__logo"
                />
                {ministry && (
                  <p className="hero__header_ministry">{ministry}</p>
                )}
              </div>
              {shortDescription && (
                <div className="col col-sm-10 centered fadinUp hero__header_title">
                  <h1>{shortDescription}</h1>
                </div>
              )}
              {description && (
                <div
                  className="col col-sm-10 centered hero__header_description"
                  dangerouslySetInnerHTML={{
                    __html: description,
                  }}
                />
              )}
            </div>
          </div>
          <div className="container">
            {!this.props.publicationsFetching && (
              <div className="section">
                <div className="dashboard dashboard--offset">
                  {!noData && (
                    <div className="home-flex-col">
                      <DashboardItem
                        title={t("header.browseData")}
                        url="/data"
                        iconName="insert-chart"
                        primary
                        key={0}
                        index={0}
                      />
                    </div>
                  )}
                  {!!publications.length && (
                    <div className="home-flex-col">
                      <DashboardItem
                        title={t("header.publications")}
                        url="/pages/publications"
                        iconName="language"
                        key={1}
                        index={1}
                      />
                    </div>
                  )}
                  {this.props.project.menuItems.map((menuItem, index) => (
                    <div
                      className="home-flex-col"
                      key={`dashboard-item-${index}`}
                    >
                      <DashboardItem
                        {...menuItem}
                        key={index + (publications.length ? 2 : 1)}
                        index={index + (publications.length ? 2 : 1)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="row">
              {!!publications.length && (
                <div className="col col-sm-12 col-lg-6">
                  <div className="section">
                    <h3 className="section__header">
                      {t("header.lastPublications")}
                    </h3>
                    {publications.slice(0, 3).map((publication, index) => (
                      <PublicationSummary
                        key={`publication-${index}`}
                        url={`/pages/publications/${publication.id}`}
                        {...publication}
                        date={formatDate(
                          publication.createdAt,
                          this.props.i18n.language,
                        )}
                      />
                    ))}
                  </div>
                </div>
              )}
              {!!donors.length && (
                <div className="col col-sm-12 col-lg-3">
                  <div className="section">
                    <h3 className="section__header">{t("header.donors")}</h3>
                    <div className="">
                      {this.props.project.donors.map((donor, index) => (
                        <Fragment key={`dashboard-donor-${index}`}>
                          {donor.url ? (
                            <a href={donor.url}>
                              <img
                                src={donor.logoUrl}
                                alt={donor.name}
                                height="50"
                                className="icon--left dashboard-donor"
                              />
                            </a>
                          ) : (
                            <img
                              src={donor.logoUrl}
                              alt={donor.name}
                              height="50"
                              className="icon--left dashboard-donor"
                            />
                          )}
                        </Fragment>
                      ))}
                    </div>
                  </div>
                </div>
              )}

              <div
                className={`col col-sm-12 col-lg-3 ${
                  !publications.length && !donors.length
                    ? "centered centered-text"
                    : ""
                } `}
              >
                <div className="section">
                  <h3 className="section__header">{t("footer.powered_by")}</h3>
                  <p>
                    <a href="https://bluesquarehub.com/dataviz/">
                      <img
                        src={datavizLogo}
                        alt="Dataviz logo"
                        height="40"
                        className="icon--left dashboard-donor"
                      />
                    </a>
                    <a href="https://www.dhis2.org/">
                      <img
                        src={dhis2Logo}
                        alt="Dhis2 logo"
                        height="40"
                        className="icon--left dashboard-donor"
                      />
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ExportButton />
          <div className="container">
            <div className="section centered-text">
              <OpenDataNotice project={this.props.project} />
            </div>
          </div>
        </Fragment>
      </Body>
    );
  }
}

Homepage.propTypes = {
  project: PropTypes.object,
};

const propsState = state => ({
  project: state.project,
  publicationsFetching: state.publications.isFetching,
  publications: state.publications.items || [],
});

export default withTranslation()(
  connect(propsState, { fetchPublications })(Homepage),
);
