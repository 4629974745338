import findIndex from "lodash/findIndex";
import * as types from "../constants/actions-type";

import { generateColors } from "../lib/colors";
import { getName } from "../lib/uiUtils";

function project(
  state = {
    isFetching: true,
    performanceLibActive: false,
    displayedGroups: []
  },
  action,
) {
  switch (action.type) {
    case types.TOGGLE_PERFORMANCE_LIBS:
      return {
        ...state,
        performanceLibActive: !state.performanceLibActive,
      };
    case types.LOAD_BOOTSTRAP_DATA:
      return Object.assign({}, state, {
        isFetching: false,
        ...action.data,
        cssPalette: generateColors(
          action.data.colors.primary,
          action.data.colors.secondary,
        ),
      });
    default:
      return state;
  }
}

export default project;

export const subLevelName = (levels, currentLevel) => {
  const currentLevelIndex = findIndex(
    levels,
    level => level.level === currentLevel,
  );
  return getName(levels[currentLevelIndex + 1]);
};

export const entitiesLevelName = levels => getName(levels[levels.length - 1]);
