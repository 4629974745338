import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { isExternal } from "../../lib/routerUtils";

function DashboardItem(props) {
  const { url, iconName, title, index, primary } = props;
  if (isExternal(url)) {
    return (
      <a
        className="dashboard__item fadinUp"
        href={url}
        rel="noopener noreferrer"
        target="_blank"
        style={{ animationDelay: `${index / 10}s` }}
      >
        <i
          className={`fa dashboard__item__icon ${
            primary ? "primary-color" : "secondary-color"
          } fa-${iconName}`}
        />
        <h4>{title}</h4>
      </a>
    );
  }
  return (
    <Link
      className="dashboard__item fadinUp"
      to={url}
      style={{ animationDelay: `${index / 10}s` }}
    >
      <i
        className={`fa dashboard__item__icon ${
          primary ? "primary-color" : "secondary-color"
        } fa-${iconName}`}
      />
      <h4>{title}</h4>
    </Link>
  );
}

DashboardItem.propTypes = {
  iconName: PropTypes.string,
  index: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string,
  primary: PropTypes.bool,
};

export default DashboardItem;
