import React, { Component, Fragment } from "react";
import { ZoomControl } from "react-leaflet";
import Control from "react-leaflet-control";
import PropTypes from "prop-types";
import LayersControl from "./LayersControl";
import SearchControl from "./SearchControl";
import PaneSwitch from "../../PaneSwitch";
import { useSelector, useDispatch } from 'react-redux';
import { toggleSearch, setFilter, setMapLayers } from "../../../actions/ui";
import { LAYERS } from "../../../constants/ui";

function MapControls(props) {
  const dispatch = useDispatch();
  const {
    activeMapLayer,
    toggleMapLayer,
    displayLayersControl,
    currentZone,
    activeEntity,
    searchActive,
    activeFilter,
    switchPane
  } = props;

  const searchToggler = () => dispatch(toggleSearch());
  // Only show the filters control when it's active for that project
  const filtersEnabled = useSelector(state => {
    return (state.project.featureToggles || {mapFilters: false}).mapFilters;
  });
  const isFiltering = !!activeFilter && activeMapLayer === LAYERS.filters;

  const resetFilters = () => {
    dispatch(setFilter(null))
    dispatch(setMapLayers(LAYERS.groups));
  }

  return (
    <Fragment>
      <ZoomControl position="bottomleft" />
      {displayLayersControl && (
        <LayersControl
          toggleMapLayer={toggleMapLayer}
          activeMapLayer={activeMapLayer}
        />
      )}
      <SearchControl
        currentZone={currentZone}
        activeEntity={activeEntity}
        toggleSearch={searchToggler}
        searchActive={searchActive}
      />
      <Control position="bottomright">
        <PaneSwitch switchPane={switchPane} />
      </Control>
      { !!activeFilter ? (
        <Control position="topleft">
          <button onClick={resetFilters} className={`btn btn--rounded btn-lg`}>
            Filters active, click to reset
          </button>
        </Control>
      ) : []}
    </Fragment>
  )
}

MapControls.defaultProps = {
  mobileMapHidden: true,
};

MapControls.propTypes = {
  activeEntity: PropTypes.object,
  activeMapLayer: PropTypes.string,
  currentZone: PropTypes.object,
  displayLayersControl: PropTypes.bool,
  mobileMapHidden: PropTypes.bool,
  searchActive: PropTypes.bool,
  switchPane: PropTypes.func,
  toggleMapLayer: PropTypes.func,
  toggleSearch: PropTypes.func,
};

export default MapControls;
