import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

class PaneSwitch extends Component {
  render() {
    return (
      <div
        className={classNames(
          "pane-switch btn btn--map-control hidden-lg raised-box primary-background",
          { "pane-switch--fixed": this.props.fixed },
        )}
        onClick={this.props.switchPane}
      >
        {this.props.fixed ? (
          <i className="fa fa-map-marker" />
        ) : (
          <i className="fa fa-menu" />
        )}
      </div>
    );
  }
}

PaneSwitch.propTypes = {
  fixed: PropTypes.bool,
  switchPane: PropTypes.func,
};

export default PaneSwitch;
