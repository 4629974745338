/* global window, Event, document  */

import "../../../css/legend-container.scss";
import _flatMap from "lodash/flatMap";
import { withTranslation } from "react-i18next";
import React, { Component } from "react";
import { connect } from "react-redux";
import Swipe from "react-easy-swipe";

import PropTypes from "prop-types";
import _isEqual from "lodash/isEqual";
import _uniqBy from "lodash/uniqBy";
import sortBy from "lodash/sortBy";
import classNames from "classnames";

import { toggleLegend, setLegendHeight } from "../../actions/ui";

import { getName } from "../../lib/uiUtils";

import MapLegend from "./MapLegend";
import GeoZoneColors from "./GeoZoneColors";
import LoadingSpinner from "../LoadingSpinner";
import { LAYERS } from "../../constants/ui"

class LegendContainer extends Component {
  state = {
    title: "",
    items: [],
  };

  componentDidMount() {
    const legendContainer = document.getElementById("legend-container");
    legendContainer.addEventListener("transitionend", () =>
      window.dispatchEvent(new Event("resize")),
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeMapLayer, currentChoroplethIndicator } = this.props;

    if (
      !_isEqual(
        currentChoroplethIndicator,
        prevProps.currentChoroplethIndicator,
      ) ||
      prevProps.activeMapLayer !== activeMapLayer
    ) {
      this.toggleLegend(true);
    }
  }

  legend() {
    const {
      t,
      activeMapLayer,
      groupLegendItems,
      choropletlegendItems,
      currentChoroplethIndicator,
      activeFilter,
    } = this.props;
    if (activeMapLayer === LAYERS.choropleth) {
      return {
        title: getName(currentChoroplethIndicator),
        items: choropletlegendItems,
      };
    }
    if (activeMapLayer === LAYERS.filters && activeFilter) {
      return {
        title: activeFilter.name,
        items: [
          {name: "Yes", color: "green"},
          {name: "No", color: "red"},
        ]
      }
    }

    return {
      title: t("map.switchToGroups"),
      items: groupLegendItems,
    };
  }

  toggleLegend(displayLegend) {
    this.props.toggleLegend(displayLegend);
  }

  render() {
    const {
      activeMapLayer,
      activeMapGroup,
      fetchingChorplethValues,
      legendActive,
      toggleMapGroup,
    } = this.props;

    const { items, title } = this.legend();

    return (
      <div
        id="legend-container"
        className={classNames("legend-container raised-box", {
          "legend-container--open": legendActive,
        })}
      >
        <Swipe
          onSwipeUp={() => this.toggleLegend(true)}
          onSwipeDown={() => this.toggleLegend(false)}
        >
          <header className="legend-header" onClick={() => this.toggleLegend()}>
            <span>{title}</span>
          </header>
          <div id="map-legend__container">
            {fetchingChorplethValues &&
              activeMapLayer === "choropleth" &&
              legendActive && (
                <div className="legend__loading-spinner">
                  <LoadingSpinner />
                </div>
              )}
            <div
              className={classNames({
                "map-legend--hidden":
                  fetchingChorplethValues && activeMapLayer === "choropleth",
              })}
            >
              <MapLegend
                items={items}
                toggleMapGroup={toggleMapGroup}
                activeMapGroup={activeMapGroup}
                activeMapLayer={activeMapLayer}
              />
            </div>
          </div>
        </Swipe>
      </div>
    );
  }
}

LegendContainer.propTypes = {
  activeMapLayer: PropTypes.string,
  groupLegendItems: PropTypes.array,
  choropletlegendItems: PropTypes.array,
  fetchingChorplethValues: PropTypes.bool,
  currentZone: PropTypes.object,
  toggleLegend: PropTypes.func,
  setLegendHeight: PropTypes.func,
  legendActive: PropTypes.bool,
  t: PropTypes.func,
  currentChoroplethIndicator: PropTypes.object,
  searchActive: PropTypes.bool,
};

export const defaultLegendsPresenter = (geoZoneColors, t) =>
  [20, 40, 60, 80, 100].map(legend => {
    const { fillColor: color, fillOpacity: opacity } = geoZoneColors.colorScale(
      legend,
    )();
    return {
      startValue: `${legend - 20}`,
      endValue: t("formats.percentage", { value: legend - 1 }),
      opacity,
      color,
    };
  });

export const legendItemsPresenter = (indicator, defaultLegends) => {
  const { legendSets } = indicator;

  if (!legendSets) return null;

  const legendSet = legendSets[0];
  const items = legendSet
    ? sortBy(legendSet.legends, ["endValue"])
    : defaultLegends;
  return items.map(item => ({
    ...item,
    name: item.name ? item.name : `${item.startValue} - ${item.endValue}`,
  }));
};

const mapStateToProps = (state, props) => {
  const { currentChoroplethIndicator, currentGroupSetId } = state.map;
  const displayedGroups = (props.currentZone.displayedGroups || [])
    .map(e => {
      const group = state.project.displayedGroups.filter(
        g => g.name === e.name,
      )[0];
      if (group) {
        if (group.groupSet) {
          // xxx - Remove when groupSet_S_ goes live
          e.groupSetIds = [group.groupSet.id];
        } else {
          e.groupSetIds = group.groupSets.map(s => s.id);
        }
        return e;
      }
      return null;
    })
        .filter(obj => obj);
  const uniqueGroups = _uniqBy(displayedGroups, "id");
  const groupLegendItems = uniqueGroups
    .filter(g => g.groupSetIds.includes(currentGroupSetId))
    .map(item => ({
      ...item,
      name: `${item.name} (${item.entitiesCount})`,
    }));

  let choropletlegendItems;
  if (currentChoroplethIndicator) {
    const geoZoneColors = new GeoZoneColors(state.project);
    const defaultLegends = defaultLegendsPresenter(geoZoneColors, props.t);
    choropletlegendItems = legendItemsPresenter(
      currentChoroplethIndicator,
      defaultLegends,
    );
  }
  const filterElements = (state.project.filterElements.flatMap(e => e.elements) || []);
  const activeFilter = filterElements.find(({id, name}) => (id === state.ui.filter));

  return {
    fetchingChorplethValues: state.map.fetchingChorplethValues,
    groupLegendItems,
    activeFilter: activeFilter,
    choropletlegendItems,
    activeMapLayer: state.ui.activeMapLayer,
    activeMapGroup: state.ui.activeMapGroup,
    legendActive: state.ui.legendActive,
    searchActive: state.ui.searchActive,
    currentChoroplethIndicator,
  };
};

export default withTranslation()(
  connect(mapStateToProps, { toggleLegend, setLegendHeight })(LegendContainer),
);
