import React, { Component, Fragment } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import classNames from "classnames";

import { toggleSearch, setMapLayers, setMapGroups } from "../actions/ui";

import MapContainer from "./Map/MapContainer";
import MapNavigation from "./Map/MapNavigation";
import LegendContainer from "./Map/LegendContainer";

class MapPane extends Component {
  toggleSearch = () => {
    this.props.toggleSearch();
  };

  toggleMapLayer = activeMapLayer => {
    this.props.setMapLayers({
      activeMapLayer,
    });
  };

  toggleMapGroup = activeMapGroup => {
    if (this.props.activeMapLayer !== "groups") {
      return false;
    }
    if (this.props.activeMapGroup == activeMapGroup) {
      activeMapGroup = null;
    }
    this.props.setMapGroups({
      activeMapGroup,
    });
  };

  render() {
    const {
      activeMapLayer,
      activeMapGroup,
      currentZone,
      activeEntity,
      paneSize,
      active,
      searchActive,
    } = this.props;
    return (
      <div className={classNames("map-pane", { "map-pane--active": active })}>
        <MapContainer
          containerWidth={paneSize}
          currentZone={currentZone}
          activeEntity={activeEntity}
          activeMapLayer={activeMapLayer}
          activeMapGroup={activeMapGroup}
          toggleSearch={this.toggleSearch}
          toggleMapLayer={this.toggleMapLayer.bind(this)}
          searchActive={searchActive}
          switchPane={this.props.switchPane}
          customTileUrl={this.props.customTileUrl}
        />
        {!searchActive && (
          <Fragment>
            <MapNavigation
              currentZone={currentZone}
              activeEntity={activeEntity}
            />
            <LegendContainer
              currentZone={currentZone}
              toggleMapGroup={this.toggleMapGroup.bind(this)}
              activeMapGroup={activeMapGroup}
            />
          </Fragment>
        )}
      </div>
    );
  }
}

MapPane.propTypes = {
  active: PropTypes.bool,
  activeEntity: PropTypes.object,
  choroplethActive: PropTypes.bool,
  currentZone: PropTypes.object,
  paneSize: PropTypes.object,
  searchActive: PropTypes.bool,
  setMapLayers: PropTypes.func,
  setMapGroups: PropTypes.func,
  toggleSearch: PropTypes.func,
  activeMapLayer: PropTypes.string,
  activeMapGroup: PropTypes.string,
  customTileUrl: PropTypes.string,
};

const mapStateToProps = state => ({
  searchActive: state.ui.searchActive,
  activeMapLayer: state.ui.activeMapLayer,
  activeMapGroup: state.ui.activeMapGroup,
  mapLayers: state.ui.mapLayers,
  mapGroups: state.ui.mapGroups,
  customTileUrl: state.project.map.customTileUrl,
});

export default connect(mapStateToProps, {
  toggleSearch,
  setMapLayers,
  setMapGroups,
})(MapPane);
