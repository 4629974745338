import kebabCase from "lodash/kebabCase";
import history from "../config/history";

export const redirectTo = path => {
  history.push(path);
};

export const orgUnitPath = orgUnit => {
  const type = orgUnit.type === "zone" ? "zone" : "entity";

  return `/data/${type}/${orgUnit.id}`;
};

export const parametrize = stringItem =>
  kebabCase(stringItem.replace(/[^a-zA-Z 0-9]+/g, ""));

export const isExternal = url => url && url.match(/http/);
