import "../../css/header.scss";
import "../../css/scope-navbar.scss";
import "../../css/static-page.scss";

import { Route, Switch } from "react-router-dom";

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AppMainColors from "./AppMainColors";
import Body from "./Body";
import Footer from "./Footer";
import Header from "./Header/Header";
import PublicationsContainer from "./Publications/PublicationsContainer";
import StaticPageBreadcrumb from "./StaticPageBreadcrumb";
import withTracker from "../lib/withTracker";

function StaticPage({ title, match }) {
  return (
    <Body className="full-width">
      <Fragment>
        <AppMainColors />
        <Header />
        <StaticPageBreadcrumb title={title} />
        <section className="content-pane static-page">
          <Switch>
            <Route
              path={`${match.url}/publications`}
              component={PublicationsContainer}
            />
          </Switch>
        </section>
        <Footer />
      </Fragment>
    </Body>
  );
}

const mapStateToProps = state => ({
  title: state.pageTitle,
});

export default withTracker(connect(mapStateToProps)(StaticPage));

StaticPage.propTypes = {
  location: PropTypes.object,
  title: PropTypes.string,
  match: PropTypes.object,
};
