import fuzzyFilter from "fuzzy-array-filter";

export const getSuggestions = (inputValue, searchableItems) => {
  const inputLength = inputValue.length;

  const results = inputLength < 3 ? [] : searchableItems;
  const options = {
    keys: ["name"],
    id: "id",
    threshold: 0.2,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
  };
  return results.filter(fuzzyFilter(inputValue, options));
};
