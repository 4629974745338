/* globals IntersectionObserver, document */
import "intersection-observer";
import { redirectTo, parametrize } from "./routerUtils";

import { setActiveTab } from "../actions/ui";
import store from "../store/store";

let spyElements;
let observer;
const contentId = "content-pane";
const threshold = 0.5;
const options = {
  root: document.querySelector(contentId),
  rootMargin: "-108px 0px 0px 0px",
  threshold,
};

const setTargetsVisibility = (entry, spyElement, spyIndex) => {
  spyElement.targets.forEach((target, targetIndex) => {
    if (target.domElement === entry.target) {
      spyElements[spyIndex].targets[targetIndex].isVisible =
        entry.intersectionRatio > threshold;
    }
  });
};

const isSpyElementActive = spyIndex =>
  spyElements[spyIndex].targets.filter(t => t.isVisible === true).length > 0;

const observerCallback = entries => {
  const { activeTab, scrollSpyActive } = store.getState().ui;
  entries.forEach(entry => {
    spyElements.forEach((spyElement, spyIndex) => {
      setTargetsVisibility(entry, spyElement, spyIndex);
      spyElements[spyIndex].isActive = isSpyElementActive(spyIndex);
    });

    const lastSpy = spyElements[spyElements.length - 1];
    const beforeLastSpyIndex = spyElements.length - 2;
    const beforeLastSpy = spyElements[beforeLastSpyIndex];
    if (lastSpy && beforeLastSpy) {
      if (lastSpy.isActive) {
        beforeLastSpy.isActive = false;
      } else if (isSpyElementActive(beforeLastSpyIndex)) {
        beforeLastSpy.isActive = true;
      }
    }
    const activeSpyElements = spyElements.filter(
      spyElement => spyElement.isActive,
    );

    if (activeSpyElements.length > 0) {
      const activeSpyElementName = parametrize(activeSpyElements[0].name);
      if (activeTab !== activeSpyElementName && scrollSpyActive) {
        redirectTo(`#section-${activeSpyElementName}`);
        store.dispatch(setActiveTab(activeSpyElementName));
      }
    }
  });
};

const getSpyTargets = (domElement, name) => {
  const targets = [];
  let nextSiblingIsContent = true;
  let currentElement = domElement.nextSibling;
  if (currentElement) {
    while (nextSiblingIsContent) {
      currentElement.name = name;
      targets.push({
        name,
        isVisible: false,
        domElement: currentElement,
      });
      if (
        !currentElement.nextSibling ||
        currentElement.nextSibling.classList.contains("section--separator") ||
        name === "info"
      ) {
        nextSiblingIsContent = false;
      }
      currentElement = currentElement.nextSibling;
    }
  }
  return targets;
};

const spyOn = (domElement, name) => {
  if (
    domElement &&
    domElement.id &&
    !spyElements.find(spyElement => spyElement.domElement.id === domElement.id)
  ) {
    const spyElement = {
      domElement,
      name,
      isActive: false,
      targets: getSpyTargets(domElement, name),
    };
    spyElements.push(spyElement);
    spyElement.targets.forEach(target => observer.observe(target.domElement));
  }
};

const init = () => {
  observer = new IntersectionObserver(observerCallback, options);
  spyElements = [];
};

const reset = () => {
  observer = null;
  spyElements = [];
};

export default {
  init,
  spyOn,
  reset,
};
