import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { formatDate } from "../../lib/uiUtils";
import PublicationSummary from "../Publications/PublicationSummary";
import LoadingSpinner from "../LoadingSpinner";

function PublicationsSection(props) {
  const { isFetching, t, publications } = props;

  if (isFetching) {
    <div>
      <LoadingSpinner />
      <div style={{ height: 100 }} />
    </div>;
  }

  if (!publications.length) {
    return null;
  }

  return (
    <section className="section">
      <div className="container-fluid">
        <h3 className="section__header publications">
          {t("publications.title")}
        </h3>
        <div className="publications-list row">
          {publications.map(publication => (
            <div key={publication.id} className="col-xs-12">
              <PublicationSummary
                {...publication}
                date={formatDate(publication.createdAt, props.i18n.language)}
                url={`pages/publications/${publication.id}`}
              />
            </div>
          ))}
        </div>
        <p className="publications-list__see_all">
          <Link to={"/pages/publications"}>{t("buttons.see_all")}</Link>
        </p>
      </div>
    </section>
  );
}

const mapStateToProps = state => ({
  publications: state.publications.items.slice(0, 3),
  isFetching: state.publications.isFetching,
});

PublicationsSection.propTypes = {
  publications: PropTypes.arrayOf(PropTypes.object),
  isFetching: PropTypes.bool,
};

export default withTranslation()(connect(mapStateToProps)(PublicationsSection));
