import "../../../css/search-results.scss";

import React, { Component } from "react";

import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { orgUnitPath } from "../../lib/routerUtils";
import { subLevelName } from "../../reducers/project";
import { formattedName } from "../../lib/uiUtils";

function sign(item) {
  return item.type === "entity" ? "icon-facility" : "map-signs";
}

class SearchResults extends Component {
  levelName = level => subLevelName(this.props.levels, level - 1);

  render() {
    const categories = Object.keys(this.props.suggestionsCategories);

    return (
      <div className="search-results">
        <div className="search-results__list">
          {categories.map(category => (
            <div key={category} className="search-results__list__group">
              <h5 className="search-results__list__group__header">
                {this.levelName(category)} (
                {this.props.suggestionsCategories[category].length})
              </h5>
              <ul>
                {this.props.suggestionsCategories[category].map(result => (
                  <li
                    key={result.id}
                    className="search-results__list__group__result"
                  >
                    <Link to={orgUnitPath(result)}>
                      <i className={`fa fa-${sign(result)} icon--left`} />
                      {formattedName(result.name)}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  levels: state.project.levels,
});

export default connect(mapStateToProps)(SearchResults);
