import React, { Component, Fragment } from "react";

import { Link } from "react-router-dom";
import Menu from "../Menu";
import MenuItem from "../MenuItem";
import { formattedName } from "../../lib/uiUtils";
import { orgUnitPath } from "../../lib/routerUtils";

class BreadcrumbMenu extends Component {
  state = {
    showMenu: false,
  };

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  handleClickOutside = () => {
    if (this.state.showMenu) {
      this.toggleMenu();
    }
  };

  render() {
    return (
      <Fragment>
        <a onClick={this.toggleMenu}>
          <i className="fa fa-more-horiz" />
        </a>
        <Menu
          in={this.state.showMenu}
          handleClickOutside={this.handleClickOutside}
          align="left"
        >
          <ul className="menu-list">
            {this.props.items.map(item => (
              <MenuItem key={item.id}>
                <Link to={orgUnitPath(item)} onClick={this.handleClickOutside}>
                  {formattedName(item.name)}
                </Link>
              </MenuItem>
            ))}
          </ul>
        </Menu>
      </Fragment>
    );
  }
}

export default BreadcrumbMenu;
