import "../../../css/graphs-section.scss";
import _sortBy from "lodash/sortBy";
import _isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { getAverages, serieName } from "../../lib/graphsUtils";

import GraphContainer from "../Graphs/GraphContainer";
import LoadingSpinner from "../LoadingSpinner";
import SectionDescription from "./SectionDescription";
import { getCurrentOrgUnitId } from "../../reducers/zones";
import { getName } from "../../lib/uiUtils";
import indicatorUtils from "../../lib/indicatorUtils";
import seriesColors from "../../lib/colors";
import { siblingOrgUnits, getOrgUnit } from "../../reducers/orgUnits";
import { sectionPresenter, seriesDataEnhancer } from "../../lib/sectionUtils";
import SeriesSelectorService from "../../lib/SeriesSelectorService";
import { currentPeriodMin, currentPeriodMax } from "../../reducers/period";

const graphSeriesDataValues = series => series.map(serie => serie.data);

class GraphsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentGraphId: (this.props.graphNavigatorItems || []).length
        ? this.props.graphNavigatorItems[0].id
        : undefined,
      navOpen: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !_isEqual(nextProps.currentPeriodMin, this.props.currentPeriodMin) ||
      !_isEqual(nextProps.currentPeriodMax, this.props.currentPeriodMax) ||
      !_isEqual(nextProps.isFetching, this.props.isFetching) ||
      nextState.navOpen !== this.state.navOpen
    );
  }

  toggleNav = () => {
    this.setState({
      navOpen: !this.state.navOpen,
    });
  };

  updateCurrentGraphId(currentGraphId) {
    this.setState({ currentGraphId });
  }

  handleGraphSelect = graphId => {
    this.toggleNav();
    this.updateCurrentGraphId(graphId);
  };

  avgSerieForCurrentGraph = series => ({
    data: getAverages(graphSeriesDataValues(series)),
    active: true,
    name: this.props.t("graphLegends.average"),
    dashed: true,
  });

  currentGraphId() {
    return this.state.currentGraphId
      ? this.state.currentGraphId
      : (this.props.graphNavigatorItems[0] || {}).id;
  }

  seriesPresenter(series) {
    if (series.length < 1) {
      return [];
    }

    const seriesWithData = seriesDataEnhancer(
      series,
      this.props.currentPeriodMin,
      this.props.currentPeriodMax,
      this.props.dataByOrgUnitId || [],
    );

    // Only calculate avg if they are siblings orgUnit
    if (
      this.props.section.sectionType === "standard" &&
      seriesWithData.length > 1
    ) {
      const avgSerieForCurrentGraph = this.avgSerieForCurrentGraph(
        seriesWithData,
      );
      seriesWithData.splice(0, 0, avgSerieForCurrentGraph);
    }

    return seriesWithData
      .filter(serie => serie.data.length)
      .map((serie, index) => {
        const generatedData = indicatorUtils.generateData(
          serie.orgUnitId,
          serie.data,
          index,
        );
        return {
          ...serie,
          id: index,
          indicatorName: getName(serie),
          color: seriesColors(
            this.props.serieBaseColor,
            this.props.serieSecondaryColor,
          )[index],
          data: generatedData,
          active: serie.active !== undefined ? serie.active : true,
          name: serieName(serie, this.props.section.sectionType),
        };
      });
  }

  spinner() {
    return (
      <div>
        <LoadingSpinner />
      </div>
    );
  }

  sectionContent() {
    const seriesSelector = new SeriesSelectorService({
      indicatorIdsByQuery: this.props.indicatorIdsByQuery,
      currentGraphId: this.currentGraphId(),
      indicators: this.props.indicators,
      sectionType: this.props.section.sectionType,
      currentOrgUnitId: this.props.currentOrgUnitId,
      displayChildrenValues: this.props.displayChildrenValues,
      childrenOrgUnitIds: this.props.childrenOrgUnitIds,
      siblingOrgUnitIds: this.props.siblingOrgUnitIds,
    }).run();
    const graphSeriesData = this.seriesPresenter(seriesSelector);

    return (
      <div className="graphs-section">
        {this.graphContainer(graphSeriesData)}
      </div>
    );
  }

  graphContainer(graphSeriesData) {
    const currentGraph = this.props.graphNavigatorItems.find(
      item => item.id === this.currentGraphId(),
    );
    const { scaleType, defaultGraphType } = this.props.section;

    return (
      <GraphContainer
        graphNavigatorItems={this.props.graphNavigatorItems}
        toggleNav={this.toggleNav}
        graphSeriesData={graphSeriesData}
        scaleType={scaleType}
        defaultGraphType={defaultGraphType}
        currentGraph={currentGraph}
        title={currentGraph && getName(currentGraph)}
        onGraphSelect={this.handleGraphSelect}
      />
    );
  }

  render() {
    const {
      section: { name: title, description },
      currentOrgUnitId,
      isFetching,
    } = this.props;

    return (
      <section className="section" data-id={this.props.section.id}>
        <div className="container-fluid">
          <h3 className="section__header">{title}</h3>
          <SectionDescription text={description} />
          {isFetching || !currentOrgUnitId || !this.props.dataByOrgUnitId
            ? this.spinner()
            : this.sectionContent()}
        </div>
      </section>
    );
  }
}

GraphsSection.propTypes = {
  allPeriods: PropTypes.arrayOf(PropTypes.string),
  childrenOrgUnitIds: PropTypes.array,
  currentOrgUnitId: PropTypes.string,
  currentPeriodMax: PropTypes.string,
  currentPeriodMin: PropTypes.string,
  dataByOrgUnitId: PropTypes.object,
  displayChildrenValues: PropTypes.bool,
  graphNavigatorItems: PropTypes.arrayOf(PropTypes.object),
  indicatorIdsByQuery: PropTypes.object,
  indicators: PropTypes.array,
  isFetching: PropTypes.bool,
  scaleType: PropTypes.string,
  section: PropTypes.object,
  serieBaseColor: PropTypes.string,
  serieSecondaryColor: PropTypes.string,
  siblingOrgUnitIds: PropTypes.arrayOf(PropTypes.string),
  t: PropTypes.func,
  title: PropTypes.string,
};

const mapStateToProps = (state, props) => {
  const {
    section: { queries, sectionType, displayChildrenValues },
  } = props;
  const presentedSection = sectionPresenter(state, props.section);
  const currentOrgUnitId = getCurrentOrgUnitId(state);

  const graphNavigatorItems = () => {
    switch (sectionType) {
      case "multi_indicators_groups":
        return presentedSection.queries;
      case "multi_indicators":
        return [];
      default:
        return _sortBy(presentedSection.items, ["position"]);
    }
  };

  const siblingOrgUnitIds = siblingOrgUnits(state, currentOrgUnitId).map(
    orgUnit => orgUnit.id,
  );

  const childrenOrgUnitIds =
    getOrgUnit(state, currentOrgUnitId).childrenIds || [];

  const allPeriods = state.period.selected;
  const vizBranding = state.project.colors.visualization;
  const contrastBranding = vizBranding === "primary" ? "secondary" : "primary";
  const { cssPalette } = state.project;
  const indicatorIdsByQuery = state.dataElements.byQueryId;

  return {
    queries,
    indicatorIdsByQuery,
    allPeriods,
    siblingOrgUnitIds,
    childrenOrgUnitIds,
    currentOrgUnitId,
    displayChildrenValues,
    mainOrgUnitId: state.project.mainZone.id,
    currentPeriodMin: currentPeriodMin(state.period),
    currentPeriodMax: currentPeriodMax(state.period),
    graphNavigatorItems: graphNavigatorItems(),
    serieBaseColor: cssPalette[vizBranding],
    serieSecondaryColor: cssPalette[contrastBranding],
    indicators: presentedSection.items,
  };
};

export default withTranslation()(connect(mapStateToProps)(GraphsSection));
