import {
  Crosshair,
  FlexibleWidthXYPlot,
  HorizontalGridLines,
  XAxis,
  YAxis,
} from "react-vis/es";
import React, { PureComponent } from "react";

import _flatMap from "lodash/flatMap";
import _maxBy from "lodash/maxBy";
import { withTranslation } from "react-i18next";
import GraphTooltip from "./GraphTooltip";
import { formatLabel, getLabelWidthEstimation } from "../../lib/graphsUtils";
import { getName, isMobile } from "../../lib/uiUtils";
import { valueFactory } from "../../lib/indicatorUtils";

class Graph extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.state = {
      crosshairValue: [],
    };
  }

  formatCrosshairValues(crosshairValues) {
    const series = this.props.graphSeriesData;
    return crosshairValues.map(crosshairValue => {
      const serie = series[crosshairValue.serieId];

      return {
        ...crosshairValue,
        y: crosshairValue.y,
        formattedValue: crosshairValue.missingData
          ? this.props.t("noData.missingData")
          : valueFactory(this.props.scaleType.toUpperCase(), crosshairValue.y),
        name: serie.name,
        color: serie.color,
      };
    });
  }

  updateCrossHair = (value, info) => {
    const isSerieActive = this.props.graphSeriesData[value.serieId].active;

    if (this.state.lastLocation !== info.innerX && isSerieActive) {
      this.setState({ crosshairValue: [], lastLocation: info.innerX }, () => {
        const crosshairValues = this.state.crosshairValue;
        const isIn = crosshairValues.find(
          crosshairValue => value.serieId === crosshairValue.id,
        );
        if (!isIn) crosshairValues.push(value);

        this.setState({
          crosshairValue: this.formatCrosshairValues(crosshairValues),
        });
      });
    }
  };

  onMouseLeave = () => {
    this.setState({ crosshairValue: [] });
  };

  renderSerie = (type, serie, index) => {
    const { data, dashed, color } = serie;
    const Comp = type;
    return (
      <Comp
        data={data}
        key={index}
        color={color}
        curve={"curveMonotoneX"}
        onNearestX={this.updateCrossHair}
        strokeStyle={dashed ? "dashed" : "solid"}
        strokeWidth={2}
        size={3}
      />
    );
  };

  render() {
    const { graphSeriesData, chartType } = this.props;
    const activeGraphSeriesData = graphSeriesData.filter(
      serie => serie.data.length && serie.active,
    );

    if (!activeGraphSeriesData.length) {
      return null;
    }

    const maxValue = Math.max(
      ...activeGraphSeriesData.flatMap(series => series.data.map(e => e.y)),
    );

    const oldMaxValue = _maxBy(
      _flatMap(activeGraphSeriesData, serie => serie.data),
      o => o.y,
    ).y;

    const labelWidthEstimation = getLabelWidthEstimation(
      valueFactory(this.props.scaleType.toUpperCase(), maxValue).length,
      6,
    );

    let xyPlotProps = {
      margin: { left: 0, right: labelWidthEstimation, top: 50, bottom: 50 },
      xType: "ordinal",
      height: 300,
      animation: true,
      onMouseLeave: this.onMouseLeave,
      yDomain: [0, maxValue],
    };

    if (this.props.scaleType === "percentage") {
      xyPlotProps = {
        ...xyPlotProps,
        yDomain: [0, 100],
      };
    }

    return (
      <div
        id="plotGraphContainer"
        className="graph"
        dataindicatorid={activeGraphSeriesData[0].displayShortName}
      >
        <FlexibleWidthXYPlot {...xyPlotProps}>
          <HorizontalGridLines />
          <YAxis
            hideLine
            orientation="right"
            tickFormat={tick =>
              valueFactory(this.props.scaleType.toUpperCase(), tick)
            }
          />
          <XAxis
            hideLine
            tickFormat={tick => formatLabel(tick, "period")}
            style={{
              text: {
                transform: "rotate(30deg) translate(30px,10px)",
              },
            }}
          />
          {activeGraphSeriesData.map((serie, index) =>
            this.renderSerie(chartType.type, serie, index),
          )}
          {!isMobile && this.state.crosshairValue.length > 0 ? (
            <Crosshair values={this.state.crosshairValue}>
              <GraphTooltip values={this.state.crosshairValue} />
            </Crosshair>
          ) : null}
        </FlexibleWidthXYPlot>
      </div>
    );
  }
}

export default withTranslation()(Graph);
