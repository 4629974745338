import _flatMap from "lodash/flatMap";
import _reduce from "lodash/reduce";
import _values from "lodash/values";

export const mapTopEntitiesByDataElementId = sections => {
  const dataElements = _flatMap(
    sections.filter(section => section.sectionType === "entities_ranking"),
    section => section.items,
  );

  return _reduce(
    dataElements,
    (topEntitiesByDataElement, dataElement) => {
      topEntitiesByDataElement[dataElement.id] = dataElement.topEntities;
      return topEntitiesByDataElement;
    },
    {},
  );
};
