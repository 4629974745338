import "../../../css/org-unit/attributes-list.scss";

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { entitiesLevelName, subLevelName } from "../../reducers/project";

import Indicator from "../KeyIndicators/Indicator";
import { getCurrentEntity } from "../../reducers/entities";
import { getCurrentZone } from "../../reducers/zones";
import DataReporting from "./DataReporting";

function OrgUnitAttributesList(props) {
  const { t, displayedGroupsList } = props;
  const fields = [
    { fieldName: "contactPerson", type: "TEXT" },
    { fieldName: "address", type: "TEXT" },
    { fieldName: "ministry", type: "TEXT" },
    { fieldName: "phoneNumber", type: "TEXT" },
  ];

  return (
    <div className="attributes-list">
      {props.subLevelName !== props.entitiesLevelName && (
        <Indicator
          key="attribute-listitem-subzone-count"
          title={`${props.subLevelName} - Total`}
          value={props.subZonesCount}
          showProgress={false}
          valueType="NUMBER"
          compact
        />
      )}
      {props.entity.type === "zone" && props.entitiesCount && (
        <Indicator
          key="attribute-listitem-entities-count"
          title={`${props.entitiesLevelName} - Total`}
          value={props.entitiesCount}
          showProgress={false}
          valueType="NUMBER"
          compact
        />
      )}
      {fields.map((field, index) => {
        if (
          props.entity[field.fieldName] &&
          props.entity[field.fieldName].length
        ) {
          const value = props.entity[field.fieldName];
          return (
            <Indicator
              key={`attribute-listitem-${index}`}
              title={t(`entityInfo.${field.fieldName}`)}
              showProgress={false}
              compact
              valueType={field.type}
              value={value}
            />
          );
        }
      })}
      {!!displayedGroupsList.length && props.entity.type !== "zone" && (
        <Indicator
          key={`attribute-listitem-displayed-groups`}
          title={t(`entityInfo.displayedGroups`)}
          showProgress={false}
          compact
          valueType="ARRAY"
          value={displayedGroupsList}
        />
      )}
      <DataReporting email={props.email} orgUnit={props.entity} />
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const activeEntity = getCurrentEntity(state.entities);
  const currentZone = getCurrentZone(state.zones, activeEntity);

  return {
    email: state.project.contactEmail,
    subZonesCount: currentZone.childrenIds.length,
    entitiesCount: currentZone.entitiesCount,
    subLevelName: subLevelName(state.project.levels, currentZone.level),
    entitiesLevelName: entitiesLevelName(
      state.project.levels,
      state.entities.level,
    ),
    displayedGroupsList: props.entity.displayedGroups || [],
  };
};

export default withTranslation()(
  connect(mapStateToProps)(OrgUnitAttributesList),
);

OrgUnitAttributesList.propTypes = {
  displayedGroupsList: PropTypes.array,
  entitiesCount: PropTypes.number,
  entitiesLevelName: PropTypes.string,
  entity: PropTypes.object,
  subLevelName: PropTypes.string,
  subZonesCount: PropTypes.number,
  t: PropTypes.func,
};
