import _uniqBy from "lodash/uniqBy";
import { addMissingLastPeriodValues } from "./periodsUtils";
import {
  indicatorsForQuery,
  indicatorsForSection,
  filterMetaData,
} from "../reducers/dataElements";

export const sectionPresenter = (state, section) => ({
  ...section,
  items: indicatorsForSection(state, section.id),
});

export const queriesFromIndicators = indicators =>
  _uniqBy(
    indicators.map(indicator => indicator.query),
    "id",
  ).map(query => ({
    ...query,
    itemsIds: indicators
      .filter(indicator => indicator.query.id === query.id)
      .map(indicator => indicator.id),
  }));

export const createSerieForOrgUnits = (
  orgUnitIds,
  mainGraphSerie,
  active = false,
  persistable = true,
) =>
  orgUnitIds.map(siblingId => ({
    ...mainGraphSerie,
    orgUnitId: siblingId,
    active,
    persistable,
  }));

const indicatorsFromDataByOrgUnitId = (data, serieId) =>
  data ? data.find(indicator => indicator.id === serieId) || {} : {};

export const seriesDataEnhancer = (
  series,
  currentPeriodMin,
  currentPeriodMax,
  data,
) =>
  series.map(serie => ({
    ...serie,
    data: filterMetaData(
      indicatorsFromDataByOrgUnitId(data[serie.orgUnitId], serie.id).data || [],
      currentPeriodMin,
      currentPeriodMax,
    ),
  }));

export const decorateKpiQueries = (
  indicatorsIdsForQueryId,
  queries,
  indicators,
) => {
  const decoratedQueries = queries.map(query => ({
    ...query,
    indicators: indicatorsForQuery(
      indicatorsIdsForQueryId,
      indicators,
      query.id,
    ),
  }));
  return decoratedQueries;
};
