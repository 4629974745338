/* globals document */

import { Children } from "react";

import PropTypes from "prop-types";
import withSideEffect from "react-side-effect";

function Body(props) {
  return Children.only(props.children);
}

Body.propTypes = {
  className: PropTypes.string.isRequired,
};

function reducePropsToState(propsList) {
  return propsList
    .map(props => props.className)
    .filter((value, index, self) => self.indexOf(value) === index)
    .join(" ");
}

function handleStateChangeOnClient(stringClassNames) {
  document.body.className = stringClassNames || "";
}

export default withSideEffect(
  reducePropsToState,
  handleStateChangeOnClient,
)(Body);
