import React from "react";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";

function Menu(props) {
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        props.handleClickOutside();
      }}
    >
      <div
        className={classNames(
          "menu raised-box",
          {
            active: props.in,
          },
          `menu--align-${props.align}`,
        )}
      >
        {props.children}
      </div>
    </OutsideClickHandler>
  );
}

export default Menu;
