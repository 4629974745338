import React from "react";

import "../../css/loading-spinner.scss";

export default function LoadingSpinner() {
  return (
    <div className="text-center loading-spinner">
      <i className="fa fa-spinner fa-spin fa-2x" />
    </div>
  );
}
