import PropTypes from "prop-types";
import React from "react";

const propTypes = {
  color: PropTypes.string,
};

const ColorLegend = ({ color }) => (
  <div
    className="color-legend"
    style={{
      backgroundColor: color,
    }}
  />
);

ColorLegend.propTypes = propTypes;

export default ColorLegend;
