import * as types from "../constants/actions-type";

function ui(
  state = {
    searchActive: false,
    filterActive: false,
    mapLayers: {
      choropleth: false,
      groups: false,
    },
    activeMapLayer: null,
    showDrawer: false,
    activeTab: "info",
    scrollSpyActive: true,
    currentGraphType: null,
    currentOrgunitLegend: [],
    legendActive: true,
    filter: null,
  },
  action,
) {
  switch (action.type) {
    case types.TOGGLE_SEARCH:
      return {
        ...state,
        searchActive: action.hide ? false : !state.searchActive,
      };

    case types.FILTER_TOGGLE:
      return {
        ...state,
        filterActive: action.hide ? false : !state.filterActive,
      };
    case types.SET_MAP_LAYERS:
      return {
        ...state,
        ...action.mapLayers,
      };
    case types.FILTER_SET:
      return {
        ...state,
        filter: action.filter,
      };
    case types.SET_MAP_GROUPS:
      return {
        ...state,
        ...action.mapGroups,
      };
    case types.SET_SCROLL_SPY:
      return {
        ...state,
        scrollSpyActive: action.value,
      };
    case types.SET_ACTIVE_TAB:
      return {
        ...state,
        activeTab: action.value,
      };
    case types.SET_CURRENT_ORGUNIT_LEGEND:
      return {
        ...state,
        currentOrgunitLegend: action.value,
      };
    case types.TOGGLE_LEGEND:
      return {
        ...state,
        legendActive:
          action.displayLegend !== undefined
            ? action.displayLegend
            : !state.legendActive,
      };
    case types.SET_LEGEND_HEIGHT:
      return {
        ...state,
        legendHeight: action.legendHeight,
      };
    default:
      return state;
  }
}

export default ui;
