import "../../../css/header.scss";

import React, { Component, Fragment } from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import PeriodPicker from "../../containers/PeriodPicker";
import Drawer from "./Drawer";
import { formattedName, getName } from "../../lib/uiUtils";

class Header extends Component {
  state = {
    drawerOpen: false,
  };

  handleClickOutside = () => {
    if (this.state.drawerOpen) {
      this.toggleDrawer();
    }
  };

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen });
  };

  render() {
    const {
      logoUrl,
      title,
      noTabs,
      menuItems,
      hasPublications,
      shortDescription,
    } = this.props;
    return (
      <header
        className={classNames(
          "container-fluid header fixed primary-background",
          { "header--no-tabs": noTabs },
        )}
      >
        <Fragment>
          <a
            href="#"
            className="header__sidenav_toggle icon--left"
            onClick={this.toggleDrawer}
          >
            <i className="fa fa-menu" />
          </a>
          <Drawer
            open={this.state.drawerOpen}
            handleClickOutside={this.handleClickOutside}
            logoUrl={logoUrl}
            menuItems={menuItems}
            hasPublications={hasPublications}
          />
        </Fragment>
        <Helmet>
          <title>{`${formattedName(
            title,
          )} - ${shortDescription} - Dataviz`}</title>
        </Helmet>
        <h1 className="header__title">{formattedName(title)}</h1>
        <PeriodPicker />
      </header>
    );
  }
}

const titleSelector = orgUnit => {
  if (!orgUnit) {
    return "";
  }
  return getName(orgUnit);
};

const mapStateToProps = (state, props) => {
  const {
    project: { logoUrl, menuItems, shortDescription },
    publications,
  } = state;
  const title = titleSelector(props.activeEntity || props.currentZone);

  return {
    logoUrl,
    title,
    shortDescription,
    menuItems,
    hasPublications: !!(publications.items || []).length,
  };
};

Header.propTypes = {
  backgroundImage: PropTypes.string,
  hasPublications: PropTypes.bool,
  logoUrl: PropTypes.string,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  noTabs: PropTypes.bool,
  title: PropTypes.string,
  shortDescription: PropTypes.string,
};

export default connect(mapStateToProps)(Header);
