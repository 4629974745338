import PropTypes from "prop-types";
import React from "react";
import { applyContainerQuery } from "react-container-query";
import classNames from "classnames";
import { connect } from "react-redux";
import TopEntities from "./TopEntities";
import SectionDescription from "../Sections/SectionDescription";
import mediaQueries from "../../lib/browser";
import { sectionPresenter } from "../../lib/sectionUtils";
import LoadingSpinner from "../LoadingSpinner";

const TopEntitiesSection = ({
  title,
  dataElements,
  isFetching,
  description,
  containerQuery: mq,
}) => {
  if (!dataElements.length && !isFetching) {
    return null;
  }
  const topEntityClasses = classNames({
    "col-xs-12 top-entities-list--spaced": !mq.lg,
    "col-xs-6": mq.lg,
  });

  return (
    <section className="section section--alternate">
      <div className="container-fluid">
        <h3 className="section__header">{title}</h3>
        {description && <SectionDescription text={description} />}

        {isFetching ? (
          <LoadingSpinner />
        ) : (
          <div className="row">
            {dataElements.map(dataElement => (
              <TopEntities
                dataElement={dataElement}
                key={dataElement.id}
                classNames={topEntityClasses}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

TopEntitiesSection.propTypes = {
  dataElements: PropTypes.arrayOf(PropTypes.object),
  description: PropTypes.string,
  isFetching: PropTypes.bool,
  mq: PropTypes.object,
  containerQuery: PropTypes.object,
  title: PropTypes.string,
};

const mapStateToProps = (state, props) => ({
  dataElements: sectionPresenter(state, props.section).items,
});

export default connect(mapStateToProps)(
  applyContainerQuery(TopEntitiesSection, mediaQueries),
);
