import {
  FlexibleWidthXYPlot,
  XAxis,
  YAxis,
  HeatmapSeries,
  LabelSeries,
} from "react-vis/es";
import React from "react";
import _max from "lodash/max";
import _flatMap from "lodash/flatMap";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Color from "color";
import PropTypes from "prop-types";
import { formatLabel, getLabelWidthEstimation } from "../../lib/graphsUtils";
import { valueFactory } from "../../lib/indicatorUtils";
import { getName } from "../../lib/uiUtils";
import GeoZoneColors from "../Map/GeoZoneColors";

const bgcolor = (indicator, value, colorUtils) =>
  (indicator.legendSets || []).length
    ? colorUtils.legendColors(indicator, value)().fillColor
    : "#efefef";

function Heatmap(props) {
  const { graphSeriesData, scaleType, colorUtils } = props;

  const data = _flatMap(graphSeriesData, indicator =>
    indicator.data.map(values => ({
      x: values.x,
      y: indicator.name || getName(indicator),
      style: {
        pointerEvents: "none",
        fill: Color(bgcolor(indicator, values.y, colorUtils)).isLight()
          ? "#333"
          : "#fff",
      },
      legendColor: bgcolor(indicator, values.y, colorUtils),
      color: valueFactory(
        indicator.valueType || scaleType.toUpperCase(),
        values.y,
      ),
    })),
  );

  const labelWidthEstimation = getLabelWidthEstimation(
    _max(data.map(item => item.y.length)),
    6,
  );

  const config = {
    yType: "ordinal",
    xType: "ordinal",
    height: graphSeriesData.length * 50,
    margin: { right: 0, left: labelWidthEstimation, top: 50, bottom: 50 },
  };

  return (
    <div className="graph">
      <FlexibleWidthXYPlot {...config}>
        <YAxis hideLine orientation="left" />
        <XAxis
          tickLabelAngle={-50}
          hideLine
          tickFormat={tick => formatLabel(tick, "period")}
        />
        <HeatmapSeries
          style={{
            stroke: "white",
            strokeWidth: "2px",
          }}
          className="heatmap-serie"
          getColor={d => d.legendColor}
          colorType="literal"
          data={data}
        />
        <LabelSeries
          data={data}
          getLabel={d => `${d.color}`}
          style={{ pointerEvents: "none" }}
          labelAnchorX="middle"
          labelAnchorY="middle"
        />
      </FlexibleWidthXYPlot>
    </div>
  );
}

Heatmap.propTypes = {
  colorUtils: PropTypes.object,
  graphSeriesData: PropTypes.array,
  scaleType: PropTypes.string,
};

const mapStateToProps = state => ({
  colorUtils: new GeoZoneColors(state.project),
});

export default connect(mapStateToProps)(withTranslation()(Heatmap));
