import "../../../css/graph-legend.scss";

import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import OrgUnitLegend from "../OrgUnitLegend";

const GraphLegend = ({ series, onGraphLegendClick }) => (
  <div className="graph-legend-container centered">
    <ul
      className={classNames("graph-legend", {
        "graph-legend--centered": series.length < 3,
      })}
    >
      {series.map((serie, index) => {
        const activeSeries = series.filter(s => s.active);
        const dispatchToggleOrgUnit = () => {
          if (activeSeries.length === 1 && serie.active) return null;
          return onGraphLegendClick(serie);
        };
        return (
          <li key={`${serie.name}-${index}`}>
            <OrgUnitLegend
              onClickOrgUnitLegend={dispatchToggleOrgUnit}
              name={serie.name}
              color={serie.color}
              active={serie.active}
              clickable={activeSeries.length > 1 || !serie.active}
              showTooltip={activeSeries.length > 1}
            />
          </li>
        );
      })}
    </ul>
  </div>
);

GraphLegend.propTypes = {
  onGraphLegendClick: PropTypes.func,
  series: PropTypes.array,
};

export default GraphLegend;
