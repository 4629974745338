import { Link, withRouter } from "react-router-dom";

import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";

const propTypes = {
  title: PropTypes.string,
  location: PropTypes.object,
};

function StaticPageBreadcrumb({ title, location, t }) {
  const isPublication = location.pathname === "/pages/publications";

  return (
    <div className="scope-navbar fixed primary-background">
      <ol className="map-navigation__breadcrumb map-navigation__breadcrumb--inverse">
        {isPublication ? (
          <li className="map-navigation__breadcrumb__item">
            <Link to="/pages/publications">{t("header.publications")}</Link>
          </li>
        ) : (
          <Fragment>
            <li className="map-navigation__breadcrumb__item">
              <Link to="/pages/publications">{t("header.publications")}</Link>
            </li>
            <li className="map-navigation__breadcrumb__item">
              <span>
                <i className="fa fa-keyboard-arrow-right" />
              </span>
            </li>
            <li className="map-navigation__breadcrumb__item">
              <span>{title}</span>
            </li>
          </Fragment>
        )}
      </ol>
    </div>
  );
}

StaticPageBreadcrumb.propTypes = propTypes;

export default withRouter(withTranslation()(StaticPageBreadcrumb));
