import { GeoJSON } from "react-leaflet";
import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { orgUnitPath, redirectTo } from "../../lib/routerUtils";
import { entitiesLevelName } from "../../reducers/project";
import MarkerTooltip from "../Marker/MarkerTooltip";
import MapLegend from "./MapLegend";
import { isMobile } from "../../lib/uiUtils";
import indicatorUtils from "../../lib/indicatorUtils";
import _uniqBy from "lodash/uniqBy";

class GeoZone extends Component {
  geozoneStyle(geozone) {
    if (this.props.activeMapLayer === "choropleth") {
      if (geozone.choroplethValue === undefined) {
        return this.props.colors.noDataZones();
      }

      if (geozone.currentChoroplethIndicator.legendSets.length) {
        const colors = this.props.colors.legendColors(
          geozone.currentChoroplethIndicator,
          geozone.choroplethValue,
        );

        return colors;
      }
      return this.props.colors.colorScale(geozone.choroplethValue);
    }

    switch (this.props.variant) {
      case "inactive":
        return this.props.colors.inactiveZones();
      case "surrounding":
        return this.props.colors.surroundingZone();
      default:
        return this.props.colors.boundaries();
    }
  }

  choroplethActive() {
    return this.props.activeMapLayer === "choropleth";
  }

  groupsActive() {
    return this.props.activeMapLayer === "groups";
  }

  handleClick(geozone) {
    redirectTo(orgUnitPath(geozone));
  }

  handleZoneHover(e, styles) {
    e.target.setStyle(styles);
  }

  render() {
    const {
      geozone,
      style,
      inactive,
      clickable,
      details,
      t,
      currentChoroplethIndicator,
      colors,
    } = this.props;

    if (!geozone.geoJson) {
      return null;
    }

    return (
      <GeoJSON
        clickable={clickable}
        data={geozone.geoJson}
        style={this.geozoneStyle(geozone)}
        onClick={
          clickable
            ? () => {
                this.handleClick(geozone);
              }
            : undefined
        }
        onAdd={this.props.onAdd}
      >
        {!isMobile && (
          <MarkerTooltip orgUnit={geozone} sticky={true}>
            {this.props.entitiesLevelName && !!geozone.entitiesCount && (
              <Fragment>
                <div className="leaflet-tooltip__section">
                  <h6>{this.props.entitiesLevelName} - Total</h6>
                  {!inactive && geozone.entitiesCount}
                </div>
                {this.choroplethActive() && (
                  <div className="leaflet-tooltip__section">
                    <h6>{currentChoroplethIndicator.displayName}</h6>
                    {geozone.choroplethValue === undefined
                      ? t("noData.noDataAvailble")
                      : geozone.choroplethValue}
                  </div>
                )}
                {this.groupsActive() && <MapLegend items={details} />}
              </Fragment>
            )}
          </MarkerTooltip>
        )}
      </GeoJSON>
    );
  }
}

GeoZone.propTypes = {
  clickable: PropTypes.bool,
  entitiesLevelName: PropTypes.string,
  geozone: PropTypes.object,
  hoverStyle: PropTypes.func,
  inactive: PropTypes.bool,
  onAdd: PropTypes.func,
  style: PropTypes.func,
  variant: PropTypes.string,
};

const mapStateToProps = (state, props) => {
  const currentGroupSetId = state.map.currentGroupSetId;
  const activeGroups = state.project.displayedGroups.filter( g => {
    if (g.groupSet) {
      return g.groupSet.id === currentGroupSetId;
    } else {
      return g.groupSets.map(set => set.id).includes(currentGroupSetId);
    }
  });
  const activeGroupIds = activeGroups.map(g => g.id);
  const displayedActiveGroups = props.geozone.displayedGroups.filter(g => activeGroupIds.includes(g.id))
  const groups=_uniqBy(displayedActiveGroups, g => g.id)
  //groups.sort((a, b) => a.name.localeCompare(b.name));

  const details = groups.map(group => ({
    ...group,
    name: `${group.name} (${group.entitiesCount})`,
  }));
  const choro = props.activeMapLayer === "choropleth";
  return {
    geozone: choro
      ? indicatorUtils.withChoroplethValues(
          state,
          props.geozone,
          props.currentChoroplethIndicator,
        )
      : props.geozone,
    details,
    entitiesLevelName: entitiesLevelName(
      state.project.levels,
      state.entities.level,
    ),
  };
};
export default connect(mapStateToProps)(withTranslation()(GeoZone));
