/**
 * Geozones
 */
export const RECEIVE_GEOZONES = "RECEIVE_GEOZONES";
export const RECEIVE_ENTITIES = "RECEIVE_ENTITIES";
export const UPDATE_CURRENT_ENTITY = "UPDATE_CURRENT_ENTITY";
export const UPDATE_CURRENT_ZONE = "UPDATE_CURRENT_ZONE";
export const SET_ENTITIES_LEVEL = "SET_ENTITIES_LEVEL";
export const HYDRATE_ENTITIES = "HYDRATE_ENTITIES";
/**
 * Inactive geozones
 */
export const RECEIVE_INACTIVE_GEOZONES = "RECEIVE_INACTIVE_GEOZONES";

/**
 * Map
 */
export const UPDATE_SELECTED_CHOROPLETH = "UPDATE_SELECTED_CHORqOPLETH";
export const REQUEST_CHOROPLETH_INDICATORS_VALUES =
  "REQUEST_CHOROPLETH_INDICATORS_VALUES";
export const RECEIVE_CHOROPLETH_INDICATORS_VALUES =
  "RECEIVE_CHOROPLETH_INDICATORS_VALUES";
export const RECEIVE_CHOROPLETH_INDICATORS = "RECEIVE_CHOROPLETH_INDICATORS";
export const UPDATE_SELECTED_GROUP_SET = "UPDATE_SELECTED_GROUP_SET";

/**
 *  Top entities
 */
export const RECEIVE_TOP_ENTITIES = "RECEIVE_TOP_ENTITIES";

/**
 *  Section & Data elements
 */
export const RECEIVE_DATA_ELEMENTS = "RECEIVE_DATA_ELEMENTS";
export const REQUEST_DATA_ELEMENTS = "REQUEST_DATA_ELEMENTS";
export const SET_SECTIONS_LOADED = "SET_SECTIONS_LOADED";

/**
 *  Period
 */
export const RECEIVE_PERIODS = "RECEIVE_PERIODS";
export const UPDATE_FROM_PERIOD = "UPDATE_FROM_PERIOD";
export const UPDATE_TO_PERIOD = "UPDATE_TO_PERIOD";

/**
 *  Bootstrap data
 */
export const LOAD_BOOTSTRAP_DATA = "LOAD_BOOTSTRAP_DATA";
/**
 *  Performance libs
 */
export const TOGGLE_PERFORMANCE_LIBS = "TOGGLE_PERFORMANCE_LIBS";

/**
 *  Pages
 */

export const REQUEST_PUBLICATIONS = "REQUEST_PUBLICATIONS";
export const RECEIVE_PUBLICATIONS = "RECEIVE_PUBLICATIONS";

export const REQUEST_VALUES = "REQUEST_VALUES";
export const RECEIVE_VALUES = "RECEIVE_VALUES";

/**
 *  Ui
 */

export const TOGGLE_SEARCH = "TOGGLE_SEARCH";

export const FILTER_TOGGLE = "FILTER_TOGGLE";
export const FILTER_SET = "FILTER_SET";

export const SET_SCROLL_SPY = "SET_SCROLL_SPY";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_CURRENT_ORGUNIT_LEGEND = "SET_CURRENT_ORGUNIT_LEGEND";
export const SET_MAP_LAYERS = "SET_MAP_LAYERS";
export const SET_MAP_GROUPS = "SET_MAP_GROUPS";
export const TOGGLE_LEGEND = "TOGGLE_LEGEND";
export const SET_LEGEND_HEIGHT = "SET_LEGEND_HEIGHT";
