import * as types from "../constants/actions-type";

function publications(
  state = {
    isFetching: false,
    items: [],
  },
  action,
) {
  switch (action.type) {
    case types.REQUEST_PUBLICATIONS:
      return Object.assign({}, state, {
        isFetching: true,
        items: [],
      });
    case types.RECEIVE_PUBLICATIONS:
      return Object.assign({}, state, {
        isFetching: false,
        items: action.publications,
      });
    default:
      return state;
  }
}

export default publications;
