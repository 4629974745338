import _values from "lodash/values";
import { getEntity } from "./entities";
import { getZone } from "./zones";

export const getOrgUnit = (state, orgUnitId, type) => {
  if (type) {
    return type === "zone"
      ? getZone(state.zones, orgUnitId)
      : getEntity(state.entities, orgUnitId);
  }

  return (
    getZone(state.zones, orgUnitId) || getEntity(state.entities, orgUnitId)
  );
};

export const siblingOrgUnits = (state, orgUnitId) => {
  const orgUnit = getOrgUnit(state, orgUnitId);
  const { level, parentId, id } = orgUnit;
  const siblingsHash =
    orgUnit.type === "zone" ? state.zones.byId : state.entities.byId;

  return _values(siblingsHash).filter(
    siblingOrgUnit =>
      siblingOrgUnit.level === level &&
      siblingOrgUnit.parentId === parentId &&
      siblingOrgUnit.id !== id,
  );
};
