import * as types from "../constants/actions-type";

export const getAllPeriods = (periods, min, max) =>
  periods.filter(period => period >= min && period <= max);

export const currentPeriodMin = periodsState => periodsState.selected[0];
export const currentPeriodMax = periodsState =>
  periodsState.selected[periodsState.selected.length - 1];

const period = (state = {}, action) => {
  switch (action.type) {
    case types.RECEIVE_PERIODS:
      return {
        ...state,
        all: action.periods,
        selected: action.periods,
      };
    case types.UPDATE_FROM_PERIOD:
      return {
        ...state,
        selected: getAllPeriods(
          state.all,
          action.period,
          currentPeriodMax(state),
        ),
      };
    case types.UPDATE_TO_PERIOD:
      return {
        ...state,
        selected: getAllPeriods(
          state.all,
          currentPeriodMin(state),
          action.period,
        ),
      };
    default:
      return state;
  }
};

export default period;
