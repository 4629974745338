import _isEmpty from "lodash/isEmpty";
import axios from "axios";
import * as types from "../constants/actions-type";

import {
  receiveChoroplethIndicatorsValues,
  requestChoroplethIndicatorsValues,
} from "./map";

import indicatorUtils from "../lib/indicatorUtils";
import { receiveTopEntities } from "./topEntities";

const requestDataElements = () => ({
  type: types.REQUEST_DATA_ELEMENTS,
});

const receiveDataElements = (
  byId,
  byQueryId,
  bySectionId,
  sections,
  orgUnitId,
) => ({
  type: types.RECEIVE_DATA_ELEMENTS,
  byId,
  byQueryId,
  bySectionId,
  sections,
  orgUnitId,
});

export const fetchSections = (project, orgUnitId) => dispatch => {
  dispatch(requestDataElements());
  return axios
    .get(
      `${process.env.FRONTOFFICE_MANAGER_URL}/projects/${project.id}/sections?org_unit_id=${orgUnitId}`,
    )
    .then(response => {
      dispatch(receiveTopEntities(response.data));

      return dispatch(
        receiveDataElements(
          ...indicatorUtils.normalizeIndicators(project, response.data),
          orgUnitId,
        ),
      );
    });
};

export const fetchChoroplethValues = orgUnitId => (dispatch, getState) => {
  const { project } = getState();
  if (project.map.choroplethIndicators.length) {
    dispatch(requestChoroplethIndicatorsValues());
    return indicatorUtils
      .fetchDataElementValues({
        projectId: project.id,
        orgUnitId,
      })
      .then(response =>
        dispatch(
          receiveChoroplethIndicatorsValues(
            indicatorUtils.sortDataByOrgUnit(response.data),
          ),
        ),
      );
  }
};
