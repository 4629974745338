export default [
  "www.cartesanitairerdc.org",
  "dps.ihp-prosani.com",
  "uva.bluesquare.org",
  "demo.fp.beninfbr.org",
  "frontoffice.drc-pdss.bluesquare.org",
  "portal.fmoh-hsrhc.ng",
  "www.beninfbr.org",
  "www.rbf-ug.org",
  "portal.memisa-dhis2.org",
  "www.paquefbp.org",
  "dashboard.ihp-prosani.com",
  "www.fbrsante-sn.org",
  "www.fbp-rdc.org",
  "unicef-ecoles.bluesquare.org",
  "vih.beninfbr.org",
  "frontoffice.ml.bluesquare.org",
  "www.liberiapbf.org",
  "www.cartesanitaire-benin.org",
  "carte.snis-centrafrique.org",
  "www.fbp-msp.org",
  "www.mfl-nigeria.org",
  "dataviz.zimrbf.org",
  "www.praased.org",
  "www.fbr-rca.com",
  "www.fbpcotedivoire.org",
  "hiv.drc.hivdr-initiative.org",
  "santedesfemmes.cartesanitaire-sn.org",
  "plan-rca.dataviz.bluesquare.org",
  "www.caritas-digitalisation.be",
  "front.rbf-ng.org",
  "front.fbrcameroun.org",
  "www.pass-sourou.org",
  "immu.beninfbr.org",
  "dataviz-demo.bluesquare.org",
];
