import React from "react";
import "../../../css/drawer.scss";
import PropTypes from "prop-types";
import classNames from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import MenuItem from "./MenuItem";

function Drawer({
  open,
  menuItems,
  handleClickOutside,
  logoUrl,
  hasPublications,
}) {
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        handleClickOutside();
      }}
    >
      <div className={classNames("drawer", { "drawer--open": open })}>
        <img src={logoUrl} aria-hidden="true" className="drawer__logo" />
        <ul className="drawer__list">
          <MenuItem i18nKey="header.home" title="Home" url="/" />
          <MenuItem i18nKey="header.data" title="Data" url="/data" />
          {hasPublications && (
            <MenuItem
              i18nKey="header.publications"
              title="Publications"
              url="/pages/publications"
            />
          )}
          {menuItems.map((item, index) => (
            <MenuItem key={index} {...item} />
          ))}
        </ul>
      </div>
    </OutsideClickHandler>
  );
}

Drawer.propTypes = {
  open: PropTypes.bool,
  handleClickOutside: PropTypes.func,
  menuItems: PropTypes.arrayOf(PropTypes.object),
  hasPublications: PropTypes.bool,
  logoUrl: PropTypes.string,
};

export default Drawer;
