/* globals window, document */

import L from "leaflet";
import { formatDistance } from "date-fns";
import { fr } from "date-fns/locale";

export const formattedName = name =>
  name ? name.charAt(0).toUpperCase() + name.slice(1) : "";

export const formatDate = (value, currentLocale) =>
  formatDistance(new Date(value), new Date(), {
    locale: currentLocale === "en" ? undefined : fr,
    addSuffix: true,
  });

export const isMobile = L.Browser.mobile;
export const getName = resource =>
  resource
    ? resource.alternateName ||
      resource.name ||
      resource.displayShortName ||
      resource.displayName
    : "";

export const scrollToTop = () => {
  if (window.pageYOffset > 0) {
    document.body.scrollIntoView({ behavior: "smooth" });
  }
};
