/* global window */

import "../../css/body.scss";

import React, { Component, Fragment } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { parse } from "query-string";
import AppContainer from "../containers/AppContainer";
import StaticPage from "./StaticPage";
import { fetchProject } from "../actions/project";
import Homepage from "./Homepage";
import AppMainColors from "./AppMainColors";

class AppWrapper extends Component {
  componentDidMount() {
    const testDomain = parse(this.props.location.search).domain;
    this.props.fetchProject(
      testDomain || process.env.PROJECT_DOMAIN || window.location.hostname,
    );
  }

  render() {
    if (this.props.isFetching) {
      return null;
    }

    return (
      <Fragment>
        <AppMainColors />
        <Switch>
          <Redirect from={`/entity/:id`} to={`/data/entity/:id`} />
          <Redirect from={`/zone/:id`} to={`/data/zone/:id`} />
          <Route exact path="/" component={Homepage} />
          <Route path={`/pages`} component={StaticPage} />
          <Route exact path={`/data`} component={AppContainer} />
          <Route path={`/data/:type/:id`} component={AppContainer} />
        </Switch>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { isFetching } = state.project;
  return {
    isFetching,
  };
}

AppWrapper.propTypes = {
  children: PropTypes.object,
  fetchProject: PropTypes.func,
  isFetching: PropTypes.bool,
  location: PropTypes.object,
  pageTitle: PropTypes.string,
};

export default withRouter(
  connect(mapStateToProps, { fetchProject })(AppWrapper),
);
