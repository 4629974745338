import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactModal from "react-modal";
import SwitchButton from "../SwitchButton";

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  acceptCookies: PropTypes.func.isRequired,
};

class CookiesModal extends Component {
  constructor() {
    super();
    ReactModal.setAppElement("#app");
    this.state = {
      isPerformanceCookieGranted: true,
    };
  }

  toggleGrantPerfCookie() {
    this.setState({
      isPerformanceCookieGranted: !this.state.isPerformanceCookieGranted,
    });
  }

  render() {
    const { isOpen, toggleModal, acceptCookies, t } = this.props;
    const { isPerformanceCookieGranted } = this.state;
    return (
      <ReactModal
        className="dv-modal raised-box"
        overlayClassName="dv-overlay"
        isOpen={isOpen}
        shouldCloseOnOverlayClick
        onRequestClose={() => toggleModal()}
      >
        <button
          className="cookies-close btn btn-sm"
          onClick={() => toggleModal()}
        >
          <i className="fa fa-close" />
        </button>
        <div className="dv-modal__section">
          <h3>{t("cookies.modaleTitle")}</h3>
          <p>{t("cookies.modaleMainText")}</p>
        </div>
        <div className="dv-modal__section">
          <h4>{t("cookies.modalePlainCookieTitle")}</h4>
          <div className="cookies-infos primary-color">
            {t("cookies.alwaysActive")}
          </div>
          <p>{t("cookies.modalePlainCookieText")}</p>
        </div>
        <div className="dv-modal__section">
          <h4>{t("cookies.modalePerformanceTitle")}</h4>
          <div
            className={`cookies-infos ${
              isPerformanceCookieGranted ? "primary-color" : ""
            }`}
          >
            {isPerformanceCookieGranted
              ? t("cookies.activated")
              : t("cookies.deactivated")}
            <SwitchButton
              isActive={isPerformanceCookieGranted}
              onChange={() => this.toggleGrantPerfCookie()}
            />
          </div>
          {t("cookies.modalePerfomanceText")}
        </div>
        <div className="cookies-banner-actions">
          <button
            className="btn btn-sm btn-primary primary-background primary-border-color"
            onClick={() => acceptCookies(isPerformanceCookieGranted)}
          >
            {t("cookies.validate")}
          </button>
        </div>
      </ReactModal>
    );
  }
}

CookiesModal.propTypes = propTypes;

export default withTranslation()(CookiesModal);
