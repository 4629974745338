import "../../../css/publication-summary.scss";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import { getOrgUnit } from "../../reducers/orgUnits";

const entityPhotoPlaceholder = require("../../../img/topography-dark.png");

const PublicationSummary = ({
  title,
  url,
  date,
  orgUnit,
  attachmentUrl,
  backgroundImageUrl,
  excerpt,
  t,
}) => (
  <div className="publication-summary">
    <div className="row">
      <div
        className={`col-xs-12 col-lg-5${
          !backgroundImageUrl ? " publication-img-placeholder" : ""
        }`}
      >
        <img
          className="publication-image"
          alt={title}
          src={backgroundImageUrl || entityPhotoPlaceholder}
        />
      </div>
      <div className="col-xs-12 col-lg-7">
        <h4 className="publication-summary__header">{title}</h4>
        <div className="publication-summary__header__meta">
          {date}
          {attachmentUrl && (
            <span>
              {" - "} <i className="fa fa-insert-drive-file icon--left" />
            </span>
          )}
          {orgUnit && (
            <span>
              {" - "} <i className="pill icon--left">{orgUnit.name}</i>
            </span>
          )}
        </div>
        {excerpt !== "" && <p>{excerpt}</p>}
        {excerpt === "" && <p>{t("publications.noContent")}</p>}
      </div>
    </div>
    <Link className="publication-link" to={url}>
      {t("publications.more")}
    </Link>
  </div>
);

const mapStateToProps = (state, props) => ({
  orgUnit: getOrgUnit(state, props.orgUnitId),
});

PublicationSummary.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  date: PropTypes.string,
  orgUnit: PropTypes.object,
  attachmentUrl: PropTypes.string,
  backgroundImageUrl: PropTypes.string,
  excerpt: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation()(connect(mapStateToProps)(PublicationSummary));
