/* globals window */
import "../../css/scope-navbar.scss";

import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import Scrollchor from "react-scrollchor";

import scrollSpy from "../lib/scrollSpy";
import { parametrize } from "../lib/routerUtils";

const scrolls = {};

const addScroll = (key, ref) => {
  scrolls[key] = ref;
};

const preFix = "#section-";

class ScopeNavbar extends Component {
  constructor() {
    super();
    this.state = {
      hasScrolled: false,
    };
  }

  componentWillMount() {
    scrollSpy.init();
  }

  componentDidUpdate() {
    if (
      window.location.hash &&
      !this.state.hasScrolled &&
      !this.props.isFetchingSections
    ) {
      const scrollId = window.location.hash.replace(preFix, "");
      if (scrolls[scrollId]) {
        this.setState({
          hasScrolled: true,
        });
        setTimeout(() => {
          scrolls[scrollId].simulateClick();
        }, 300);
      }
    }
  }

  componentWillUnmount() {
    scrollSpy.reset();
  }

  render() {
    const {
      tabs,
      activeTab,
      afterAnimateScroll,
      beforeAnimateScroll,
    } = this.props;
    if (tabs.length === 1) return null;
    return (
      <div
        className={classNames("scope-navbar primary-background", {
          fixed: true,
        })}
      >
        {tabs.map(tab => {
          const tabName = parametrize(tab.name);
          return (
            <Scrollchor
              ref={ref => addScroll(tabName, ref)}
              to={`${preFix}${tabName}`}
              className={classNames("tab", {
                active: tabName === activeTab,
              })}
              animate={{ offset: -110, duration: 500 }}
              beforeAnimate={() => beforeAnimateScroll(tabName)}
              afterAnimate={() => afterAnimateScroll()}
              key={tab.name}
            >
              {tab.name}
            </Scrollchor>
          );
        })}
      </div>
    );
  }
}

ScopeNavbar.propTypes = {
  title: PropTypes.string,
  fixed: PropTypes.bool,
  tabs: PropTypes.arrayOf(PropTypes.object),
  activeTab: PropTypes.string,
  beforeAnimateScroll: PropTypes.func,
  afterAnimateScroll: PropTypes.func,
  sectionsLoaded: PropTypes.bool,
  isFetchingSections: PropTypes.bool,
};

const mapStateToProps = state => ({
  sectionsLoaded: state.sections.sectionsLoaded,
});

export default connect(mapStateToProps)(ScopeNavbar);
