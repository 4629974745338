import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import ExportButton from "../containers/ExportButton";
import Header from "./Header/Header";
import OrgUnitAttributesList from "./OrgUnit/OrgUnitAttributesList";
import OrgunitSummary from "./OrgUnit/OrgunitSummary";
import ScopeNavbar from "../containers/ScopeNavbar";
import SectionsList from "./Graphs/SectionsList";
import { setActiveTab, setScrollSpy } from "../actions/ui";
import { sectionsByType, orgUnitSections } from "../reducers/sections";
import SeparatorSection from "./Sections/SeparatorSection";
import PaneSwitch from "./PaneSwitch";

class ContentPane extends Component {
  beforeAnimateScroll = name => {
    this.props.setScrollSpy(false);
    this.props.setActiveTab(name);
  };

  afterAnimateScroll = () => {
    this.props.setScrollSpy(true);
  };

  render() {
    const {
      activeEntity,
      currentZone,
      active,
      sections,
      currentOrgUnit,
      tabs,
      activeTab,
      isFetchingSections,
    } = this.props;

    const viewableSections = sections.filter(s => !!!s.hidden);
    const noTabs = tabs.length <= 1;
    return (
      <div
        id="content-pane"
        className={classNames("content-pane", {
          "content-pane--active": active,
          "content-pane--no-tabs": noTabs,
        })}
      >
        {currentOrgUnit && (
          <Fragment>
            <Header
              currentZone={currentZone}
              activeEntity={activeEntity}
              noTabs={noTabs}
            />
            <ScopeNavbar
              tabs={tabs}
              activeTab={activeTab}
              beforeAnimateScroll={this.beforeAnimateScroll}
              afterAnimateScroll={this.afterAnimateScroll}
              isFetchingSections={isFetchingSections}
            />
            {tabs.length > 1 && (
              <SeparatorSection section={{ name: "info" }} hidden />
            )}
            <section>
              <OrgunitSummary orgUnit={currentOrgUnit} />
              <OrgUnitAttributesList entity={activeEntity || currentZone} />
            </section>
          </Fragment>
        )}
        <SectionsList
          currentOrgUnit={currentOrgUnit}
          currentZone={currentZone}
          sections={viewableSections}
        />
        <ExportButton split />
        <PaneSwitch switchPane={this.props.switchPane} fixed />
      </div>
    );
  }
}

ContentPane.propTypes = {
  active: PropTypes.bool,
  activeEntity: PropTypes.object,
  activeTab: PropTypes.string,
  className: PropTypes.string,
  currentOrgUnit: PropTypes.object,
  currentZone: PropTypes.object,
  isFetchingSections: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.object),
  setActiveTab: PropTypes.func,
  setScrollSpy: PropTypes.func,
  switchPane: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps(state, props) {
  const {
    ui: { activeTab },
  } = state;
  const currentOrgUnit = props.activeEntity || props.currentZone;
  const tabs = sectionsByType(state.sections, "separator");

  tabs.unshift({
    name: "info",
  });
  return {
    sections: orgUnitSections(state.sections, currentOrgUnit),
    currentOrgUnit,
    tabs,
    activeTab,
    isFetchingSections: state.dataElements.isFetching,
  };
}

export default connect(mapStateToProps, { setActiveTab, setScrollSpy })(
  ContentPane,
);
