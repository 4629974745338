/* global document */

import "../../../css/cookiesManager.scss";
import "../../../css/modal.scss";

import PropTypes, { instanceOf } from "prop-types";
import { withCookies, Cookies } from "react-cookie";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

import CookiesModal from "./CookiesModal";
import CookiesBanner from "./CookiesBanner";
import { launchPerformanceLibs } from "../../lib/performance";
import { togglePerformanceLibs } from "../../actions/project";

class CookiesManager extends Component {
  constructor() {
    super();
    this.state = {
      isBannerVisible: false,
      isModalVisible: false,
    };
  }

  componentDidMount() {
    const { cookies, performanceLibActive } = this.props;
    const grantedCookies = cookies.get("grantedCookies");
    const grantedPerformanceCookies = cookies.get("grantedPerformanceCookies");
    this.setState({
      isBannerVisible:
        grantedCookies === undefined && grantedPerformanceCookies === undefined,
      isModalVisible: false,
    });
    if (
      grantedCookies === "true" &&
      grantedPerformanceCookies === "true" &&
      !performanceLibActive
    ) {
      this.props.togglePerformanceLibs();
      launchPerformanceLibs();
    }
  }

  toggleModal() {
    document.body.style.overflow = !this.state.isModalVisible
      ? "hidden"
      : "auto";
    this.setState({
      isModalVisible: !this.state.isModalVisible,
    });
  }

  acceptCookies(isPerfAccepted) {
    const { cookies } = this.props;
    cookies.set("grantedCookies", true, { path: "/" });
    cookies.set("grantedPerformanceCookies", isPerfAccepted, {
      path: "/",
    });
    this.setState({
      isBannerVisible: false,
      isModalVisible: false,
    });
    if (isPerfAccepted) {
      this.props.togglePerformanceLibs();
      launchPerformanceLibs();
    }
  }

  render() {
    const { isBannerVisible, isModalVisible } = this.state;
    return (
      <div className="cookies-container">
        {isBannerVisible && !isModalVisible && (
          <CookiesBanner
            toggleModal={() => this.toggleModal()}
            acceptCookies={() => this.acceptCookies(true)}
          />
        )}
        {
          <CookiesModal
            isOpen={isModalVisible}
            toggleModal={() => this.toggleModal()}
            acceptCookies={isPerformanceCookieGranted =>
              this.acceptCookies(isPerformanceCookieGranted)
            }
          />
        }
      </div>
    );
  }
}
CookiesManager.propTypes = {
  isOpen: PropTypes.bool,
  cookies: instanceOf(Cookies).isRequired,
  performanceLibActive: PropTypes.bool,
  togglePerformanceLibs: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  performanceLibActive: state.project.performanceLibActive,
});

export default withTranslation()(
  withCookies(
    connect(mapStateToProps, { togglePerformanceLibs })(CookiesManager),
  ),
);
