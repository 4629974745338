import { stringify } from "query-string";
import React from "react";
import _keyBy from "lodash/keyBy";
import _sortBy from "lodash/sortBy";
import _map from "lodash/map";
import _compact from "lodash/compact";
import axios from "axios";
import classNames from "classnames";
import { format } from "d3-format";
import { formattedPeriod } from "./periodsUtils";
import i18n from "../i18n/i18n";
import store from "../store/store";
import { queriesFromIndicators } from "./sectionUtils";
import { getName, formatDate } from "./uiUtils";
import { getIndicatorType } from "../reducers/dataElements";
import { currentPeriodMax } from "../reducers/period";

function generateData(orgUnitId, data, serieId) {
  return data.map(dataItem => ({
    orgUnitId,
    x: formattedPeriod(dataItem.period),
    y: dataItem.value,
    missingData: !dataItem.value,
    serieId,
  }));
}

function indicatorEnhancer(project, fullIndicator) {
  const attributeValues = fullIndicator.attributeValues || [];
  const iconFromAttr = attributeValues.find(
    av => av.attribute.id === project.iconsAttributeId,
  );

  const indicatorType = getIndicatorType(
    project.indicatorTypes,
    fullIndicator.indicatorType,
  );

  const valueType =
    fullIndicator.alternateValueType ||
    (indicatorType || {}).type ||
    fullIndicator.valueType;

  return {
    ...fullIndicator,
    icon: iconFromAttr && iconFromAttr.value,
    valueType,
  };
}

function normalizeIndicators(project, responseIndicators) {
  let byId = {};
  const sections = [];
  const byQueryId = {};
  const bySectionId = {};

  responseIndicators.forEach(section => {
    const { items } = section;

    const cleanedItems = items.map(item => {
      const newItem = { ...item };
      delete newItem.query;
      return indicatorEnhancer(project, newItem);
    });

    const queries = queriesFromIndicators(section.items);

    // Fill bySectionId
    bySectionId[section.id] = _compact(_map(cleanedItems, "id"));

    // Fill byQueryId
    queries.forEach(query => {
      byQueryId[query.id] = query.itemsIds;
      delete query.itemsIds;
    });

    // Assign queries to section
    section.queries = queries;

    delete section.items;
    sections.push(section);

    // Fill byId
    byId = { ...byId, ..._keyBy(cleanedItems, "id") };
  });
  return [byId, byQueryId, bySectionId, sections];
}

function fetchDataElementValues(options) {
  const { projectId, ...rest } = options;
  const queryParams = stringify(rest);
  const url = `${process.env.FRONTOFFICE_MANAGER_URL}/projects/${projectId}/values?${queryParams}`;

  return axios.get(url);
}

const pill = value => {
  const isObject = typeof value === "object";
  const pillColor = (value || {}).color;
  const name = isObject ? value.name : value;
  const styles = pillColor
    ? { backgroundColor: pillColor, color: "white" }
    : undefined;

  return (
    <div className="pill" key={name} style={styles}>
      {name}
    </div>
  );
};

const formatPills = pills => (
  <div className="pills">{pills.map(val => pill(val))}</div>
);

const formatOptionSetOption = (value, optionSetOptions) => {
  const option = optionSetOptions.find(
    optionSetOption => parseInt(optionSetOption.code, 10) === value,
  );
  return (option || {}).name;
};

export const formatBooleanValue = (value, inverted) => {
  const isOne = parseInt(value, 10) === 1;
  const isZero = parseInt(value, 10) === 0;

  return (
    <i
      className={classNames(
        "fa indicator-boolean icon--left",
        {
          "fa-done primary-background indicator-boolean--true":
            isOne && !inverted,
        },
        {
          "fa-done primary-background indicator-boolean--true":
            isZero && inverted,
        },
        {
          "fa-close secondary-background indicator-boolean--false":
            isZero && !inverted,
        },
        {
          "fa-close secondary-background indicator-boolean--false":
            isOne && inverted,
        },
      )}
    />
  );
};

export const valueFactory = (valueType, value, optionSetOptions = []) => {
  switch (valueType) {
    case "DATE":
      return formatDate(value);
    case "ARRAY":
      return formatPills(value);
    case "LONG_TEXT":
      return value;
    case "OPTION":
      return formatOptionSetOption(value, optionSetOptions);
    case "TEXT":
      return value;
    case "PERCENTAGE":
      return i18n.t("formats.percentage", { value: format(",.2d")(value) });
    case "BOOLEAN":
      return value > 1 ? format(",.2d")(value) : null;
    case "INVERTED_BOOLEAN":
      return value > 1 ? format(",.2d")(value) : null;
    case "MONEY":
      return i18n.t("formats.money", {
        value: format(",.2~f")(value),
        currency: store.getState().project.currencyUnit,
      });
    default:
      // Numbers default to 2 decimals unless they're trealing zeroes in
      // which case they are ignored.
      //
      //       0.02456 => 0.02
      //       123456  => 123,456
      //       123456,7=> 123,456.7
      //
      return format(",.2~f")(value);
  }
};

const choroValue = (state, orgUnit, currentChoroplethIndicator) => {
  const {
    period,
    map: { choroplethValuesById },
  } = state;
  const max = currentPeriodMax(period);

  const indicator = (choroplethValuesById[orgUnit.id] || []).find(
    i => i.id === currentChoroplethIndicator.id,
  );
  const data = indicator && generateData(orgUnit.id, indicator.data);

  const lastValue = (data || []).find(
    dataValue => dataValue.x === formattedPeriod(max),
  );

  return (lastValue || {}).y;
};

const withChoroplethValues = (state, orgUnit, currentChoroplethIndicator) => ({
  ...orgUnit,
  choroplethValue: choroValue(state, orgUnit, currentChoroplethIndicator),
  choroplethName: getName(currentChoroplethIndicator),
  currentChoroplethIndicator,
});

const sortDataByOrgUnit = data => {
  const dataByOrgUnit = _keyBy(data, "org_unit_id");

  return Object.keys(dataByOrgUnit).reduce((accumulator, id) => {
    const dataForCurrentOrgUnit = dataByOrgUnit[id]
      ? { [id]: dataByOrgUnit[id].data_element_values }
      : {};

    return {
      ...accumulator,
      ...dataForCurrentOrgUnit,
    };
  }, {});
};

export const findLastAvailableValues = data => {
  // Sorts based on ascending per year then month
  const sortedByData = _sortBy(data, e => {
    const [month, year] = e.x.split("/").map(el => parseInt(el));
    return year;
  }, e => {
    const [month, year] = e.x.split("/").map(el => parseInt(el));
    return month;
  });

  return sortedByData.reverse().find(periodData => !periodData.missingData);
};

export default {
  withChoroplethValues,
  normalizeIndicators,
  fetchDataElementValues,
  generateData,
  sortDataByOrgUnit,
};
