import PropTypes from "prop-types";
import React, { Component } from "react";

import GraphsSection from "../Sections/GraphsSection";
import KpiSection from "../Sections/KpiSection";
import PublicationsSection from "../Sections/PublicationsSection";
import SeparatorSection from "../Sections/SeparatorSection";
import TopEntitiesSection from "../TopEntities/TopEntitiesSection";
import KpiComparatorSection from "../Sections/KpiComparatorSection";
import withValues from "../../lib/withValues";

class SectionFactory extends Component {
  componentDidUpdate() {
    this.props.updateLoadSectionState(
      this.props.section.id,
      this.props.isFetching,
    );
  }

  render() {
    const { section, isFetching, dataByOrgUnitId } = this.props;
    switch (section.sectionType) {
      case "separator":
        return <SeparatorSection section={section} />;
      case "entity_info":
        return (
          <KpiSection
            section={section}
            isFetching={isFetching}
            dataByOrgUnitId={dataByOrgUnitId}
          />
        );
      case "key_indicators_comparator":
        return (
          <KpiComparatorSection
            section={section}
            isFetching={isFetching}
            dataByOrgUnitId={dataByOrgUnitId}
          />
        );
      case "key_indicators":
        return (
          <KpiSection
            section={section}
            isFetching={isFetching}
            dataByOrgUnitId={dataByOrgUnitId}
          />
        );
      case "entities_ranking":
        return (
          <TopEntitiesSection
            section={section}
            isFetching={isFetching}
            title={section.name}
            description={section.description}
          />
        );
      case "publications":
        return <PublicationsSection />;
      default:
        return (
          <GraphsSection
            section={section}
            isFetching={isFetching}
            dataByOrgUnitId={dataByOrgUnitId}
          />
        );
    }
  }
}

SectionFactory.propTypes = {
  section: PropTypes.object,
  updateLoadSectionState: PropTypes.func,
  isFetching: PropTypes.bool,
  dataByOrgUnitId: PropTypes.object,
};

export default withValues(SectionFactory);
