import React, { Fragment } from "react";

import PropTypes from "prop-types";
import _includes from "lodash/includes";
import _reject from "lodash/reject";
import _remove from "lodash/remove";
import { connect } from "react-redux";
import BreadcrumbMenu from "./BreadcrumbMenu";
import BreadCrumbItem from "./BreadCrumbItem";
import { getOrgUnit } from "../../reducers/orgUnits";

function BreadcrumbItemSeparator() {
  return (
    <li className="map-navigation__breadcrumb__item ">
      <span>
        <i className="fa fa-keyboard-arrow-right" />
      </span>
    </li>
  );
}

function BreadcrumbItemFactory({ items }) {
  const firstItem = items[0];
  const lastItem = items[items.length - 1];
  const dropDownItems = _reject(items, item =>
    _includes([firstItem.id, lastItem.id], item.id),
  );

  switch (items.length) {
    case 0:
      return null;
    case 1:
      return (
        <BreadCrumbItem
          key={firstItem.id}
          orgUnit={firstItem}
          home={true}
          interactive={false}
        />
      );
    case 2:
      return (
        <Fragment>
          <BreadCrumbItem
            key={firstItem.id}
            orgUnit={firstItem}
            home={true}
            interactive={true}
          />
          <BreadcrumbItemSeparator />
          <BreadCrumbItem
            key={lastItem.id}
            orgUnit={lastItem}
            interactive={false}
          />
        </Fragment>
      );
    default:
      return (
        <Fragment>
          <BreadCrumbItem
            key={firstItem.id}
            orgUnit={firstItem}
            home={true}
            interactive={true}
          />
          <BreadcrumbItemSeparator />
          <li className="map-navigation__breadcrumb__item">
            <BreadcrumbMenu items={dropDownItems} />
          </li>
          <BreadcrumbItemSeparator />
          <BreadCrumbItem
            key={lastItem.id}
            orgUnit={lastItem}
            index={items.length}
            interactive={false}
          />
        </Fragment>
      );
  }
}

function Breadcrumb(props) {
  const { itemNodes } = props;

  return (
    <ol className="map-navigation__breadcrumb raised-box">
      <BreadcrumbItemFactory items={itemNodes} />
    </ol>
  );
}

const breadcrumbItems = (state, currentZone, activeEntity) => {
  const geozone = activeEntity ? activeEntity.path : currentZone.path;
  return geozone
    .split("/")
    .map(orgUnitId => getOrgUnit(state, orgUnitId))
    .filter(orgUnit => orgUnit);
};

const mapStateToProps = (state, props) => {
  const { currentZone, activeEntity } = props;

  return {
    itemNodes: breadcrumbItems(state, currentZone, activeEntity),
  };
};

Breadcrumb.propTypes = {
  mainZone: PropTypes.object,
  itemNodes: PropTypes.array,
  currentZone: PropTypes.object,
  activeEntity: PropTypes.object,
};

export default connect(mapStateToProps)(Breadcrumb);
