import React from "react";

import { CircleMarker } from "react-leaflet";
import PropTypes from "prop-types";
import _isEqual from "lodash/isEqual";
import _some from "lodash/some";
import _find from "lodash/find";
import EntityMarker from "../Marker/EntityMarker";
import { LAYERS } from "../../constants/ui";

class Markers extends React.Component {
  shouldComponentUpdate(nextProps) {
    return (
      !_isEqual(nextProps.entities, this.props.entities) ||
      !_isEqual(nextProps.displayBigMarkers, this.props.displayBigMarkers) ||
      !_isEqual(nextProps.colors, this.props.colors) ||
      !_isEqual(nextProps.mapColor, this.props.mapColor) ||
      !_isEqual(nextProps.activeEntity, this.props.activeEntity) ||
      !_isEqual(nextProps.activeFilter, this.props.activeFilter) ||
      !_isEqual(nextProps.activeMapLayer, this.props.activeMapLayer) ||
      !_isEqual(nextProps.activeMapGroup, this.props.activeMapGroup) ||
      !_isEqual(nextProps.activeGroups, this.props.activeGroups)
    );
  }

  choroplethActive() {
    return this.props.activeMapLayer === LAYERS.choropleth;
  }

  groupsActive() {
    return this.props.activeMapLayer === LAYERS.groups;
  }

  entityStyle(entity, activeGroups) {
    if (this.choroplethActive()) {
      if (entity.choroplethValue !== undefined) {
        if (entity.currentChoroplethIndicator.legendSets.length) {
          return this.props.colors.legendColors(
            entity.currentChoroplethIndicator,
            entity.choroplethValue,
          );
        }
        return this.props.colors.colorScale(entity.choroplethValue);
      }
      return this.props.colors.noDataZones();
    }

    return () => ({
      fillColor: this.colorForEntity(entity, activeGroups),
      color: this.colorForEntity(entity, activeGroups),
      weight: 1,
      opacity: 0.8,
      fillOpacity: entity.path.startsWith(this.props.currentZone.path)
        ? 0.8
        : 0.3,
      stroke: false,
    });
  }

  colorForEntity(entity, activeGroups) {
    if (entity.overRuledColor) {
      return entity.overRuledColor;
    }
    if (!this.groupsActive()) {
      return "#999";
    }
    if (this.props.activeMapGroup) {
      return (
        (
          _find(
            entity.displayedGroups,
            g => g.id == this.props.activeMapGroup,
          ) || {}
        ).color || "#666"
      );
    }
    if (activeGroups && activeGroups.length > 0) {
      const activeGroupIds = activeGroups.map(g => g.id);
      const groups = entity.displayedGroups.filter(g => activeGroupIds.includes(g.id));
      return (
        (_find(groups, g => !!g.color) || {}).color || "#666"
      )
    } else {
      return (
        (_find(entity.displayedGroups, g => !!g.color) || {}).color || "#666"
      );
    }
  }

  displayEntity(entity) {
    const group = _find(entity.displayedGroups, g => !!g.color);
    if (!entity.geoJson) return false;
    const position = entity.geoJson.geometry
      ? entity.geoJson.geometry.coordinates
      : entity.geoJson;

    if (position.length > 2 || !position[0] || !position[1]) {
      return null;
    }

    if (this.props.activeMapGroup) {
      return _some(
        entity.displayedGroups,
        g => g.id == this.props.activeMapGroup,
      );
    }
    return true;
  }

  render() {
    const {
      entities,
      displayBigMarkers,
      colors,
      mapColor,
      activeEntity,
      activeMapLayer,
      activeMapGroup,
      activeGroups,
      currentZone,
      section
    } = this.props;

    if (displayBigMarkers) {
      return [
        entities.map(entity =>
          this.displayEntity(entity) ? (
            <EntityMarker
              colors={colors}
              key={entity.id}
              entity={entity}
              big={displayBigMarkers}
              groupColor={this.colorForEntity(entity, activeGroups)}
              active={activeEntity && entity.id === activeEntity.id}
              activeMapLayer={activeMapLayer}
              section={section}
            />
          ) : null,
        ),
      ];
    }
    const onAdd = el => el.target.bringToBack();
    return [
      entities.map(entity =>
        this.displayEntity(entity) ? (
          <CircleMarker
            onAdd={el => onAdd(el)}
            key={`small-entity-${entity.id}`}
            radius={2}
            center={
              entity.geoJson.geometry
                ? entity.geoJson.geometry.coordinates
                : entity.geoJson
            }
            stroke={false}
            {...this.entityStyle(entity, activeGroups)()}
          />
        ) : null,
      ),
    ];
  }
}

Markers.propTypes = {
  activeEntity: PropTypes.object,
  entities: PropTypes.array.isRequired,
  displayBigMarkers: PropTypes.bool,
  colors: PropTypes.object,
  mapColor: PropTypes.string,
  activeMapLayer: PropTypes.string,
  activeMapGroup: PropTypes.string,
  activeFilter: PropTypes.string,
  section: PropTypes.string
};

export default Markers;
