import { fetchChoroplethValues, fetchSections } from "./dataElements";
import { isEntityParent } from "../reducers/zones";
import { hydrateEntitties, updateCurrentEntity } from "./entities";

import { fetchPublications } from "./publications";
import geoUtils from "../lib/geoUtils";
import { getOrgUnit } from "../reducers/orgUnits";
import { updateCurrentZone } from "./zones";

const shouldHydrateParent = (state, orgUnit) =>
  !state.entities.entitiesParentIds.find(
    parent => parent.id === orgUnit.parentId,
  ).hydrated;

const hydradeParent = orgUnitId => (dispatch, getState) =>
  geoUtils
    .fetchEntities(getState().project.id, null, orgUnitId)
    .then(({ data: entities }) => {
      dispatch(hydrateEntitties(entities, orgUnitId));
    });

const chooseOrgUnit = (type, state, orgUnitId) =>
  type && orgUnitId
    ? getOrgUnit(state, orgUnitId, type)
    : state.project.mainZone;

const getOrgUnitData = (type, orgUnitId) => (dispatch, getState) => {
  const state = getState();
  const orgUnit = chooseOrgUnit(type, state, orgUnitId);

  if (type === "entity") {
    if (shouldHydrateParent(state, orgUnit)) {
      dispatch(hydradeParent(orgUnit.parentId));
    }
    dispatch(updateCurrentEntity(orgUnit.id));
    dispatch(fetchChoroplethValues(orgUnit.parentId));
  } else {
    dispatch(updateCurrentZone(orgUnit.id));

    if (isEntityParent(state.entities.entitiesParentIds, orgUnit.id)) {
      dispatch(hydradeParent(orgUnit.id));
    }
    dispatch(fetchChoroplethValues(orgUnit.id));
  }
  dispatch(fetchSections(state.project, orgUnit.id));

  dispatch(fetchPublications(orgUnit.id));
};

export default getOrgUnitData;
