import * as types from "../constants/actions-type";

export const updateSelectedChoropleth = indicator => ({
  type: types.UPDATE_SELECTED_CHOROPLETH,
  indicator,
});

export const requestChoroplethIndicatorsValues = () => ({
  type: types.REQUEST_CHOROPLETH_INDICATORS_VALUES,
});

export const receiveChoroplethIndicatorsValues = data => ({
  type: types.RECEIVE_CHOROPLETH_INDICATORS_VALUES,
  data,
});

export const receiveChoroplethIndicators = indicators => ({
  type: types.RECEIVE_CHOROPLETH_INDICATORS,
  indicators,
});

export const updateSelectedGroupSet = groupSetId => ({
  type: types.UPDATE_SELECTED_GROUP_SET,
  groupSetId,
});
