import "../../../css/map-navigation.scss";

import React from "react";
import PropTypes from "prop-types";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

function MapNavigation(props) {
  return (
    <div className="map-navigation">
      {props.currentZone.level !== 1 && (
        <Breadcrumb
          currentZone={props.currentZone}
          activeEntity={props.activeEntity}
        />
      )}
    </div>
  );
}

MapNavigation.propTypes = {
  activeEntity: PropTypes.object,
  currentZone: PropTypes.object,
};

export default MapNavigation;
