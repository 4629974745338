import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { orgUnitPath } from "../../lib/routerUtils";

function SearchResultViewButton(props) {
  return (
    <Link to={orgUnitPath(props.orgUnit)} className={props.className}>
      <i className={`fa fa-visibility`} />
    </Link>
  );
}

SearchResultViewButton.propTypes = {
  orgUnit: PropTypes.object,
  className: PropTypes.string,
};

export default SearchResultViewButton;
