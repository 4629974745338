/* global window */
import "react-tippy/dist/tippy.css";

import React, { Component, Fragment } from "react";

import PropTypes from "prop-types";
import classNames from "classnames";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import Body from "./Body";
import ContentPane from "./ContentPane";
import MapPane from "./MapPane";
import { getCurrentEntity } from "../reducers/entities";
import { getCurrentZone } from "../reducers/zones";
import CookiesManager from "./Cookies/CookiesManager";
import LoadingSpinner from "./LoadingSpinner";

class App extends Component {
  state = {
    paneSize: null,
    mobileMapHidden: true,
  };

  switchPane = () => {
    this.setState(
      {
        mobileMapHidden: !this.state.mobileMapHidden,
      },
      () => {
        this.invalidateSize();
      },
    );
  };

  invalidateSize() {
    window.dispatchEvent(new Event("resize"));
  }

  render() {
    const { currentZone, activeEntity, searchActive } = this.props;
    if (!currentZone) {
      return (
        <div className="app-loader">
          <LoadingSpinner />
        </div>
      );
    }
    return (
      <Body
        className={classNames({
          backdrop: searchActive,
          "map-active": !this.state.mobileMapHidden,
        })}
      >
        <Fragment>
          <CookiesManager />

          {currentZone && (
            <ContentPane
              activeEntity={activeEntity}
              currentZone={currentZone}
              active={this.state.mobileMapHidden}
              switchPane={this.switchPane}
            />
          )}
          {currentZone && (
            <MapPane
              currentZone={currentZone}
              activeEntity={activeEntity}
              paneSize={this.state.paneSize}
              active={!this.state.mobileMapHidden}
              switchPane={this.switchPane}
            />
          )}
        </Fragment>
      </Body>
    );
  }
}

const mapStateToProps = state => {
  const activeEntity = getCurrentEntity(state.entities);
  const currentZone = getCurrentZone(state.zones, activeEntity);

  return {
    currentZone,
    activeEntity,
    searchActive: state.ui.searchActive,
  };
};

export default withTranslation()(connect(mapStateToProps)(App));

App.propTypes = {
  activeEntity: PropTypes.object,
  currentZone: PropTypes.object,
  hoveredZone: PropTypes.string,
  searchActive: PropTypes.bool,
};
