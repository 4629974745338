import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";

const propTypes = {
  css: PropTypes.string,
};

const AppMainColors = ({ css }) => <style>{css}</style>;

const generateCSS = colors =>
  Object.keys(colors).reduce((acc, colorKey) => {
    const styles = [
      `.${colorKey}-color { color: ${colors[colorKey]} !important;}`,
      `.${colorKey}-border-color { border-color: ${colors[colorKey]} !important;}`,
      `.${colorKey}-background { background-color: ${colors[colorKey]} !important;} `,
      `.${colorKey}-progress .CircularProgressbar-path { stroke: ${colors[colorKey]} !important; }`,
      `.${colorKey}-marker svg path {
        fill: ${colors[colorKey]} !important; }`,
    ];
    if (colorKey === "secondary") {
      styles.push(`a { color: ${colors[colorKey]};}`);
    }
    return acc + styles.join(" ");
  }, "");

const mapStateToProps = state => ({
  css: generateCSS(state.project.cssPalette),
});

AppMainColors.propTypes = propTypes;

export default connect(mapStateToProps)(AppMainColors);
