import React from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { setMapGroups } from "../../actions/ui";
import { LAYERS } from "../../constants/ui";

function MapLegend(props) {
  const { t } = useTranslation();

  const toggleMapGroups = mapGroup => {
    props.toggleMapGroup(mapGroup.id);
  };

  const choroplethActive = () => props.activeMapLayer === LAYERS.choropleth;

  const groupsActive = () => props.activeMapLayer === LAYERS.groups;

  const legendEnabled = legendItem => {
    if (choroplethActive()) return true;
    if (!props.activeMapGroup) return true;

    return legendItem.id == props.activeMapGroup;
  };

  return (
    <div className="map-legend">
      <div key="legend-no-data" className="map-legend__item">
        <div
          className="map-legend__item__bg"
          style={{
            backgroundColor: "#999",
            opacity: 1,
          }}
        />
        <span className="map-legend__item__name">
          {t("noData.missingData")}
        </span>
      </div>
      {props.items.map((legendItem, index) => (
        <div
          key={`legend-${legendItem.name}-${index}`}
          className={classNames("map-legend__item", {
            "map-legend__item--disabled": !legendEnabled(legendItem),
          })}
        >
          <div
            className="map-legend__item__bg"
            onClick={() => toggleMapGroups(legendItem)}
            style={{
              backgroundColor: legendItem.color || "#666",
              opacity: legendItem.opacity || 0.5,
            }}
          />
          <span className="map-legend__item__name">{legendItem.name}</span>
        </div>
      ))}
    </div>
  );
}

MapLegend.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object),
  toggleMapGroups: PropTypes.func,
};

const mapStateToProps = state => ({
  mapGroups: state.ui.mapGroups,
});

export default connect(mapStateToProps, { setMapGroups })(MapLegend);
