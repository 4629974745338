import i18n from "../i18n/i18n";

export const formattedPeriod = period => {
  const isMonth = !!period.match(/^\d{6}\b/);
  const isQuarter = !!period.match(/Q/);

  if (isMonth) {
    return i18n.t("period.monthly", {
      month: period.slice(-2),
      year: period.slice(0, 4),
    });
  }

  if (isQuarter) {
    return i18n.t("period.quarterly", {
      quarter: period.slice(-1),
      year: period.slice(0, 4),
    });
  }

  return period;
};
