import _values from "lodash/values";
import { createSelector } from "reselect";
import * as types from "../constants/actions-type";

function zones(
  state = {
    byId: {},
    current: null,
    isFetching: true,
  },
  action,
) {
  switch (action.type) {
    case types.RECEIVE_GEOZONES:
      return {
        ...state,
        byId: action.zones,
        isFetching: false,
      };
    case types.UPDATE_CURRENT_ZONE:
      return {
        ...state,
        current: action.id,
      };
    default:
      return state;
  }
}

export default zones;

export const getZone = (orgUnits, id) => (id ? orgUnits.byId[id] : null);

const getSubZonesIds = (state, id) => {
  const geozone = getZone(state.zones, id);
  return (geozone || {}).childrenIds || [];
};

export const getCurrentZone = (orgUnits, activeEntity) => {
  let currentZone =
    getZone(
      orgUnits,
      activeEntity ? activeEntity.parentId : orgUnits.current,
    ) || null;

  if (currentZone) {
    if (activeEntity) {
      currentZone = {
        ...currentZone,
        activeEntityId: activeEntity.id,
      };
    } else {
      currentZone = {
        ...currentZone,
        activeEntityId: null,
      };
    }
  }

  return currentZone;
};

export const getCurrentOrgUnitId = state =>
  state.entities.current ? state.entities.current : state.zones.current;

export const getSubZones = (state, id) =>
  getSubZonesIds(state, id)
    .map(childId => getZone(state.zones, childId))
    .filter(entity => entity);

const entitiesbyGeozoneIdSelector = (entities, geozoneId) =>
  _values(entities.byId).filter(entity =>
    entity.path.split("/").includes(geozoneId),
  ) || [];

export const entitiesFor = createSelector(
  entitiesbyGeozoneIdSelector,
  entities => entities,
);

export const isEntityParent = (entitiesParentIds, zoneId) =>
  !!entitiesParentIds.find(parent => parent.id === zoneId);
