import {
  FlexibleXYPlot,
  YAxis,
  HorizontalBarSeries,
  LabelSeries,
  XAxis,
  ChartLabel,
} from "react-vis/es";
import React, { PureComponent, Fragment } from "react";
import PropTypes from "prop-types";
import _max from "lodash/max";
import { withTranslation } from "react-i18next";
import { valueFactory } from "../../lib/indicatorUtils";
import { formattedPeriod } from "../../lib/periodsUtils";
import { getLabelWidthEstimation } from "../../lib/graphsUtils";
import GraphDownloadActions from "./GraphDownloadActions";
import domToImg from "../../lib/domToImg";

class HorizontalBarGraph extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      graphContainer: null,
    };
  }

  exportImage = () => {
    domToImg(this.state.graphContainer, this.props.title);
  };

  render() {
    const { data, lastValues, t, scaleType, color } = this.props;
    const axisTitle = lastValues ? "" : formattedPeriod(data[0].label);
    const labelWidthEstimation = getLabelWidthEstimation(
      _max(data.map(item => item.y.length)),
    );

    const xyPlotProps = {
      margin: { left: labelWidthEstimation, right: 100, top: 0, bottom: 40 },
      padding: 20,
      yType: "ordinal",
      animation: true,
      height: data.length * 30,
      onMouseLeave: this.onMouseLeave,
      color: "red",
    };

    return (
      <Fragment>
        <div className="horizontal-graphs-section__detail__header">
          <GraphDownloadActions
            exportImage={() => this.exportImage()}
            displayCsvButton={false}
          />
        </div>
        <div
          className="graph"
          ref={el =>
            this.setState({
              graphContainer: el,
            })
          }
        >
          <FlexibleXYPlot {...xyPlotProps}>
            <YAxis />
            <XAxis hideTicks />
            <HorizontalBarSeries data={data} color={color} barWidth={0.5} />
            <ChartLabel
              text={axisTitle}
              className="alt-x-label"
              includeMargin={false}
              xPercent={0}
              yPercent={1.1}
            />
            <LabelSeries
              data={data}
              getLabel={d =>
                d.missingData
                  ? t("noData.missingData")
                  : valueFactory(scaleType.toUpperCase(), d.x)
              }
              labelAnchorX="start"
              labelAnchorY="middle"
              style={{ fontSize: 10 }}
            />
          </FlexibleXYPlot>
        </div>
      </Fragment>
    );
  }
}

HorizontalBarGraph.propTypes = {
  color: PropTypes.string,
  data: PropTypes.array,
  lastValues: PropTypes.bool,
  scaleType: PropTypes.string,
  t: PropTypes.func,
  title: PropTypes.string,
};

export default withTranslation()(HorizontalBarGraph);
