import _keyBy from "lodash/keyBy";
import axios from "axios";
import L from "leaflet";

const fetchGeozones = (projectId) => {
  return axios.get(`${process.env.FRONTOFFICE_MANAGER_URL}/projects/${projectId}/zones`);
}

const fetchEntities = (projectId, map = false, parentId = null) => {
  const params = new URLSearchParams();
  map && params.append("map", true);
  parentId && params.append("parent_id", parentId);

  return axios.get(
    `${process.env.FRONTOFFICE_MANAGER_URL}/projects/${projectId}/entities?${params}`,
  );
}

const fetchInactiveGeozones = (projectId) => {
  return axios.get(
    `${process.env.FRONTOFFICE_MANAGER_URL}/projects/${projectId}/zones?inactive=true`,
  );
}

const fetchFilters = (projectId) => {
  return axios.get(
    `${process.env.FRONTOFFICE_MANAGER_URL}/projects/${projectId}/filters`,
  )
}

const fetchAllGeoZones = (projectId, loadFilters = false) => {
  let toLoad = [
    fetchGeozones(projectId),
    fetchInactiveGeozones(projectId),
    fetchEntities(projectId, true),
  ]
  if (loadFilters) {
    toLoad.push(fetchFilters(projectId))
  }
  return axios
    .all(toLoad)
    .then(
      axios.spread((geozonesResponse, inactiveGeozones, entities, filters) => ({
        geozones: _keyBy(geozonesResponse.data, "id"),
        inactiveGeozones: _keyBy(inactiveGeozones.data, "id"),
        entities: entities.data,
        filters: (filters || {data: {}}).data,
      })));
}

export const validCoordinates = orgUnit => {
  if (!orgUnit.geoJson) {
    if (
      process.env.LOG_LEVEL === "debug" &&
      process.env.NODE_ENV === "development" &&
      orgUnit
    ) {
      console.log(`no coordinates for ${orgUnit.name} ${orgUnit.id}`);
    }
    return false;
  }
  return true;
};

export const getMapBounds = (currentZone, countryBoundaries) =>
  L.geoJson(
    currentZone.geoJson ? currentZone.geoJson : countryBoundaries,
  ).getBounds();

export default {
  fetchGeozones,
  fetchEntities,
  fetchAllGeoZones,
};
