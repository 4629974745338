import "../../css/period-picker.scss";

import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { formattedPeriod } from "../lib/periodsUtils";
import { updatePeriod } from "../actions/periods";

const renderPeriod = period => (
  <option key={`period:${period}`} value={period}>
    {formattedPeriod(period)}
  </option>
);

const PeriodPicker = ({
  projectId,
  selectedPeriods,
  allPeriods,
  secondListOfPeriods,
  handleFromPeriodChange,
  handleToPeriodChange,
  t,
}) => {
  if (allPeriods.length === 0) return <div />;

  return (
    <form className="form-inline period-picker">
      <div>
        <select
          id="fromPeriod"
          value={selectedPeriods[0]}
          onChange={handleFromPeriodChange}
        >
          {allPeriods.map(period => renderPeriod(period))}
        </select>
        <span>
          <i className="fa fa-keyboard-arrow-right" />
        </span>
        <select
          id="toPeriod"
          value={selectedPeriods[selectedPeriods.length - 1]}
          onChange={handleToPeriodChange}
        >
          {secondListOfPeriods.map(period => renderPeriod(period, t))}
        </select>
      </div>
      {projectId === "3a2b3acf-c0a6-4b08-a01c-64f719a7ccd2" && (
        <div>
          <small style={{ color: "white" }}>
            Please note this is the{" "}
            <a href="https://en.wikipedia.org/wiki/Ethiopian_calendar">
              Ethiopian calendar
            </a>
          </small>
        </div>
      )}
    </form>
  );
};

const generateSecondListOfPeriods = (allPeriods, selectedPeriods) =>
  allPeriods.filter(period => period > selectedPeriods[0]);

const mapStateToProps = state => {
  const allPeriods = state.period.all || [];
  const selectedPeriods = state.period.selected;
  const projectId = state.project.id;
  return {
    projectId,
    selectedPeriods,
    allPeriods,
    secondListOfPeriods: generateSecondListOfPeriods(
      allPeriods,
      selectedPeriods,
    ),
  };
};

const mapDispatchToProps = dispatch => ({
  handleFromPeriodChange: e => {
    const period = e.target.value;
    dispatch(updatePeriod("from", period));
  },
  handleToPeriodChange: e => {
    const period = e.target.value;
    dispatch(updatePeriod("to", period));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PeriodPicker);

PeriodPicker.propTypes = {
  allPeriods: PropTypes.array,
  secondListOfPeriods: PropTypes.array,
  selectedPeriods: PropTypes.array,
  handleFromPeriodChange: PropTypes.func,
  handleToPeriodChange: PropTypes.func,
  t: PropTypes.func,
};
